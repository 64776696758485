/**
 * ThumbnailList
 */

import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import type { Thumbnail } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

interface BaseProps {
	className?: string;
	direction: 'row' | 'column';
	images: Thumbnail[];
	isEnabled?: boolean;
	onThumbnailClick: (index: number) => void;
	selectedImageIndex?: number;
	size: 56 | 80;
}
interface PropsWithoutMaxVisible extends BaseProps {
	maxVisible?: never;
	onShowAllClick?: never;
	showAllTriggersDialog?: never;
}
interface PropsWithMaxVisible extends BaseProps {
	maxVisible: number;
	onShowAllClick: () => void;
	showAllTriggersDialog?: boolean;
}
type Props = PropsWithoutMaxVisible | PropsWithMaxVisible;

export default function ThumbnailList({
	className,
	direction,
	images,
	isEnabled = true,
	maxVisible,
	onShowAllClick,
	onThumbnailClick,
	selectedImageIndex,
	showAllTriggersDialog,
	size,
}: Props) {
	const { t } = useI18n();

	if (images.length < 2) {
		return null;
	}

	// At least two to match the above return check.
	const visibleCount = Math.max(2, maxVisible ?? 999);
	const hiddenCount = Math.max(0, images.length - visibleCount);
	const visibleImages = images.slice(0, visibleCount);

	const sizeClasses: Record<typeof size, string> = {
		56: 'size-14',
		80: 'size-20',
	};

	return (
		<div
			className={cn(
				'flex flex-wrap gap-2',
				// Flex column with wrapping doesn't size the container properly, use
				// writing mode workaround.
				// https://github.com/philipwalton/flexbugs#flexbug-14
				// https://github.com/web-platform-tests/interop/issues/780
				direction === 'column' && '[writing-mode:vertical-lr]',
				!isEnabled && 'invisible',
				className,
			)}
		>
			{visibleImages.map((image, i) => (
				<button
					key={image.src}
					type="button"
					aria-label={t('product_details_show_image_number_label', {
						num: i + 1,
					})}
					className={cn(
						'rounded-button border p-2 hover:border-grey',
						sizeClasses[size],
						direction === 'column' && '[writing-mode:horizontal-tb]',
						i === selectedImageIndex &&
							'border-black ring-1 ring-inset ring-black',
						i !== selectedImageIndex && 'border-transparent',
					)}
					onClick={() => {
						onThumbnailClick(i);
					}}
				>
					<Img src={image.src} width={size} height={size} service="nextjs" />
				</button>
			))}
			{hiddenCount > 0 && onShowAllClick && (
				<Button
					onClick={onShowAllClick}
					aria-label={t('thumbnail_list_show_all_images_label')}
					aria-haspopup={showAllTriggersDialog ? 'dialog' : undefined}
					className={cn(
						'aspect-square p-2 font-bold md:text-lg',
						direction === 'column' && '[writing-mode:horizontal-tb]',
						sizeClasses[size],
					)}
				>
					+{hiddenCount}
				</Button>
			)}
		</div>
	);
}
ThumbnailList.displayName = 'ThumbnailList';
