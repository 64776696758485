/**
 * SelectButton
 */

import React, { type MouseEventHandler } from 'react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import type { HTMLAttributes } from 'types';
import { cn } from 'utils/classNames';
import { slugify } from 'utils/string';

interface Props extends HTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	hiddenLabel?: boolean;
	invertArrow: boolean;
	label: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
}

/** A button that looks like a select and has children rendered in the 'box' */
export default function SelectButton({
	children,
	className,
	hiddenLabel,
	invertArrow,
	label,
	onClick,
	...attrs
}: Props) {
	const id = `variant-picker-${slugify(label)}`;
	return (
		<div className={cn('relative', className)}>
			<label
				htmlFor={id}
				className={cn(
					'absolute -top-3 left-4 z-1 text-greyDark',
					hiddenLabel && 'sr-only',
				)}
			>
				<Text as="span" className="bg-white" styleAs="pSmall">
					{label}
				</Text>
			</label>
			<button
				{...attrs}
				id={id}
				type="button"
				onClick={onClick}
				className="relative mt-2 flex h-14 w-full cursor-pointer items-center rounded-button border border-grey bg-white px-4 transition-colors hover:border-greyDarker hover:ring-1 hover:ring-greyDarker"
			>
				{children}
				<div className="absolute bottom-2 right-0 top-2 flex w-10 items-center justify-center border-l border-greyLight">
					<Icon
						icon="arrow"
						color="black"
						direction={invertArrow ? 'up' : 'down'}
						className="md:hidden"
					/>
					<Icon
						icon="arrow"
						color="black"
						direction={invertArrow ? 'left' : 'right'}
						className="hidden md:block"
					/>
				</div>
			</button>
		</div>
	);
}
SelectButton.displayName = 'SelectButton';
