import React, { TextareaHTMLAttributes } from 'react';

import { cn } from 'utils/classNames';

import FloatingLabel from './FloatingLabel';
import { getInputClassName, isLabelFloating } from './helpers';
import InputInfo from './InputInfo';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLabel?: boolean;
	id: string;
	invalid?: boolean;
	label: string;
	rows?: number;
	valid?: boolean;
}

export default function Textarea({
	className,
	defaultValue,
	disabled,
	errorMessage,
	helpText,
	hiddenLabel = false,
	id,
	invalid = false,
	label,
	required,
	rows = 3,
	valid = false,
	value,
	...rest
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<div className={className}>
			<div className={cn('relative', disabled && 'opacity-50')}>
				<textarea
					{...rest}
					className={getInputClassName({
						disabled,
						extra: 'py-4',
						hideOutline: true,
						invalid,
						valid,
					})}
					aria-describedby={[errorMessageId, helpTextId].join(' ')}
					// Support for aria-errormessage isn't good enough as of autumn 2024
					// so stick with aria-describedby for now.
					// aria-errormessage={errorMessageId}
					aria-invalid={invalid}
					defaultValue={defaultValue}
					disabled={disabled}
					id={id}
					required={required}
					rows={rows}
					value={value}
				/>
				<FloatingLabel
					htmlFor={id}
					invalid={invalid}
					isFloating={isLabelFloating(value, defaultValue)}
					isHidden={hiddenLabel}
					required={required}
				>
					{label}
				</FloatingLabel>
			</div>
			<InputInfo
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				invalid={invalid}
			/>
		</div>
	);
}
Textarea.displayName = 'FormUI_Textarea';
