/**
 * Icon
 */

import React from 'react';

import { BACKGROUND_COLOR_CLASSES, TEXT_COLOR_CLASSES } from 'constants/colors';
import { SVGAttributes } from 'types';
import { cn } from 'utils/classNames';
import { findNewKey, objectKeys } from 'utils/collection';

import { icons } from './icons';

// TODO: Rename to IconName and maybe also rename the `icon` prop to `name`?
export type IconType = keyof typeof icons;
export const ICON_TYPES = objectKeys(icons);

export type IconColor = keyof typeof TEXT_COLOR_CLASSES;
export const ICON_COLORS = objectKeys(TEXT_COLOR_CLASSES);

export type IconBackgroundColor = keyof typeof BACKGROUND_COLOR_CLASSES;
export const ICON_BACKGROUND_COLORS = objectKeys(BACKGROUND_COLOR_CLASSES);

const SIZES = {
	12: 'size-3',
	16: 'size-4',
	24: 'size-6',
	32: 'size-8',
	40: 'size-10',
	48: 'size-12',
} as const;
const SIZES_WITH_PADDING = {
	...SIZES,
	56: 'size-14',
} as const;
export type IconSize = keyof typeof SIZES;
export const ICON_SIZES = objectKeys(SIZES);

const DIRECTIONS = {
	up: '-rotate-90',
	right: 'rotate-0',
	down: 'rotate-90',
	left: 'rotate-180',
} as const;
export type IconDirection = keyof typeof DIRECTIONS;
export const ICON_DIRECTIONS = objectKeys(DIRECTIONS);

const ALIGNMENTS = {
	top: 'align-top',
	middle: 'align-middle',
	bottom: 'align-bottom',
} as const;
export type IconAlignment = keyof typeof ALIGNMENTS;
export const ICON_ALIGNMENTS = objectKeys(ALIGNMENTS);

export interface Props extends SVGAttributes<SVGElement> {
	/** Vertical alignment of the icon. */
	'align'?: IconAlignment;

	/** Hide the icon from screen readers. */
	'aria-hidden'?: boolean;

	/** Class name for a rounded background plate. If not set, the icon won't have a background. */
	'backgroundColor'?: IconBackgroundColor;

	/** Additional class names for the SVG element. */
	'className'?: string;

	/** Color of the icon. */
	'color'?: IconColor;

	/** Direction of the icon. */
	'direction'?: IconDirection;

	/** Add some padding when `backgroundColor` is set? */
	'hasBackgroundPadding'?: boolean;

	/** Name of the icon to be shown. */
	'icon': IconType;

	/** Size of the icon, or null to disable sizing. */
	'size'?: IconSize | null;
}

/** Handles all the icons in the project. */
export default function Icon({
	align = 'middle',
	'aria-hidden': ariaHidden = true,
	backgroundColor,
	className = '',
	color,
	direction = 'right',
	hasBackgroundPadding = false,
	icon,
	size = 24,
	...htmlAttributes
}: Props) {
	// Just use the type of any icon since they're all the same.
	const IconSvg: typeof icons.cart | undefined = icons[icon];
	if (!IconSvg) {
		return null;
	}

	return (
		<IconSvg
			className={cn(
				className,
				'inline-block',
				'flex-shrink-0',
				'fill-current',
				'pointer-events-none',
				size &&
					SIZES_WITH_PADDING[
						hasBackgroundPadding
							? findNewKey(SIZES_WITH_PADDING, size, 'next')
							: size
					],
				color && TEXT_COLOR_CLASSES[color],
				backgroundColor && [
					BACKGROUND_COLOR_CLASSES[backgroundColor],
					'rounded-full',
				],
				hasBackgroundPadding && 'p-1',
				// A global SVG rule sets vertical-align to middle.
				align !== 'middle' && ALIGNMENTS[align],
				direction !== 'right' && DIRECTIONS[direction],
			)}
			aria-hidden={ariaHidden ? 'true' : undefined}
			{...htmlAttributes}
		/>
	);
}
Icon.displayName = 'Icon';
