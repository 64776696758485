import React from 'react';
import { Form } from 'react-final-form';
import { useInterpret, useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import AccountDetailsCreditInformation from 'components/AccountDetailsCreditInformation';
import ActionButton from 'components/ActionButton';
import { TextInput } from 'components/FinalForm';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import {
	useAvailableCreditBalance,
	useCustomerInformation,
	useGlobalLinks,
	useRevalidateCustomerInformation,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import {
	accountMachine,
	selectSubmitButtonState,
} from 'state-machines/account';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {};

function cleanPhoneNumber(number: string) {
	return number.replaceAll(/[\s,-]/g, '');
}

export default function AccountDetails({ rendering }: Props) {
	const { t } = useI18n();
	const { globalPopoverService } = useGlobalStateContext();
	const accountService = useInterpret(accountMachine, {
		devTools: true,
	});
	const { send } = accountService;
	const { julaClubCreditFunctionsEnabled } = useFeatureToggle();

	const { applyCredit, raiseCredit } = useGlobalLinks();
	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation();
	const revalidate = useRevalidateCustomerInformation();

	const {
		cellPhoneNumberFormatted,
		creditLimit,
		emailAddress,
		firstName,
		id,
		invoiceAddress,
		isCreditCustomer,
		isNameAndAddressEditableByCustomer,
		isValidCreditCustomer,
		lastName,
		postalAddress,
	} = customerInformation ?? {};
	const {
		availableCreditBalance,
		error: errorAvailableCreditBalance,
		isLoading: isLoadingAvailableCreditBalance,
	} = useAvailableCreditBalance(
		Boolean(
			julaClubCreditFunctionsEnabled && customerInformation?.isCreditCustomer,
		),
	);

	const submitButtonState = useSelector(
		accountService,
		selectSubmitButtonState,
	);

	const onSubmit = async (formData) => {
		if (
			formData.firstName ||
			formData.lastName ||
			formData.email ||
			formData.phoneNumber ||
			formData.street ||
			formData.postalCode ||
			formData.city
		) {
			send({
				type: 'UPDATE_JULA_CLUB_CUSTOMER_INFORMATION',
				updateCustomerInformationData: {
					firstName: formData.firstName,
					lastName: formData.lastName,
					email: formData.email,
					phoneNumber: cleanPhoneNumber(formData.phoneNumber),
					address: formData.street,
					postalCode: formData.postalCode,
					city: formData.city,
				},
			});

			// Wait for updating user info then see if we have any validation errors
			const doneUpdatingInformation = await waitFor(
				accountService,
				(state) => state.hasTag('submitDone'),
				{
					timeout: 120_000,
				},
			);

			if (
				doneUpdatingInformation?.context?.updateJulaClubCustomerErrors
					?.fieldValidationErrors
			) {
				return doneUpdatingInformation.context.updateJulaClubCustomerErrors
					.fieldValidationErrors;
			}
			revalidate();
		}
	};

	const isLoadingCreditInformation =
		(isCreditCustomer && isLoadingAvailableCreditBalance) ||
		isLoadingCustomerInformation;
	const errorCreditInformation =
		(isCreditCustomer && errorAvailableCreditBalance) ||
		errorCustomerInformation;
	const customerInformationLoadedOk =
		!isLoadingCustomerInformation && !errorCustomerInformation;

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_details_heading')}
			mainContentSize="thin"
		>
			<div className="mb-6 sm:mb-8">
				{errorCustomerInformation && (
					<InfoBox
						icon="error"
						variant="error"
						message={t('account_generic_fetch_error_text')}
					/>
				)}
			</div>
			{!errorCustomerInformation && (
				<>
					<Text as="h2" text={t('jula_club')} className="mb-4" />
					{isLoadingCustomerInformation && (
						<Skeleton>
							<SkeletonItem height="1rem" className="mt-4" />
							<SkeletonItem height="1rem" className="mb-8 mt-2" />
						</Skeleton>
					)}
					{customerInformationLoadedOk && (
						<>
							<Text
								as="p"
								text={t('account_details_customer_number_text')}
								className="font-bold"
							/>
							<Text as="p" text={id} className="mb-6" />
						</>
					)}
					{julaClubCreditFunctionsEnabled && (
						<AccountDetailsCreditInformation
							isCreditCustomer={isCreditCustomer}
							isValidCreditCustomer={isValidCreditCustomer}
							availableCreditHeading={t('account_details_credit_space_text')}
							applyForCreditInfoHeading={t('account_details_invoice_heading')}
							creditSpace={creditLimit}
							creditInformationText={t('account_details_invoice_text')}
							availableCreditBalance={availableCreditBalance}
							descriptiveText={t(
								'account_details_linear_gauge_descriptive_text',
							)}
							applyForCreditButtonOnClick={() => {
								if (applyCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: applyCredit,
										heading: t('account_apply_for_credit_button'),
									});
								}
							}}
							raiseCreditButtonOnClick={() => {
								if (raiseCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: raiseCredit,
										heading: t('account_raise_credit_button'),
									});
								}
							}}
							raiseCreditButtonText={t('account_raise_credit_button')}
							applyForCreditButtonText={t('account_apply_for_credit_button')}
							loading={isLoadingCreditInformation}
							hasError={errorCreditInformation}
						/>
					)}

					<div className="mb-9 mt-12 sm:mb-10">
						<div>
							<Text as="h2" className="mb-2 font-alt">
								{t('account_address_heading')}
							</Text>
							{isLoadingCustomerInformation && (
								<Skeleton>
									<SkeletonItem height="1rem" className="mt-4" />
									<SkeletonItem height="1rem" className="mt-1" />
									<SkeletonItem height="1rem" className="mt-1" />
									<SkeletonItem height="1rem" className="mt-1" />
									<SkeletonItem height="4rem" className="mb-8 mt-4" />
								</Skeleton>
							)}
							{!isLoadingCustomerInformation &&
								!isNameAndAddressEditableByCustomer && (
									<>
										<Text as="p">{invoiceAddress?.street}</Text>
										<Text as="p">
											{invoiceAddress?.postalCode}, {invoiceAddress?.city}
										</Text>
										<Text as="p" className="mb-2">
											{invoiceAddress?.country}
										</Text>
										<div className="flex">
											<InfoBox icon="info" variant="information">
												<Text as="pSmall">
													{t(
														'account_details_club_userinfo_not_editable_description',
													)}
												</Text>
											</InfoBox>
										</div>
									</>
								)}
						</div>
					</div>
					{isLoadingCustomerInformation && (
						<Skeleton className="w-[21rem]">
							<SkeletonItem height="3.5rem" className="mt-2" />
							<SkeletonItem height="3.5rem" className="mb-6 mt-4" />
							<SkeletonItem height="3.5rem" className="mb-8 mt-2" />
						</Skeleton>
					)}
					{!isLoadingCustomerInformation && (
						<Form
							onSubmit={onSubmit}
							initialValues={{
								firstName,
								lastName,
								email: emailAddress,
								phoneNumber: cleanPhoneNumber(
									cellPhoneNumberFormatted?.default ?? '',
								),
								street: postalAddress?.street,
								postalCode: postalAddress?.postalCode,
								city: postalAddress?.city,
							}}
							initialValuesEqual={() => true}
							render={(renderProps) => {
								const { handleSubmit } = renderProps;
								return (
									<form onSubmit={handleSubmit}>
										{isNameAndAddressEditableByCustomer && (
											<>
												<TextInput
													type="text"
													id="firstName"
													label={t('account_details_firstName_label')}
													name="firstName"
													className="mb-6"
												/>

												<TextInput
													type="text"
													id="lastName"
													label={t('account_details_lastName_label')}
													name="lastName"
													className="mb-6"
												/>
											</>
										)}
										<TextInput
											type="email"
											id="email"
											label={t('account_details_email_label')}
											inputMode="email"
											name="email"
											className="mb-6"
										/>

										<TextInput
											id="telephoneNumber"
											name="phoneNumber"
											label={t('account_details_phone_label')}
											inputMode="tel"
											className="mb-6"
										/>
										{isNameAndAddressEditableByCustomer && (
											<>
												<TextInput
													type="text"
													id="street"
													label={t('account_details_address_label')}
													name="street"
													className="mb-6"
												/>

												<TextInput
													type="text"
													id="postalCode"
													label={t('account_details_postalCode_label')}
													name="postalCode"
													className="mb-6"
												/>

												<TextInput
													type="text"
													id="city"
													label={t('account_details_city_label')}
													name="city"
													className="mb-6"
												/>
											</>
										)}
										<div>
											<ActionButton
												color="white"
												variant="cta"
												displayWidth="full"
												type="submit"
												customState={submitButtonState}
											>
												{t('account_details_save_details_button')}
											</ActionButton>
										</div>
									</form>
								);
							}}
						/>
					)}
				</>
			)}
		</AccountLayoutContainer>
	);
}
AccountDetails.displayName = 'AccountDetails';
