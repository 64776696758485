import React, { useState } from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { Input } from 'components/FormUi';
import GenericForm from 'components/GenericForm';
import InfoBox from 'components/InfoBox';
import ListItem from 'components/ListItem';
import LoadingSpinner from 'components/LoadingSpinner';
import { Step, Stepper } from 'components/Stepper';
import Text from 'components/Text';
import type { Section } from 'models/sitecore';
import type {
	Organization,
	Worksite,
} from 'state-machines/createJulaProCustomer';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const getWorkSiteErrorText = (
	worksite: Worksite | undefined,
	errors: Worksite['worksiteRegistrationStatus'][],
) =>
	worksite && errors.includes(worksite.worksiteRegistrationStatus)
		? worksite.worksiteRegistrationText
		: undefined;

interface Props {
	companySearchSection: Section | undefined;
	creditFunctionsEnabled: boolean;
	customerIdInputSection: Section | undefined;
	isInSearchOrganizationSuccess: boolean;
	isInWaitingForNewOrganizationSearch: boolean;
	isSearchingOrganizations: boolean;
	organizationList?: Organization[] | null;
	resetToSearchOrganization: () => void;
	resetToSelectOrganization: () => void;
	searchingOrganizationFailed: boolean;
	selectedWorksite: Worksite | undefined;
	selectOrganization: (id: string) => void;
	selectOrganizationButtonState: ActionButtonState;
	sendNewSearchInProgress: () => void;
	showCustomerIdForm: boolean;
	submitCustomerId: ({ customerId }: { customerId: string }) => void;
	submitCustomerIdButtonState: ActionButtonState;
	submitOrganizationSearch: (searchSstring: string) => void;
}

export default function StepOneContent({
	companySearchSection,
	creditFunctionsEnabled,
	customerIdInputSection,
	isInSearchOrganizationSuccess,
	isInWaitingForNewOrganizationSearch,
	isSearchingOrganizations,
	organizationList,
	resetToSearchOrganization,
	resetToSelectOrganization,
	searchingOrganizationFailed,
	selectedWorksite,
	selectOrganization,
	selectOrganizationButtonState,
	sendNewSearchInProgress,
	showCustomerIdForm,
	submitCustomerId,
	submitCustomerIdButtonState,
	submitOrganizationSearch,
}: Props) {
	const [value, setValue] = useState('');
	const { t } = useI18n();

	const [currentSelectedWorksiteId, setCurrentSelectedWorksiteId] =
		useState('');

	return (
		<>
			{creditFunctionsEnabled && (
				<Stepper className="mx-8 mb-8 mt-4 flex items-center">
					<Step number={1} status="active" />
					<Step number={2} status="pending" />
					<Step number={3} status="pending" />
				</Stepper>
			)}

			{!showCustomerIdForm && (
				<form
					className="relative mb-4"
					onSubmit={(e) => {
						e.preventDefault();
						if (value) {
							submitOrganizationSearch(value);
						}
					}}
				>
					<Input
						id="search-organization"
						label={
							companySearchSection?.heading?.value ||
							t('julapro_search_organization_label')
						}
						labelType="inline"
						labelClassName="font-bold mb-1"
						placeholder={t('julapro_search_organization_placeholder')}
						icon="search"
						inputMode="search"
						autoComplete="off"
						autoCapitalize="none"
						inputClassName="pr-14"
						value={value}
						onChange={(e) => {
							if (
								searchingOrganizationFailed ||
								isInSearchOrganizationSuccess ||
								isInWaitingForNewOrganizationSearch
							) {
								sendNewSearchInProgress();
							}
							setValue(e.target.value);
						}}
						onClearClick={() => {
							setValue('');
							resetToSearchOrganization();
						}}
					/>
				</form>
			)}

			{isSearchingOrganizations && (
				<div className="flex justify-center">
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						size="medium"
					/>
				</div>
			)}
			{is.arrayWithLength(organizationList) && !showCustomerIdForm && (
				<ul className="flex flex-col gap-2">
					{organizationList.map((organization) => {
						const isActive =
							organization.worksiteId === currentSelectedWorksiteId;
						const errorText = isActive
							? getWorkSiteErrorText(selectedWorksite, [
									'NotOk',
									'HasNotRightToSign',
									'CompanyHasAdmin',
									'UserIsAdmin',
									'MissingCompanyInformation',
								])
							: undefined;
						return (
							<ListItem
								as="li"
								key={organization.worksiteId}
								title={organization.worksiteName}
								endContent={
									<ActionButton
										variant="cta"
										size="small"
										className="self-center"
										onClick={() => {
											selectOrganization(organization.worksiteId);
											setCurrentSelectedWorksiteId(organization.worksiteId);
										}}
										customState={
											isActive ? selectOrganizationButtonState : 'idle'
										}
									>
										{t('julapro_choicebox_button_text')}
									</ActionButton>
								}
							>
								<Text as="pSmall" className="mb-1">
									{organization.address}
								</Text>
								<Text as="pSmall">{organization.city}</Text>
								{errorText && (
									<InfoBox
										icon="error"
										variant="error"
										className="mt-2 text-sm"
									>
										{errorText}
									</InfoBox>
								)}
							</ListItem>
						);
					})}
				</ul>
			)}
			{(searchingOrganizationFailed ||
				(isInSearchOrganizationSuccess && organizationList?.length === 0)) && (
				<>
					<p className="mt-6">
						{t('julapro_organsation_search_no_result_text', {
							searchString: value,
						})}
					</p>
					<p className="mt-4">
						{t('julapro_organsation_search_no_result_description', {
							searchString: value,
						})}
					</p>
				</>
			)}

			{showCustomerIdForm && customerIdInputSection && (
				<>
					{customerIdInputSection.heading?.value && (
						<p className="mb-1 font-bold">
							{customerIdInputSection.heading?.value}
						</p>
					)}
					<p className="mb-8">{customerIdInputSection.description.value}</p>

					<p className="mb-3 font-bold">{selectedWorksite?.companyName}</p>
					<GenericForm
						inputSpace="mb-4"
						fields={customerIdInputSection.form}
						// @ts-expect-error ({ customerId }) => submitCustomerId({ customerId })
						// gives no errors and is the exact same thing.
						onSubmitCallback={submitCustomerId}
						button={
							<ActionButton
								data-cid="verifyCustomerNumber"
								displayWidth="full"
								type="submit"
								size="large"
								variant="cta"
								className="mt-6"
								customState={submitCustomerIdButtonState}
							>
								{customerIdInputSection.submitText.value}
							</ActionButton>
						}
					/>

					<div className="mt-4 text-center">
						<Button variant="text" onClick={() => resetToSelectOrganization()}>
							{customerIdInputSection.backText.value}
						</Button>
					</div>
				</>
			)}
		</>
	);
}
StepOneContent.displayName = 'AccountJulaProSignupForm_StepOneContent';
