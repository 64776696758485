import React from 'react';

import ExpandableDescription from 'components/ExpandableDescription';
import Icon from 'components/Icon';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { AnchorLink, OptionalLink } from 'components/Link';
import RichText from 'components/RichText';
import Text from 'components/Text';
import WarningSymbols from 'components/WarningSymbols';
import type { Product } from 'models/product';
import {
	getBrandLogoImage,
	getProductInformationSymbols,
	getProductWarningImages,
} from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import { PRODUCT_IMPORTANT_INFO_ID } from './helpers/constants';
import { filterAdditionalTexts } from './helpers/productDataHelpers';
import ProductDetailsVideoly from './ProductDetailsVideoly';

interface Props {
	descriptionClassName?: string;
	isExpanded?: boolean;
	onImportantInfoClick: () => void;
	product: Product;
}

export default function ProductDetailsDescription({
	descriptionClassName,
	isExpanded,
	onImportantInfoClick,
	product,
}: Props) {
	const { t } = useI18n();
	const { brand, chemicalInformation, usPs } = product;
	const brandLogoSrc = getBrandLogoImage(product);
	const warningImages = getProductWarningImages(product);
	const symbolImageUrls = getProductInformationSymbols(product);
	const additionalTexts = filterAdditionalTexts(product.additionalTexts).filter(
		(text) => ['Notification', 'Offer'].includes(text.type),
	);

	return (
		<>
			<Text as="h2" text={t('product_details_about_product_section_heading')} />

			<div className="my-4 flex flex-wrap-reverse items-end justify-between md:my-6">
				{is.arrayWithLength(usPs) && (
					<ul className="inline-flex list-inside list-disc flex-col gap-2 rounded-xl bg-greyLighter p-4 font-bold">
						{usPs.map((usp) => (
							<li key={usp}>{usp}</li>
						))}
					</ul>
				)}
				<div className="flex flex-col items-start max-md:mb-4 md:items-end">
					{brandLogoSrc && (
						<OptionalLink href={brand?.relativeUrl}>
							<Img
								src={brandLogoSrc}
								alt={brand?.title}
								useFallbackOnError
								className="mb-4 max-h-16 max-w-[10rem]"
							/>
						</OptionalLink>
					)}
					<Text as="p">
						<span className="font-bold">
							{t('product_details_article_number_label')}:
						</span>{' '}
						{product.id}
					</Text>
				</div>
			</div>

			<ProductDetailsVideoly product={product} />

			<ExpandableDescription
				heading={product.title}
				description={product.webTextFormatted}
				textLengthBreakPoint={600}
				isExpanded={isExpanded}
				descriptionClassName={descriptionClassName}
				beforeDescription={
					<>
						{symbolImageUrls.length > 0 && (
							<div className="mb-4 flex flex-wrap items-start sm:mb-6">
								{symbolImageUrls.map((url) => (
									<Img key={url} src={url} className="mr-4 h-14" />
								))}
							</div>
						)}
						{additionalTexts.map((text) => (
							<InfoBox
								key={text.id}
								icon="info"
								heading={text.title}
								className="mb-2 max-w-[25rem]"
							>
								{text.textFormatted && (
									<RichText
										html={text.textFormatted}
										onlyInternalMargin
										className="text-sm"
									/>
								)}
							</InfoBox>
						))}
					</>
				}
				afterDescription={
					chemicalInformation?.hazardPictogram && (
						<div className="mb-4 sm:mb-6">
							<WarningSymbols
								heading={chemicalInformation.signalWord}
								text={chemicalInformation.hazardStatements}
								images={warningImages}
							/>
							<Text as="p">
								<AnchorLink
									className="underline hover:no-underline"
									anchor={PRODUCT_IMPORTANT_INFO_ID}
									onClick={onImportantInfoClick}
								>
									{t('product_details_show_safety_instructions_button')}
									<Icon icon="arrow" direction="down" className="ml-1" />
								</AnchorLink>
							</Text>
						</div>
					)
				}
			/>
		</>
	);
}
ProductDetailsDescription.displayName = 'ProductDetailsDescription';
