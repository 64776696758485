import React from 'react';
import { useSelector } from '@xstate/react';

import Popover from 'components/Popover';
import { WatchableSignup } from 'components/WatchableSignup';
import { useGlobalStateContext } from 'contexts';
import { useCustomerInformation } from 'hooks';
import { selectIsAuthenticatedUser } from 'state-machines/authentication';
import { useI18n } from 'utils/i18n';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	productId: string;
}

export default function WatchableVariantPopover({
	isOpen,
	onClose,
	productId,
}: Props) {
	const { t } = useI18n();
	const { userService } = useGlobalStateContext();
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const { customerInformation } = useCustomerInformation(isAuthenticatedUser);
	return (
		<Popover
			isOpen={isOpen}
			title={t('product_watchable_popover_heading')}
			onClose={onClose}
		>
			<WatchableSignup
				userEmail={customerInformation?.emailAddress}
				onClose={onClose}
				variantId={productId}
			/>
		</Popover>
	);
}

WatchableVariantPopover.displayName =
	'ProductDetails_StockInformation_WatchableVariantPopover';
