import React, { useMemo, useState } from 'react';

import StorePopover from 'components/StorePopover';
import { type StoreIdName, useSelectedStore } from 'contexts';
import { useGlobalEvent, useProductStock } from 'hooks';
import { filterTruthy } from 'utils/collection';
import { useI18n } from 'utils/i18n';

/** Popover for the global product card store selector */
export default function GlobalStockStoreSelectorPopover() {
	const { t } = useI18n();
	const [isOpen, setIsOpen] = useState(false);

	const {
		isLoading: isLoadingSelectedStore,
		selectedStore,
		setSelectedStore,
	} = useSelectedStore();

	const [stockFetchProductId, setStockFetchProductId] = useState('');
	const onStoreSelect = (store: StoreIdName) => {
		setSelectedStore(store);
		setIsOpen(false);
	};

	useGlobalEvent('open-stock-store-selector', (e) => {
		setIsOpen(true);
		setStockFetchProductId(e.detail.productId);
	});

	const { isLoadingProductStock, productStock } = useProductStock({
		productId: stockFetchProductId,
	});

	const productStoresStock = productStock?.storeStock?.stocks;

	const flattenedStores = useMemo(
		() =>
			productStoresStock?.map((storeStock) => ({
				...storeStock.store,
				availableStockLevel: storeStock.availableStockLevel,
				inStock: storeStock.inStock,
			})),
		[productStoresStock],
	);

	if (isLoadingSelectedStore || selectedStore) {
		return null;
	}

	return (
		<StorePopover
			baseId="global-stock-store"
			title={t('store_stock_popover_select_store_title')}
			isLoading={isLoadingProductStock}
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(false);
				setStockFetchProductId('');
			}}
			onStoreSelectClick={(store) => {
				onStoreSelect({ id: store.id, name: store.name });
			}}
			allStores={filterTruthy(flattenedStores, 'id', 'name')}
		/>
	);
}
GlobalStockStoreSelectorPopover.displayName = 'GlobalStoreSelectorPopover';
