/**
 * Services
 */

import { waitFor } from 'xstate/lib/waitFor';

import type { Cart, CartRequest, VerifyPaymentRequest } from 'models/api';
import { UserInfoMachineActor } from 'state-machines/checkout/userInfo';
import { formatCartForRequest } from 'utils/business-logic';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';

export async function fetchNewCart() {
	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart`,
		{ method: 'POST' },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Could not fetch new cart');
	}
	return response.responseData;
}

export async function fetchSpecificCart(cartId: string | undefined) {
	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/${cartId}`,
		{ method: 'GET' },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		// We, in some sense, use errors as values in the machines by throwing the data
		// and then expecting it in guards or setters in the 'onError' transition.
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw response;
	}
	return response.responseData;
}

export async function updateCart(cart: Cart | undefined) {
	if (!cart) {
		throw new Error('Cart is undefined');
	}
	const updatedCart: CartRequest = formatCartForRequest(cart);

	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/${cart?.id}`,
		{ method: 'PUT', body: JSON.stringify(updatedCart) },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Could not update cart');
	}
	return response.responseData;
}

/**
 * Verify redirect payment
 * - Verifies a redirect payment and returns the full cart.
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__verifyredirectpayment
 */
export async function verifyRedirectPayment(
	cartId: string | undefined,
	payload: VerifyPaymentRequest | undefined,
) {
	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/${cartId}/verifyredirectpayment`,
		{
			method: 'POST',
			body: payload ? JSON.stringify(payload) : undefined,
		},
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Could not verify redirect payment');
	}
	return response.responseData;
}

/**
 * Copy cart
 * - Copies cart (e.g. after requesting /Cart/checkout the cart is locked and can't be modified,
 *   so if a payment failes we need to copy it to be able to make changes to it).
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__copy
 */
export async function copyCart(cartId: string | undefined) {
	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/${cartId}/copy`,
		{ method: 'POST' },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		// We, in some sense, use errors as values in the machines by throwing the data
		// and then expecting it in guards or setters in the 'onError' transition.
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw response;
	}
	return response.responseData;
}

export async function checkoutCart(cart: Cart | undefined) {
	if (!cart) {
		throw new Error('Cart is undefined');
	}
	const updatedCart: CartRequest = formatCartForRequest(cart);

	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/checkout`,
		{ method: 'POST', body: JSON.stringify(updatedCart) },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Could not checkout cart');
	}
	return response.responseData;
}

export function waitForUserInfoActor(actor: UserInfoMachineActor) {
	return waitFor(actor, (state) => state.matches('idle'), {
		timeout: 120_000,
	});
}
