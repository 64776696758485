/**
 * Wishlist
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import Button from 'components/Button';
import CartListItem from 'components/CartListItem';
import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import PriceRow from 'components/PriceRow';
import Text from 'components/Text';
import TotalWithVat from 'components/TotalWithVat';
import {
	StoreIdName,
	useFeatureToggle,
	useGlobalStateContext,
	useSelectedStore,
} from 'contexts';
import { useProductGTMEvents } from 'hooks';
import type { Wishlist as WishlistModel, WishlistVariant } from 'models/api';
import { selectIsLoading } from 'state-machines/wishlist';
import { getProductCardImage } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

import { formatWishlistdataForCart, formatWishListDataForGTM } from './helpers';

export interface WishlistBlockProps {
	copiedToClipboard?: boolean;
	heading: string;
	isLoading: boolean;
	isSharing?: boolean;
	moveAllVariantsToCartButtonText: string;
	moveVariantToCartButtonText: string;
	onEmptyWishlist: (() => void) | undefined;
	onlineCommerceEnabled: boolean;
	onMoveAllVariantsToCart: (() => void) | undefined;
	onMoveVariantToCart: (variant: WishlistVariant) => void;
	onRemoveVariant?: (variant: WishlistVariant) => void;
	onShare?: () => unknown;
	onUpdateVariantQty?: (variant: WishlistVariant, newQuantity: number) => void;
	selectedStore: StoreIdName | undefined;
	sharedText?: string;
	storesEnabled: boolean;
	wishlist: WishlistModel | undefined;
}

/** Wishlist layout component */
export function WishlistBlock({
	copiedToClipboard,
	heading,
	isLoading,
	isSharing,
	moveAllVariantsToCartButtonText,
	moveVariantToCartButtonText,
	onEmptyWishlist,
	onlineCommerceEnabled,
	onMoveAllVariantsToCart,
	onMoveVariantToCart,
	onRemoveVariant,
	onShare,
	onUpdateVariantQty,
	selectedStore,
	sharedText,
	storesEnabled,
	wishlist,
}: WishlistBlockProps) {
	const { t } = useI18n();
	const { categories, savingSummaries, summaries } = wishlist ?? {};
	const getSummary = (sumType: string) =>
		summaries?.find((summary) => summary.sumType === sumType);
	const getSummaries = (sumType: string) =>
		summaries?.filter((summary) => summary.sumType === sumType);

	return (
		<LayoutContainer withGrid className="mt-4 md:mt-10">
			<Text className="col-span-full" as="h1">
				{heading}
			</Text>
			{sharedText && (
				<Text as="p" className="col-span-full mt-4">
					{sharedText}
				</Text>
			)}

			<div className="col-span-full mt-6 flex items-start gap-4 md:mt-8 print:hidden">
				{onShare && (
					<Button rounded onClick={onShare}>
						<Icon
							icon={
								isSharing ? 'spinner' : copiedToClipboard ? 'check' : 'copyLink'
							}
							className={cn('mr-2', isSharing && 'animate-spinFast')}
						/>
						{copiedToClipboard
							? t('wishlist_link_copied_button')
							: t('wishlist_share_wishlist_button')}
					</Button>
				)}
				<Button
					className="max-md:hidden"
					onClick={() => globalThis.print()}
					rounded
				>
					<Icon icon="printer" className="mr-2" />
					{t('wishlist_print_wishlist_button')}
				</Button>
				{onEmptyWishlist && (
					<div className="place-self-center">
						<Button variant="text" size="small" onClick={onEmptyWishlist}>
							{t('wishlist_empty_wishlist_button')}
						</Button>
					</div>
				)}
			</div>

			<div className="relative col-span-full mt-6 md:mt-14 print:block">
				{isLoading && (
					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						className="z-1000 absolute left-1/2 top-1/2 -ml-20 -mt-20"
					/>
				)}
				<div className="divide-y divide-grey border-y border-grey">
					{categories?.flatMap((category) =>
						category.variants?.map((variant) => {
							const imageSrc = getProductCardImage(variant.listImageFormats);

							const getSelectedStoreStock = () => {
								const stock = variant.storeStock?.stocks?.find(
									(store) => store.store.id === selectedStore?.id,
								);
								if (!stock) {
									return undefined;
								}
								return {
									name: selectedStore?.name ?? 'Unknown',
									categoryName: category.name,
									categoryColor: category.categoryColor,
									...stock,
								};
							};

							return (
								<CartListItem
									key={variant.id}
									link={variant.url}
									imageSrc={imageSrc ?? ''}
									imageAlt="image"
									heading={variant.title}
									id={variant.id}
									price={variant.price}
									rowSum={variant.rowSum}
									quantity={variant.qty}
									onUpdateVariantQty={
										onlineCommerceEnabled && onUpdateVariantQty
											? (qty) => onUpdateVariantQty(variant, qty)
											: undefined
									}
									selectedStoreStock={
										storesEnabled ? getSelectedStoreStock() : undefined
									}
									allStoresInStockCount={
										storesEnabled ? variant.storeStock?.inStockCount : undefined
									}
									webStock={variant.webStock}
									moveVariantButtonText={moveVariantToCartButtonText}
									onMoveVariant={
										onlineCommerceEnabled && variant?.webStock?.inStock
											? () => onMoveVariantToCart(variant)
											: undefined
									}
									onRemoveVariant={
										onlineCommerceEnabled && onRemoveVariant
											? () => onRemoveVariant(variant)
											: undefined
									}
									removeVariantButtonText={t(
										'wishlist_summary_product_remove_product_button',
									)}
									volumePrice={variant.volumePrice}
								/>
							);
						}),
					)}
				</div>

				<div className="flex flex-col justify-between pt-8 md:pt-14">
					<PriceRow
						title={getSummary('Total')?.title}
						price={getSummary('Total')?.displayValue}
						priceSymbol={getSummary('Total')?.displaySymbol}
					/>
					{getSummaries('QtyBasedOffer')?.map((offer, i) => (
						<PriceRow
							key={i}
							variant="minusPrice"
							title={offer.title}
							price={offer.displayValue}
							priceSymbol={offer.displaySymbol}
						/>
					))}
					<TotalWithVat
						title={getSummary('AmountToPay')?.title}
						price={getSummary('AmountToPay')?.displayValue}
						priceSymbol={getSummary('AmountToPay')?.displaySymbol}
						vat={getSummary('Vat')?.displayValue}
						vatCurrencySymbol={getSummary('Vat')?.displaySymbol}
					/>
					<div className="mt-1 flex justify-end">
						{savingSummaries?.map((item) => {
							if (item.sumType !== 'SavingTotalSum') {
								return null;
							}
							return (
								<div key={item.title} className="mt-2 flex items-center">
									<Icon
										icon="offer"
										color="julaRed"
										className="max-md:size-4"
									/>
									<Text as="p" className="ml-2 text-julaRed">
										<span className="mr-1">
											{' '}
											{t('wishlist_total_savings_label')}
										</span>
										<span>
											{formatPriceText(item.displayValue, item.displaySymbol)}
										</span>
									</Text>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{onMoveAllVariantsToCart && (
				<div className="col-span-full mb-4 mt-6 md:place-self-end print:hidden">
					<Button
						variant="cta"
						size="large"
						displayWidth="full"
						disabled={!onlineCommerceEnabled}
						onClick={onMoveAllVariantsToCart}
					>
						{moveAllVariantsToCartButtonText}
					</Button>
				</div>
			)}
		</LayoutContainer>
	);
}
WishlistBlock.displayName = 'WishlistBlock';

interface Props {
	copiedToClipboard?: boolean;
	heading: string;
	isSharing?: boolean;
	moveAllVariantsToCartButtonText: string;
	moveVariantToCartButtonText: string;
	onEmptyWishlist?: () => void;
	onRemoveVariant?: (variant: WishlistVariant) => void;
	onShare?: () => unknown;
	onUpdateVariantQty?: (variant: WishlistVariant, newQuantity: number) => void;
	sharedText?: string;
	wishlist: WishlistModel | undefined;
}

export default function Wishlist({
	copiedToClipboard,
	heading,
	isSharing,
	moveAllVariantsToCartButtonText,
	moveVariantToCartButtonText,
	onEmptyWishlist,
	onRemoveVariant,
	onShare,
	onUpdateVariantQty,
	sharedText,
	wishlist,
}: Props) {
	const { cartService, wishlistService } = useGlobalStateContext();
	const { onlineCommerceEnabled, storesEnabled } = useFeatureToggle();
	const { selectedStore } = useSelectedStore();
	const isLoading = useSelector(wishlistService, selectIsLoading);
	const hasUnsellableItems = wishlist?.categories?.some((category) =>
		category.variants?.some((variant) => !variant.webStock?.inStock),
	);
	const { sendAddMultipleToCartEvent, sendAddToCartEvent } =
		useProductGTMEvents();
	const moveAllVariantsToCart = (list: WishlistModel | undefined) => {
		if (!list) return;
		cartService.send({
			type: 'ADD_MULTIPLE_VARIANTS_MINI',
			variants: formatWishlistdataForCart(list),
		});
		sendAddMultipleToCartEvent({ products: formatWishListDataForGTM(list) });
		wishlistService.send({ type: 'REMOVE_ALL' });
	};
	const moveVariantToCart = (variant: WishlistVariant) => {
		if (!variant.id) return;
		cartService.send({
			type: 'ADD_REMOVE_VARIANT',
			variantId: variant.id,
			qty: variant.qty,
			distinctValue: false,
		});
		sendAddToCartEvent({ product: variant, quantity: variant.qty });
		wishlistService.send({
			type: 'REMOVE_VARIANT',
			variantId: variant.id,
		});
	};

	return (
		<WishlistBlock
			heading={heading}
			onShare={onShare}
			sharedText={sharedText}
			copiedToClipboard={copiedToClipboard}
			isSharing={isSharing}
			storesEnabled={storesEnabled}
			isLoading={isLoading}
			onEmptyWishlist={onEmptyWishlist}
			onMoveAllVariantsToCart={
				hasUnsellableItems ? undefined : () => moveAllVariantsToCart(wishlist)
			}
			onMoveVariantToCart={moveVariantToCart}
			onUpdateVariantQty={onUpdateVariantQty}
			onRemoveVariant={onRemoveVariant}
			onlineCommerceEnabled={onlineCommerceEnabled}
			wishlist={wishlist}
			selectedStore={selectedStore}
			moveVariantToCartButtonText={moveVariantToCartButtonText}
			moveAllVariantsToCartButtonText={moveAllVariantsToCartButtonText}
		/>
	);
}
Wishlist.displayName = 'Wishlist';
