/**
 * CartQuantity
 */

import React, { useEffect, useState } from 'react';

import Icon from 'components/Icon';
import { useDebouncedCallback, usePrevious } from 'hooks';
import { cn } from 'utils/classNames';
import { getTestDataAttrFrom, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface QuantityButtonProps {
	ariaLabel: string;
	className?: string;
	dataCy?: string;
	disabled: boolean;
	icon: 'minus' | 'plus';
	onClick: () => void;
	size: 'small' | 'large';
}

function QuantityButton({
	ariaLabel,
	className,
	dataCy,
	disabled,
	icon,
	onClick,
	size,
}: QuantityButtonProps) {
	return (
		<button
			type="button"
			className={cn(
				'relative z-10 shrink-0',
				'hover:bg-greyLight',
				'disabled:pointer-events-none disabled:cursor-default disabled:opacity-50',
				size === 'small' ? 'w-10' : 'w-14',
				className,
			)}
			aria-label={ariaLabel}
			onClick={onClick}
			disabled={disabled}
			data-cy={getTestDataAttrFrom(dataCy)}
		>
			<Icon icon={icon} />
		</button>
	);
}
QuantityButton.displayName = 'CartQuantity_QuantityButton';

interface Props {
	className?: string;

	id: string;

	/** If current quantity is invalid */
	invalid?: boolean;

	/** The quantity of products for each item in cart */
	quantity: number;

	size?: 'small' | 'large';

	/** Function to update cart */
	updateCart: (qty: number) => void;
}

/** The cart quantity component that handles the quantity of products. */
export default function CartQuantity({
	className,
	id,
	invalid,
	quantity,
	size = 'small',
	updateCart,
}: Props) {
	const { t } = useI18n();
	const [value, setValue] = useState<number | string>(quantity || 1);
	const numValue = is.number(value) ? value : 0;
	const [debouncedUpdateCart] = useDebouncedCallback(updateCart, 500);

	const updateQuantity = (qty: number) => {
		setValue(qty);
		debouncedUpdateCart(qty);
	};

	// Sync prop updates to the local state.
	const prevQuantity = usePrevious(quantity);
	useEffect(() => {
		if (
			is.defined(quantity) &&
			is.defined(prevQuantity) &&
			prevQuantity !== quantity
		) {
			setValue(quantity);
		}
	}, [prevQuantity, quantity]);

	return (
		<div
			className={cn(
				'flex rounded-button border border-greyDark bg-white hover:ring-1 hover:ring-greyDark',
				size === 'small' ? 'h-10' : 'h-14',
				className,
			)}
		>
			<QuantityButton
				onClick={() => {
					updateQuantity(Math.max(numValue - 1, 0));
				}}
				ariaLabel={t('product_quantity_decrease_by_one_label')}
				disabled={value === 0}
				size={size}
				icon="minus"
				dataCy="quantity-decrease"
			/>
			<label htmlFor={`product-quantity-${id}`} className="sr-only">
				{t('product_quantity_input_label')}
			</label>
			<input
				className={cn(
					'block min-w-0 flex-1 text-center',
					invalid && '!border-julaRed',
				)}
				id={`product-quantity-${id}`}
				inputMode="numeric"
				type="text"
				name="product-quantity"
				onChange={(event) => {
					// The debounced update will trigger onChange when done, skip an
					// unnecessary additional update if the value is the same.
					if (
						event.target.value !== '' &&
						is.number(Number(event.target.value)) &&
						event.target.value !== value
					) {
						updateQuantity(Number(event.target.value));
					} else {
						setValue(event.target.value);
					}
				}}
				onBlur={(event) => {
					// Reset to previous value if the input is empty or not a number.
					if (
						event.target.value === '' ||
						!is.number(Number(event.target.value))
					) {
						setValue(prevQuantity!);
					}
				}}
				value={value}
			/>
			<QuantityButton
				onClick={() => {
					updateQuantity(numValue + 1);
				}}
				ariaLabel={t('product_quantity_increase_by_one_label')}
				disabled={value === 0}
				size={size}
				icon="plus"
				dataCy="quantity-increase"
			/>
		</div>
	);
}
CartQuantity.displayName = 'CartQuantity';
