/**
 * AccountLayoutContainer
 */

import React, { type ReactNode } from 'react';
import type { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';

import Placeholder from 'components/Placeholder';
import Text from 'components/Text';
import { useSitecoreContext } from 'hooks';
import { cn } from 'utils/classNames';
import { getTestDataAttrFrom } from 'utils/helpers';

import LayoutContainer from './LayoutContainer';

interface Props {
	children: ReactNode;
	heading?: ReactNode;
	headingClassName?: string;
	mainContentSize?: 'regular' | 'thin';
	rendering: ComponentRendering;
	sidebarContent?: ReactNode;
}

/** Container for account pages that handles placeholders and the main grid. */
export default function AccountLayoutContainer({
	children,
	heading,
	headingClassName,
	mainContentSize = 'regular',
	rendering,
	sidebarContent,
}: Props) {
	const { sitecoreContext } = useSitecoreContext();

	// TODO: the grid should only be 4/md:12, no sm:8
	return (
		<LayoutContainer>
			<div className="mb-14 mt-6 print:hidden">
				<Placeholder name="breadcrumbs" rendering={rendering} />
			</div>
			<div className="grid grid-flow-col grid-cols-4 gap-4 gap-y-0 sm:grid-cols-8 sm:gap-6 sm:gap-y-0 md:grid-cols-12">
				<div className="col-span-2 row-span-2 block max-sm:hidden print:hidden">
					<Placeholder
						name="jula-side-navigation"
						rendering={sitecoreContext.route}
					/>
				</div>

				<div
					className={cn(
						'col-span-full sm:col-span-4 sm:col-start-3 md:col-span-5 md:col-start-4 print:!col-start-1',
						mainContentSize === 'thin' && 'max-w-[25rem]',
						sidebarContent && 'print:!col-span-8',
						!sidebarContent && 'print:!col-span-full',
					)}
				>
					{heading && (
						<Text as="h1" className={cn('mb-2', headingClassName)}>
							{heading}
						</Text>
					)}
					{children}
				</div>

				{sidebarContent && (
					<div
						data-cy={getTestDataAttrFrom('account-sidebar')}
						className="col-span-full sm:col-span-2 sm:col-start-7 md:col-span-3 md:col-start-10 print:!col-start-10"
					>
						{sidebarContent}
					</div>
				)}
			</div>
		</LayoutContainer>
	);
}
AccountLayoutContainer.displayName = 'AccountLayoutContainer';
