/**
 * InfoBox
 */

import React, { type ReactNode } from 'react';

import Icon, { type IconColor, type IconType } from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { UnionWithString } from 'types';
import { cn, cnm } from 'utils/classNames';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export type InfoboxVariants = 'success' | 'error' | 'information';
export type InfoBoxTypes = 'default' | 'wide' | 'toast';

interface Props {
	/** Type of InfoBox */
	type?: InfoBoxTypes;

	/** Information text content */
	children?: ReactNode;

	/** Additional styles */
	className?: string;

	/** Callback for close button */
	closeButtonCallback?: () => void;

	/** Information heading */
	heading?: string;

	/** Icon to display, either a predefined icon name or an image URL */
	icon?: UnionWithString<IconType>;

	/** Color of the icon */
	iconColor?: IconColor;

	/** Link to important information */
	link?: string;

	/** Link test ID */
	linkDataCy?: string;

	/** Hide the link from screen readers? */
	linkIsScreenReaderHidden?: boolean;

	/** Link text */
	linkText?: string;

	/** Information message */
	message?: string;

	/** Text size */
	textSize?: 'small' | 'base';

	/** The variant of the info box, either information or error */
	variant?: InfoboxVariants;
}

const getBackgroundColor = (variant: InfoboxVariants): string => {
	switch (variant) {
		case 'success':
			return 'bg-successLighter';
		case 'error':
			return 'bg-errorLighter';
		default:
			return 'bg-informationLighter';
	}
};

const getIconColor = (variant: InfoboxVariants): IconColor => {
	switch (variant) {
		case 'success':
			return 'success';
		case 'error':
			return 'error';
		default:
			return 'information';
	}
};

const iconIsUrl = (icon: UnionWithString<IconType>): icon is string =>
	Boolean(icon && icon.includes('/'));
const iconIsName = (icon: UnionWithString<IconType>): icon is IconType =>
	!iconIsUrl(icon);

/** Component to display an important information notice */
export default function InfoBox({
	children,
	className,
	closeButtonCallback,
	heading,
	icon,
	iconColor,
	link,
	linkDataCy,
	linkIsScreenReaderHidden,
	linkText,
	message,
	textSize = 'small',
	type = 'default',
	variant = 'information',
}: Props) {
	const { t } = useI18n();

	return (
		<div
			className={cnm(
				getBackgroundColor(variant),
				'flex-start flex items-start rounded-[0.188rem]',
				type === 'wide' ? 'p-4 md:p-8' : 'p-4',
				type === 'wide' && 'justify-center',
				type === 'toast' && 'p-4 sm:w-[32rem] md:p-6',
				!icon && '!pl-14',
				className,
			)}
		>
			{icon && (
				<div
					className={cn(
						'flex shrink-0 justify-end',
						type === 'wide' && closeButtonCallback && 'basis-1/5',
						type === 'wide' && !closeButtonCallback && 'items-center',
					)}
				>
					{iconIsUrl(icon) && (
						<Img
							src={icon}
							alt=""
							className={cn('size-6 shrink-0', type !== 'wide' && '-mt-px')}
							width={24}
							height={24}
							service="nextjs"
							jpgOptimized={false}
						/>
					)}
					{iconIsName(icon) && (
						<Icon
							icon={icon}
							color={iconColor ?? getIconColor(variant)}
							className={cn('shrink-0', type !== 'wide' && '-mt-px')}
						/>
					)}
				</div>
			)}
			<div
				className={cn(
					'inline-block min-w-0 flex-shrink',
					type !== 'toast' && 'md:self-center',
					icon && 'pl-4',
					type === 'wide' && 'sm:flex sm:items-center',
					type === 'wide' && closeButtonCallback && 'basis-3/5',
					type === 'toast' &&
						'sm:flex sm:w-full sm:items-center sm:justify-between',
					variant === 'information' && 'text-informationDarker',
					variant === 'error' && 'text-errorDarker',
					variant === 'success' && 'text-successDarker',
				)}
			>
				{heading && (
					<Text
						as="h2"
						styleAs="h6"
						className={cn(
							type !== 'wide' && type !== 'toast' && 'mb-2',
							type === 'toast' && 'mr-4',
							type === 'wide' && 'pr-1 max-sm:mb-2',
						)}
					>
						{heading}
					</Text>
				)}
				<div className="flex flex-col gap-2 break-words">
					{message && (
						<RichText
							html={message}
							className={textSize === 'small' ? 'text-sm' : undefined}
						/>
					)}
					{children && <div>{children}</div>}
					{link && (
						<Text
							as={textSize === 'small' ? 'pSmall' : 'p'}
							aria-hidden={linkIsScreenReaderHidden ? true : undefined}
						>
							<Link
								href={link}
								data-cy={getTestDataAttrFrom(linkDataCy)}
								className={cn(
									'underline hover:no-underline',
									children && 'sm:ml-1',
								)}
							>
								{linkText ? t(linkText) : t('info_box_read_more_button')}
							</Link>
						</Text>
					)}
				</div>
			</div>
			{closeButtonCallback && (
				<div className="flex self-center md:basis-1/5">
					<button
						type="button"
						onClick={closeButtonCallback}
						aria-label={t('popover_close_label')}
					>
						<Icon icon="close" />
					</button>
				</div>
			)}
		</div>
	);
}
InfoBox.displayName = 'InfoBox';
