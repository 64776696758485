import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
	};
};

export default function ContentHeadline({ fields, params }: Props) {
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text
				className="max-w-col-6"
				as={params?.heading || 'h2'}
				styleAs="h1"
				field={fields?.heading}
			/>
		</LayoutContainer>
	);
}
ContentHeadline.displayName = 'ContentHeadline';
