/**
 * BlockContactHeading
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Icon, { type IconType } from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { HTMLAttributes } from 'types';

interface Props extends HTMLAttributes<HTMLDivElement> {
	className?: string;
	description: Field<string> | undefined;
	heading: Field<string> | undefined;
	headingIcon: IconType | undefined;
	headingLevel: HeadingLevel | undefined;
}

export default function BlockContactHeading({
	className,
	description,
	heading,
	headingIcon,
	headingLevel,
	...attrs
}: Props) {
	return (
		<LayoutContainer {...attrs} outerClassName={className}>
			<div className="max-w-col-6 border-t border-t-grey pt-6 md:pt-8">
				<div className="flex items-center">
					{headingIcon && <Icon icon={headingIcon} className="mr-2 shrink-0" />}
					<Text as={headingLevel || 'h2'} field={heading} />
				</div>
				{description && <Text as="p" field={description} className="mt-4" />}
			</div>
		</LayoutContainer>
	);
}
BlockContactHeading.displayName = 'BlockContactHeading';
