/**
 * Stepper
 */

import React from 'react';

import { cn } from 'utils/classNames';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Pass optional classnames for the html */
	className?: string;
}

/** The stepper component that presents the different steps in the stepper */
export default function Stepper({ children, className }: Props) {
	return (
		<div
			// TODO: stepperwrapper styles should be inlined to Tailwind classes
			className={cn('stepperwrapper relative flex justify-between', className)}
		>
			{children}
		</div>
	);
}
Stepper.displayName = 'Stepper';
