import React from 'react';

import InfoBox from 'components/InfoBox';
import { AnchorLink } from 'components/Link';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import { PRODUCT_SIMILAR_PRODUCTS_ID } from './helpers/constants';

interface Props {
	className?: string;
	shouldShowSimilarProductsLink: boolean;
}

export default function ExpiredProductInfo({
	className,
	shouldShowSimilarProductsLink,
}: Props) {
	const { t } = useI18n();
	return (
		<InfoBox icon="info" className={cn('mx-auto mb-6 text-sm', className)}>
			{t('product_details_product_expired_text')}{' '}
			{shouldShowSimilarProductsLink && (
				<AnchorLink
					anchor={PRODUCT_SIMILAR_PRODUCTS_ID}
					className="underline hover:no-underline"
				>
					{t('product_details_view_similar_products_button')}
				</AnchorLink>
			)}
		</InfoBox>
	);
}
ExpiredProductInfo.displayName = 'ExpiredProductInfo';
