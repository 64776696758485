import React from 'react';
import { Field, Form } from 'react-final-form';

import { Select } from 'components/FormUi';
import { Option } from 'components/FormUi/Select';
import Hr from 'components/Hr';
import { AnchorLink } from 'components/Link';
import SearchField from 'components/SearchField';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

import { PRODUCT_ARCHIVE_SEARCH_LIST_ID } from './ProductArchiveSearch';

export interface Props {
	categoryOption: string | undefined;
	categoryOptions: Option[];
	categorySelectDisabled: boolean;
	categorySelectLabel: string;
	collectionOption: string | undefined;
	collectionOptions: Option[];
	collectionSelectLabel: string;
	onCategoryOptionChange: (value: string) => void;
	onCollectionOptionChange: (value: string) => void;
	onSparePartSearchSubmit: ({ search }: { search: string }) => void;
	onSubCategoryOptionChange: (value: string) => void;
	subCategoryOption: string | undefined;
	subCategoryOptions: Option[];
	subCategorySelectDisabled: boolean;
	subCategorySelectLabel: string;
	toResultsLinkVisible: boolean;
}

export default function ProductArchiveSearchForm({
	categoryOption,
	categoryOptions,
	categorySelectDisabled,
	categorySelectLabel,
	collectionOption,
	collectionOptions,
	collectionSelectLabel,
	onCategoryOptionChange,
	onCollectionOptionChange,
	onSparePartSearchSubmit,
	onSubCategoryOptionChange,
	subCategoryOption,
	subCategoryOptions,
	subCategorySelectDisabled,
	subCategorySelectLabel,
	toResultsLinkVisible,
}: Props) {
	const { t } = useI18n();
	return (
		<div className="flex flex-col rounded-border bg-greyLighter px-6 py-8">
			<Text
				as="h3"
				text={t('product_archive_search_form_collection_filter_title')}
				className="mb-4"
			/>
			<div className="flex gap-x-6 max-md:flex-col max-md:gap-y-4 md:items-end">
				<div className="md:w-1/4">
					<Select
						id="collection-select"
						label={collectionSelectLabel}
						options={collectionOptions}
						onChange={(e) => onCollectionOptionChange(e.target.value)}
						value={collectionOption}
					/>
				</div>
				<div className="md:w-1/4">
					<Select
						id="category-select"
						label={categorySelectLabel}
						options={categoryOptions}
						onChange={(e) => onCategoryOptionChange(e.target.value)}
						value={categoryOption}
						disabled={categorySelectDisabled}
					/>
				</div>
				<div className="md:w-1/4">
					<Select
						id="sub-category-select"
						label={subCategorySelectLabel}
						options={subCategoryOptions}
						value={subCategoryOption}
						onChange={(e) => onSubCategoryOptionChange(e.target.value)}
						disabled={subCategorySelectDisabled}
					/>
				</div>
				{toResultsLinkVisible && (
					<AnchorLink
						className="underline hover:no-underline md:hidden"
						anchor={PRODUCT_ARCHIVE_SEARCH_LIST_ID}
					>
						{t('product_archive_search_scroll_to_results_button')}
					</AnchorLink>
				)}
			</div>
			<Hr className="my-8" />
			<div className="flex flex-col md:w-1/2">
				<Text
					as="h3"
					text={t('product_archive_search_form_search_title')}
					className="mb-4 font-bold"
				/>
				<Form
					onSubmit={onSparePartSearchSubmit}
					render={({ handleSubmit, form }) => (
						<form onSubmit={handleSubmit}>
							<Field
								name="search"
								render={({ input }) => (
									<SearchField
										id="search"
										placeholder={t(
											'product_archive_search_form_search_placeholder',
										)}
										submitButtonLabel={t('search_show_all_button')}
										handleInputClearClick={() => form.reset()}
										inputLabel={t('product_archive_search_form_search_label')}
										{...input}
									/>
								)}
							/>
						</form>
					)}
				/>
			</div>
		</div>
	);
}
ProductArchiveSearchForm.displayName = 'ProductArchiveSearchForm';
