import React, { useCallback } from 'react';
import { useSelector } from '@xstate/react';

import AccountMenuLinks from 'components/AccountMenuLinks';
import Popover from 'components/Popover';
import { useGlobalStateContext } from 'contexts';
import { withRequiredProps } from 'hoc';
import type { AccountMenuItem } from 'models/sitecore';
import {
	selectIsLoggingOut,
	selectUsername,
} from 'state-machines/authentication';
import { selectIsOpen } from 'state-machines/menu.machine';
import { useI18n } from 'utils/i18n';

interface Props {
	fields: {
		accountLinks: AccountMenuItem[];
	};
}

/** User account menu outside of the account pages. */
function Menu({ fields }: Props) {
	const { t } = useI18n();
	const { myMenuService, userService } = useGlobalStateContext();
	const menuIsOpen = useSelector(myMenuService, selectIsOpen);
	const isLoggingOut = useSelector(userService, selectIsLoggingOut);
	const username = useSelector(userService, selectUsername);

	const closeMenu = useCallback(() => {
		myMenuService.send('CLOSE_MENU');
	}, [myMenuService]);

	const hasVisibleLinks = fields.accountLinks?.some(
		(link) => link.fields.link?.href && link.fields.link.text,
	);
	if (!hasVisibleLinks) {
		return null;
	}

	return (
		<Popover
			isOpen={menuIsOpen}
			onClose={closeMenu}
			title={t('account_menu_heading')}
			fullSizeSmall
		>
			{username && (
				<p className="mb-2 font-bold">{`${t('account_intro_heading')} ${username}!`}</p>
			)}
			<p className="mb-6">{t('account_intro_sub_text')}</p>

			<AccountMenuLinks
				items={fields.accountLinks}
				onLogoutClick={() => {
					userService.send('LOGOUT');
				}}
				isLogoutLoading={isLoggingOut}
				size="large"
				logoutSize="small"
				// Same as above p, minus the item's top padding
				logoutClassName="mt-3"
			/>
		</Popover>
	);
}

Menu.displayName = 'Menu';

export default withRequiredProps(Menu, 'fields');
