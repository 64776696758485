import React, { useMemo, useState } from 'react';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Map, { mapStoreMarker } from 'components/Map';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import { useSelectedStore } from 'contexts';
import { withRequiredProps } from 'hoc';
import { useRelativeDistance } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Store } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { formatDistance } from 'utils/format';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		description: string;
		heading: string;
		stores: Store[];
	};
};

/** Component for listing and displaying stores. */
function StoreList({ fields }: Props) {
	const { t } = useI18n();

	const [isMapVisible, setIsMapVisible] = useState(true);
	const { selectedStore, setSelectedStore } = useSelectedStore();

	const {
		clearUserPosition,
		error: userPositionError,
		getUserPosition,
		isLoading: isLoadingUserPosition,
		itemsWithDistance: storesWithDistance,
		userPosition,
	} = useRelativeDistance({
		items: fields?.stores,
	});

	const allStoreMarkers = useMemo(
		() =>
			fields?.stores.map((store) =>
				mapStoreMarker({
					store,
					isSelected: selectedStore?.id === store.id,
					onSelectClick: () => setSelectedStore(store),
				}),
			),
		[fields?.stores, selectedStore, setSelectedStore],
	);

	const storesWithDistanceMarkers = useMemo(
		() =>
			storesWithDistance?.map((store) =>
				mapStoreMarker({
					store,
					isSelected: selectedStore?.id === store.id,
					onSelectClick: () => setSelectedStore(store),
				}),
			),
		[storesWithDistance, selectedStore, setSelectedStore],
	);

	const nearbyStoreMarkers = storesWithDistanceMarkers?.slice(0, 2) ?? [];
	const hasStoresWithDistance = storesWithDistance?.length > 0;

	return (
		<LayoutContainer withGrid outerClassName="mt-8 md:mt-12">
			<div className="col-span-4 md:col-span-5 lg:col-span-4">
				<Text as="h1" text={fields?.heading} />
				<Text as="pLarge" text={fields?.description} />

				<div className="mt-6">
					<ActionButton
						customState={
							isLoadingUserPosition
								? 'loading'
								: userPositionError
									? 'failure'
									: 'success'
						}
						onClick={userPosition ? clearUserPosition : getUserPosition}
						variant={userPosition ? 'secondary' : 'cta'}
						className="max-md:w-full"
					>
						<Icon icon={userPosition ? 'shareLocationOff' : 'shareLocation'} />
						{userPosition
							? t('general_forget_position_button')
							: t('general_use_position_button')}
					</ActionButton>

					<Button
						onClick={() => setIsMapVisible((current) => !current)}
						variant="text"
						className="mt-6 md:hidden"
					>
						{isMapVisible
							? t('stores_show_as_list_button')
							: t('stores_show_on_map_button')}
						<Icon className="ml-2" icon={isMapVisible ? 'rows' : 'location'} />
					</Button>
					<ScreenReaderAnnouncementText
						as="div"
						text={
							userPositionError ? (
								<InfoBox
									className="mt-4"
									icon="error"
									variant="error"
									message={
										userPositionError?.PERMISSION_DENIED
											? t('stores_geolocation_permission_error_text')
											: t('stores_geolocation_general_error_text')
									}
								/>
							) : undefined
						}
					/>
				</div>

				<div className={cn('mt-6', isMapVisible && 'max-md:hidden')}>
					<ScreenReaderAnnouncementText
						as="div"
						text={
							hasStoresWithDistance ? (
								<>
									<Text as="h2" className="mb-4">
										{t('stores_for_position_header')}
									</Text>
									<p className="sr-only">
										{storesWithDistance.map(({ name }) => name).join(', ')}
									</p>
								</>
							) : undefined
						}
					/>
					{hasStoresWithDistance && (
						<ul className="mb-8 flex flex-col gap-y-2">
							{storesWithDistance.map((store) => (
								<StoreListItem
									key={store.id}
									name={store.name}
									url={store.url}
									openHours={store.todaysOpeningHours?.description ?? ''}
									isOpen={
										store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'
									}
									address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
									storeArea={store.storeArea ?? ''}
									distance={t('general_distance_away_text', {
										distance: formatDistance(store.distance / 1000),
									})}
								/>
							))}
						</ul>
					)}

					{!hasStoresWithDistance && (
						<ul className="flex flex-col gap-y-2">
							{fields?.stores?.map((store) => (
								<StoreListItem
									key={store.id}
									name={store.name}
									url={store.url}
									openHours={store.todaysOpeningHours?.description ?? ''}
									isOpen={
										store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'
									}
									address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
									storeArea={store.storeArea ?? ''}
								/>
							))}
						</ul>
					)}
				</div>
			</div>
			<div className="col-span-4 max-md:mt-6 md:col-span-7 md:col-start-6">
				{isMapVisible && (
					<div className="flex h-[700px] max-h-[calc(100vh-10rem)] items-center justify-center bg-greyLighter md:sticky md:top-28">
						<Map
							nearbyStores={nearbyStoreMarkers}
							userPosition={userPosition}
							stores={allStoreMarkers ?? []}
							mapClassName="h-full w-full"
						/>
					</div>
				)}
			</div>
		</LayoutContainer>
	);
}
StoreList.displayName = 'StoreList';

export default withRequiredProps(StoreList, 'fields');
