/**
 * PriceRow
 */

import React, { ReactNode } from 'react';

import Text from 'components/Text';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';

interface Props {
	children?: ReactNode;
	price?: string;
	priceSymbol?: string;
	subtitle?: string;
	title?: string;
	variant?: 'minusPrice' | 'regular';
}

export default function PriceRow({
	children,
	price,
	priceSymbol,
	subtitle,
	title,
	variant = 'regular',
}: Props) {
	return (
		<div className="mb-4 flex break-inside-avoid flex-col justify-between">
			<div className="flex justify-between">
				<Text as="p">{title}</Text>
				<Text
					as="span"
					className={cn(
						'font-bold',
						variant === 'minusPrice' ? 'text-julaRed' : 'text-greyDarker',
					)}
				>
					{formatPriceText(price, priceSymbol)}
				</Text>
			</div>
			<div className="ml-4">
				<Text as="pSmall" className="text-greyDarker">
					{subtitle}
				</Text>
				{children}
			</div>
		</div>
	);
}
PriceRow.displayName = 'PriceRow';
