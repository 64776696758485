import { useCallback, useRef, useState } from 'react';

import { useSelectedStore } from 'contexts';
import { useStock } from 'hooks/data-fetching';
import { ItemStockResponse } from 'models/api';
import { is } from 'utils/helpers';

export function useProductStock({
	productId,
	variantIds,
}: {
	productId: string | undefined;
	variantIds?: string[];
}) {
	const { isLoading: selectedStoreIsLoading, selectedStore } =
		useSelectedStore();

	const [shouldLoadNearbyStores, setShouldLoadNearbyStores] = useState(false);
	const [shouldLoadAllStores, setShouldLoadAllStores] = useState(false);
	const [shouldLoadVariantsStock, setShouldLoadVariantsStock] = useState(false);

	const loadNearbyStores = useCallback(
		() => setShouldLoadNearbyStores(true),
		[],
	);
	const loadAllStores = useCallback(() => setShouldLoadAllStores(true), []);
	const loadVariantsStock = useCallback(
		() => setShouldLoadVariantsStock(true),
		[],
	);

	const {
		isLoading: isLoadingProductStock,
		itemsStock: productItemsStockInternal,
	} = useStock({
		variantIds: productId,
		storeIds: selectedStore?.id,
		isActive: !selectedStoreIsLoading && Boolean(productId),
		keepPreviousData: true,
	});

	const productItemsStock = useRef<ItemStockResponse[]>();
	if (productItemsStockInternal !== undefined) {
		productItemsStock.current = productItemsStockInternal;
	}

	const productStock = productItemsStock.current?.find(
		(variant) => variant.id === productId,
	);

	const nearbyStoreIds = productStock?.storeStock?.stocks
		?.find((store) => store.store?.id === selectedStore?.id)
		?.store.nearbyStores?.map((nearbyStore) => nearbyStore.store.id)
		.filter(is.truthy);

	const {
		isLoading: isLoadingNearbyStoresStock,
		itemsStock: nearbyStoresItemsStock,
	} = useStock({
		variantIds: productId,
		storeIds: nearbyStoreIds,
		isActive: shouldLoadNearbyStores && Boolean(nearbyStoreIds),
	});

	const {
		isLoading: isLoadingAllStoresStock,
		itemsStock: allStoresItemsStock,
	} = useStock({
		variantIds: productId,
		isActive: shouldLoadAllStores,
	});

	const { isLoading: isLoadingVariantsStock, itemsStock: variantsStock } =
		useStock({
			variantIds,
			storeIds: selectedStore?.id,
			isActive: shouldLoadVariantsStock,
		});

	// fall back to get the store stock from all stores array
	// to avoid losing data when getting data for the new store
	// since we already have it in all stores
	const currentStoreStock =
		productStock?.storeStock?.stocks?.find(
			(store) => store.store?.id === selectedStore?.id,
		) ??
		allStoresItemsStock
			?.find((variant) => variant.id === productId)
			?.storeStock?.stocks?.find(
				(store) => store.store?.id === selectedStore?.id,
			);

	return {
		isLoadingNearbyStoresStock,
		isLoadingProductStock,
		isInitialLoadingProductStock: isLoadingProductStock && !productStock,
		isLoadingVariantsStock,
		isLoadingAllStoresStock,
		allStoresStock: allStoresItemsStock?.find(
			(variant) => variant.id === productId,
		)?.storeStock?.stocks,
		nearbyStoresStock: nearbyStoresItemsStock?.find(
			(variant) => variant.id === productId,
		)?.storeStock?.stocks,
		productStock,
		variantsStock,
		loadAllStores,
		loadNearbyStores,
		loadVariantsStock,
		currentStoreStock,
	};
}
