import React, { Fragment, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSelector } from '@xstate/react';
import type { State } from 'xstate';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import { Checkbox, FieldOnChange, TextInput } from 'components/FinalForm';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import type { JulaProUpdatedReference } from 'models/api';
import {
	type ReferenceManagementMachineContext,
	selectAddReferenceError,
	selectContactList,
} from 'state-machines/referenceManagement';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** onClose callback */
	onClose: () => void;

	/** onSubmit callback */
	onSubmit: (newReference: JulaProUpdatedReference) => void;

	/** Should Popover be opened */
	open: boolean;

	/** reference manager machine actor */
	referenceManagementActor: any;

	showReferences?: boolean;

	/** submit button state */
	submitButtonState: ActionButtonState;
}

export default function AddReferenceForm({
	onClose,
	onSubmit,
	open,
	referenceManagementActor,
	showReferences = true,
	submitButtonState,
}: Props) {
	const { t } = useI18n();

	const contactList = useSelector(referenceManagementActor, selectContactList);
	const addReferenceError = useSelector(
		referenceManagementActor,
		selectAddReferenceError,
	);

	useEffect(() => {
		if (open) {
			referenceManagementActor.send('GET_CONTACTS');
		}
	}, [open]);

	const submitAddReference = async (values, form): Promise<any> => {
		const newReference = {
			newReferenceName: values.referenceName,
		} as JulaProUpdatedReference;
		newReference.contactPermissions =
			contactList?.map((contact) => ({
				customerContactId: contact.customerContactId,
				canUseReference: values[`contact-${contact.customerContactId}`],
			})) || [];

		onSubmit(newReference);

		const state = await waitFor(
			referenceManagementActor,
			(referenceMachineState: State<ReferenceManagementMachineContext>) =>
				referenceMachineState.hasTag('addReferenceRequestEnded'),
			{
				// 20 seconds in ms
				timeout: 20_000,
			},
		);

		if (state?.context?.addReferenceError) {
			return state?.context?.addReferenceError?.fieldValidationErrors;
		}

		setTimeout(() => {
			form.reset();
			onClose();
		}, 2500);
	};

	const composeValidators =
		(...validators) =>
		(value, values, field) =>
			validators.reduce(
				(error, validator) => error || validator(value, field.name),
				undefined,
			);
	const required = (value) => (value ? undefined : t('FieldIsRequired'));

	return (
		<Popover
			isOpen={open}
			onClose={onClose}
			title={`${t('julapro_references_add_reference_overlay_heading')}`}
		>
			<p>{t('julapro_references_add_reference_form_description')}</p>

			{is.arrayWithLength(addReferenceError?.businessLogicErrors) && (
				<div className="sticky top-0 z-99 mt-4 space-y-2">
					{addReferenceError.businessLogicErrors.map((error) => (
						<InfoBox
							key={error.key}
							icon="error"
							variant="error"
							message={error.text}
						/>
					))}
				</div>
			)}

			<Form
				onSubmit={submitAddReference}
				initialValues={{}}
				render={({ form, handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						id="addJulaProReference"
						className="mt-6"
					>
						<TextInput
							id="code"
							name="referenceName"
							label={t('julapro_references_edit_form_code_label')}
							placeholder={t('julapro_references_edit_form_code_placeholder')}
							validate={composeValidators(required)}
						/>
						{showReferences && (
							<>
								{!contactList && (
									<Skeleton>
										<SkeletonItem height="3.5rem" className="my-4" />
									</Skeleton>
								)}
								{contactList && (
									<fieldset className="mb-8 mt-4 space-y-4">
										<legend>
											{t('julapro_references_edit_form_users_label')}
										</legend>

										<Checkbox
											id="checkAll"
											name="checkAll"
											label={t('julapro_references_form_check_all_users')}
										/>
										<FieldOnChange<boolean> name="checkAll">
											{(value) => {
												if (!value) return;
												contactList?.map((contact) => {
													form.change(
														`contact-${contact.customerContactId}`,
														value,
													);
												});
											}}
										</FieldOnChange>
										{contactList?.map((contact) => (
											<Fragment key={contact.customerContactId}>
												<Checkbox
													key={contact.customerContactId}
													id={`contact-${contact.customerContactId}`}
													name={`contact-${contact.customerContactId}`}
													label={contact.name}
												/>
												<FieldOnChange<boolean>
													name={`contact-${contact.customerContactId}`}
												>
													{(value) => {
														if (!value) {
															form.change('checkAll', false);
														}
													}}
												</FieldOnChange>
											</Fragment>
										))}
									</fieldset>
								)}
							</>
						)}
						<FormSpy subscription={{ values: true }}>
							{({ values }) => {
								const valuesArray = Object.values(values);
								return (
									<ActionButton
										data-cid="julaproAddReference"
										displayWidth="full"
										type="submit"
										size="large"
										variant="cta"
										disabled={showReferences && !valuesArray.includes(true)}
										customState={submitButtonState}
										className="m-0"
									>
										{t('julapro_references_add_reference_button_text')}
									</ActionButton>
								);
							}}
						</FormSpy>
					</form>
				)}
			/>
		</Popover>
	);
}
AddReferenceForm.displayName = 'AccountJulaProReferences_AddReferenceForm';
