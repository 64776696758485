/**
 * CountryListItem
 */

import React from 'react';

import Img from 'components/Img';
import Text from 'components/Text';

interface Props {
	/** The image source */
	imgSrc: string;

	/** The image alt text */
	imgAlt?: string;

	/** The heading of the item */
	heading: string;

	/** The preamble text of the item */
	preamble: string;
}

/** The "list item" the country listing for the maintenance page. */
export default function CountryListItem({
	imgSrc,
	imgAlt = '',
	heading,
	preamble,
}: Props) {
	return (
		<div className="col-span-4">
			<div className="mb-4 md:mb-0">
				<div className="mb-2 size-8 sm:mb-3 sm:size-14">
					<Img src={imgSrc} alt={imgAlt} className="h-full w-full" />
				</div>
				<Text as="h2" className="mb-2">
					{heading}
				</Text>
				<p className="mb-2 font-standard text-base md:text-lg">{preamble}</p>
			</div>
		</div>
	);
}
CountryListItem.displayName = 'CountryListItem';
