/**
 * GlobalCartAddedProductPopover
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import AddedProductPopover from 'components/AddedProductPopover';
import { useGlobalStateContext } from 'contexts';
import { useProductListGTMEvents } from 'hooks';
import {
	selectAddedProduct,
	selectAddedProductPopoverIsOpen,
	selectAdditionalSalesProducts,
	selectCartMiniMessage,
	selectPurchaseButtonState,
} from 'state-machines/cart';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

/** A global popover that shows product added to the cart, possible additional sales products as well as actions to take */
export default function GlobalCartAddedProductPopover() {
	const { t } = useI18n();
	const { cartService } = useGlobalStateContext();
	const isOpen = useSelector(cartService, selectAddedProductPopoverIsOpen);
	const addedProduct = useSelector(cartService, selectAddedProduct);
	const addProductMessage = useSelector(cartService, selectCartMiniMessage);
	const additionalSalesProducts = useSelector(
		cartService,
		selectAdditionalSalesProducts,
	);
	const buttonState = useSelector(cartService, selectPurchaseButtonState);

	const {
		gtmItemListId: additionalSalesGTMItemListId,
		gtmItemListName: additionalSalesGTMItemListName,
		sendAddToCartEvent: sendAdditionalSalesAddEvent,
	} = useProductListGTMEvents(
		'pdp_add_to_cart_upsale',
		`List: Add to cart upsale ${addedProduct?.id}`,
	);

	return (
		<AddedProductPopover
			addedLocationLink="/cart"
			goToAddedLocationButtonText={t('continue_to_cart')}
			addedProduct={addedProduct}
			buttonState={buttonState}
			gtmItemListId={additionalSalesGTMItemListId}
			gtmItemListName={additionalSalesGTMItemListName}
			id="global-cart-added-product-popover"
			isOpen={isOpen}
			onAddItem={(additionalSalesProduct) => {
				cartService.send({
					type: 'ADD_ONE_FROM_BUY_BUTTON',
					variantId: additionalSalesProduct.id,
					metaData: {
						gtmItemListId: additionalSalesGTMItemListId,
						gtmItemListName: additionalSalesGTMItemListName,
					},
				});
				sendAdditionalSalesAddEvent(additionalSalesProduct, 1);
				sendGlobalEvent('engagement', {
					type: 'addAccessoryFromAdditionalSales',
				});
			}}
			onClose={() => cartService.send({ type: 'RESET_ADDED_PRODUCT_POPOVER' })}
			products={additionalSalesProducts}
			title={addProductMessage || ''}
		/>
	);
}
GlobalCartAddedProductPopover.displayName = 'GlobalAdditionalSalesPopover';
