import React from 'react';

import Button from 'components/Button';
import { Prices } from 'components/ProductDetails';
import Rating from 'components/Rating';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import {
	StoreStockBalanceConcise,
	WebStockBalanceConcise,
} from 'components/Stock';
import Text from 'components/Text';
import { StoreIdName, useFeatureToggle } from 'contexts';
import { ItemStockResponse } from 'models/api';
import { ProductResponse } from 'models/api/variant';
import {
	getVariantPickerStoreStockStatus,
	getVariantPickerWebStockStatus,
} from 'utils/business-logic/stock';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductPopoverImage from './ProductPopoverImage';
import ProductPopoverSubtitle from './ProductPopoverSubtitle';
import ProductPopoverTitle from './ProductPopoverTitle';

interface Props {
	isLoadingProductStock?: boolean | undefined;
	product?: ProductResponse | undefined;
	productStock?: ItemStockResponse | undefined;
	selectedStore?: StoreIdName | undefined;
}

export default function CampaignViewerContent({
	isLoadingProductStock,
	product,
	productStock,
	selectedStore,
}: Props) {
	const { t } = useI18n();
	const { storesEnabled } = useFeatureToggle();

	if (!product) {
		return null;
	}

	const { id, reviewScore, usPs } = product;

	const storeStock = productStock?.storeStock?.stocks?.find(
		(store) => store.store?.id === selectedStore?.id,
	);

	return (
		<>
			<ProductPopoverTitle
				subTitle={product.subTitle}
				title={product.title}
				titleExclModel={product.titleExclModel}
				headingLevel="h3"
			/>
			<ProductPopoverSubtitle
				brandTitle={product.brand?.title}
				model={product.model}
			/>

			{reviewScore?.score && reviewScore.score > 0 && (
				<Rating
					className="mt-2"
					reviewCountVariant="numberOnly"
					score={reviewScore.score}
					reviewCount={reviewScore.count}
				/>
			)}
			<ProductPopoverImage product={product} className="mt-4" />

			<Prices
				displayPrice={product.displayPrice}
				basePrice={product.basePrice}
			/>
			<div className="mt-6 inline-flex flex-col">
				{is.arrayWithLength(usPs) && (
					<ul className="inline-flex list-inside list-disc flex-col gap-2 rounded-xl bg-greyLighter p-4 font-bold">
						{usPs.map((usp) => (
							<li key={usp}>{usp}</li>
						))}
					</ul>
				)}
				<Text as="p" className="mt-4">
					<span className="font-bold">
						{t('product_details_article_number_label')}:
					</span>{' '}
					{id}
				</Text>
			</div>
			{isLoadingProductStock && (
				<Skeleton className="mt-6 flex flex-col gap-y-1">
					<SkeletonItem height="1.2rem" width="40%" />
					<SkeletonItem height="1.2rem" width="40%" />
				</Skeleton>
			)}
			{!isLoadingProductStock && (
				<div className="mt-6">
					<WebStockBalanceConcise
						status={getVariantPickerWebStockStatus({
							isSellable: product.isSellable,
							isSellableOnWeb: product.isSellableOnWeb,
							reStockWithinAcceptableTimespan: Boolean(
								productStock?.webStock?.reStockWithinAcceptableTimespan,
							),
							availableStockLevel:
								productStock?.webStock?.availableStockLevel ?? 0,
						})}
					/>
					{storesEnabled && (
						<StoreStockBalanceConcise
							productInStockAtStores={
								productStock?.storeStock?.inStockCount ?? 0
							}
							storeName={selectedStore?.name || ''}
							status={getVariantPickerStoreStockStatus({
								storeSelected: Boolean(selectedStore?.name),
								inStock: Boolean(storeStock?.inStock),
								productInStockAtStores:
									productStock?.storeStock?.inStockCount ?? 0,
							})}
						/>
					)}
				</div>
			)}
			{product.url && (
				<Button
					displayWidth="full"
					variant="primary"
					href={product.url}
					target="_blank"
					className="mt-6"
				>
					{t('campaign_viewer_popover_go_to_product_button')}
				</Button>
			)}
		</>
	);
}
CampaignViewerContent.displayName = 'CampaignViewerContent';
