/**
 * ActionLink
 */

import React from 'react';

import Button, { type ButtonVariant } from 'components/Button';
import Icon from 'components/Icon';
import { useGlobalStateContext } from 'contexts';
import { useChat } from 'hooks';
import type { ActionLink as ActionLinkModel } from 'models/sitecore';
import { is, sendGlobalEvent } from 'utils/helpers';

const NON_NUMBERS_REGEXP = /\D/g;

export function getPhoneNumberFromText(text: string | undefined): string {
	return text?.replace(NON_NUMBERS_REGEXP, '') || '';
}
export function isValidPhoneNumberText(text: string | undefined): boolean {
	return getPhoneNumberFromText(text).length >= 5;
}

function openOneTrustSettings() {
	if (is.func(globalThis.OneTrust?.ToggleInfoDisplay)) {
		globalThis.OneTrust.ToggleInfoDisplay();
	}
}

interface Props extends ActionLinkModel {
	buttonVariant?: ButtonVariant;
	className?: string;
	underline?: boolean;
}

export default function ActionLink({
	buttonVariant = 'secondary',
	className,
	href: hrefProp,
	icon,
	target,
	targetTitle,
	text,
	type,
	underline = false,
}: Props) {
	const { globalPopoverService } = useGlobalStateContext();
	const { openChatOrPopover } = useChat();

	if (!type || !text) {
		return null;
	}

	const href =
		type === 'Phone'
			? `tel:${getPhoneNumberFromText(text) || getPhoneNumberFromText(hrefProp)}`
			: hrefProp;
	const isPopover = type === 'Popover' && href;
	const children = (
		<>
			{icon && <Icon icon={icon} />}
			{text}
		</>
	);

	// TypeScript doesn't like href + target when both are potentially undefined,
	// be explicit about the link case.
	if (is.oneOf(type, 'Phone', 'Link') && href) {
		return (
			<Button
				className={className}
				href={href}
				target={target}
				underline={underline}
				variant={buttonVariant}
			>
				{children}
			</Button>
		);
	}

	return (
		<Button
			className={className}
			underline={underline}
			variant={buttonVariant}
			aria-haspopup={isPopover ? 'dialog' : undefined}
			onClick={() => {
				if (isPopover) {
					globalPopoverService.send({
						type: 'OPEN',
						target: href,
						heading: targetTitle || text,
					});
				}
				if (type === 'Cookies') {
					openOneTrustSettings();
				}
				if (type === 'Chat') {
					openChatOrPopover();
				}
				if (is.oneOf(type, 'LoginJulaClub', 'LoginJulaPro')) {
					sendGlobalEvent('open-login', { loginType: type });
				}
			}}
		>
			{children}
		</Button>
	);
}
ActionLink.displayName = 'ActionLink';
