import { useState } from 'react';
import { waitFor } from 'xstate/lib/waitFor';

import { useGlobalStateContext } from 'contexts';

export function useShareWishlist() {
	const [copiedToClipboard, setCopiedToClipboard] = useState(false);
	const [isSharing, setIsSharing] = useState(false);

	const { wishlistService } = useGlobalStateContext();
	const { send } = wishlistService;

	const shareWishlist = async () => {
		setIsSharing(true);
		send({ type: 'SHARE_WISHLIST' });
		const wishlistState = await waitFor(wishlistService, (state) =>
			state.hasTag('wishlistWasShared'),
		);

		const wishlistShareId = wishlistState?.context.wishlistShareId;
		if (wishlistShareId) {
			const url = `${globalThis.location.href}${wishlistShareId}/`;
			await globalThis.navigator.clipboard.writeText(url);
			setCopiedToClipboard(true);
			setTimeout(() => {
				setCopiedToClipboard(false);
			}, 3000);
		}

		setIsSharing(false);
	};

	return { copiedToClipboard, isSharing, shareWishlist } as const;
}
