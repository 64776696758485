/**
 * ImageSlider
 */

import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import Img from 'components/Img';
import ThumbnailList from 'components/ThumbnailList';
import {
	useDebounce,
	useDebouncedCallback,
	useEffectOnce,
	useMinWidth,
} from 'hooks';
import type { Thumbnail } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { empty } from 'utils/helpers';

import 'keen-slider/keen-slider.min.css';

interface BaseProps {
	className?: string;
	images: Thumbnail[];
	onSlideChange?: (imageIndex: number) => void;
	onSlideClick: (imageIndex: number) => void;
	sliderButtonLabel: string;
	triggersDialog?: boolean;
}
interface PropsWithoutMaxVisible extends BaseProps {
	maxVisibleImages?: never;
	onShowAllClick?: never;
}
interface PropsWithMaxVisible extends BaseProps {
	maxVisibleImages: number;
	onShowAllClick: () => void;
}
type Props = PropsWithoutMaxVisible | PropsWithMaxVisible;

/** Horizontal image slider with thumbnail controls. */
export default function ImageSlider({
	className,
	images,
	maxVisibleImages,
	onShowAllClick,
	onSlideChange,
	onSlideClick,
	sliderButtonLabel,
	triggersDialog,
}: Props) {
	const isMinMd = useMinWidth('md');
	const maxVisible = Math.max(1, maxVisibleImages ?? 999);
	const visibleCount = maxVisible < images.length ? maxVisible - 1 : undefined;

	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	// Small screens have an animation while large screens don't. Wait for that
	// animation to finish on small screens before using the new index.
	const currentSlideIndexDebounced = useDebounce(currentSlideIndex, 150);
	const slideIndex = isMinMd ? currentSlideIndex : currentSlideIndexDebounced;

	// Keen runs slideChanged for every slide, e.g. even when moving from
	// index 1 to index 5 with a single call. Debounce to only run callback
	// for the slide landed on.
	const [debouncedOnSlideChange] = useDebouncedCallback(
		onSlideChange ?? (() => {}),
		150,
		[onSlideChange],
	);
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		slides: { perView: 1 },
		slideChanged(slider) {
			const currentIndex = slider.track.details.rel;
			debouncedOnSlideChange(currentIndex);
			setCurrentSlideIndex(currentIndex);
		},
	});
	const keenSlider = instanceRef.current;

	// Default to scrolling container and enable slider on initial render.
	const [shouldEnableSlider, setShouldEnableSlider] = useState(false);
	useEffectOnce(() => {
		if (images.length > 1) {
			setShouldEnableSlider(true);
		}
	});

	return (
		<div className={cn('md:flex md:gap-6', className)}>
			<ThumbnailList
				className={cn(
					'shrink-0 max-md:hidden',
					!shouldEnableSlider && 'invisible',
				)}
				direction="column"
				images={images}
				size={80}
				selectedImageIndex={slideIndex}
				maxVisible={visibleCount ?? 999}
				onThumbnailClick={(i) => {
					keenSlider?.moveToIdx(i, false, { duration: 0 });
				}}
				onShowAllClick={onShowAllClick ?? empty.func}
				showAllTriggersDialog={triggersDialog}
			/>

			<div
				ref={shouldEnableSlider ? sliderRef : undefined}
				className={cn(
					'grow',
					!shouldEnableSlider && 'flex overflow-x-auto',
					shouldEnableSlider && 'keen-slider',
				)}
			>
				{images.slice(0, visibleCount).map((image, i) => (
					<button
						key={image.src}
						type="button"
						className={cn(
							!shouldEnableSlider && 'w-full shrink-0',
							shouldEnableSlider && 'keen-slider__slide',
							[
								// The slide hides overflow which means no outline is visible,
								// use a pseudo element as replacement.
								'focus-visible:after:absolute',
								'focus-visible:after:inset-0',
								'focus-visible:after:z-5',
								'focus-visible:after:pointer-events-none',
								// TODO: Replace with a common outline style
								'focus-visible:after:border',
								'focus-visible:after:border-2',
								'focus-visible:after:border-black',
							],
						)}
						aria-label={
							image.alt
								? `${sliderButtonLabel}: ${image.alt}`
								: sliderButtonLabel
						}
						aria-haspopup={triggersDialog ? 'dialog' : undefined}
						tabIndex={i === slideIndex ? undefined : -1}
						onClick={() => {
							onSlideClick(i);
						}}
					>
						<Img
							src={image.src}
							alt={image.alt}
							service="nextjs"
							priority={i === 0}
							width={432}
							height={432}
							className="mx-auto max-md:size-96 max-sm:size-56"
						/>
					</button>
				))}
			</div>

			<ThumbnailList
				className={cn('mt-2 md:hidden', !shouldEnableSlider && 'invisible')}
				direction="row"
				images={images}
				size={56}
				selectedImageIndex={slideIndex}
				maxVisible={visibleCount ?? 999}
				onThumbnailClick={(i) => {
					keenSlider?.moveToIdx(i);
				}}
				onShowAllClick={onShowAllClick ?? empty.func}
				showAllTriggersDialog={triggersDialog}
			/>
		</div>
	);
}
ImageSlider.displayName = 'ImageSlider';
