import React from 'react';
import { Field, FieldInputProps, FieldMetaState } from 'react-final-form';

import { cn } from 'utils/classNames';

interface Props {
	bgColor?: string;
	disabled?: boolean;
	id: string;
	/** Final form field props */
	input: FieldInputProps<any>;
	label: string;
	margin?: boolean;
	/** Final form field props */
	meta: FieldMetaState<any>;
	required?: boolean;
}
export function RadioButtonInput(props: Props) {
	const {
		bgColor,
		disabled = false,
		id,
		input,
		label,
		margin = true,
		meta,
		required = false,
	} = props;

	return (
		<div
			className={cn(
				margin && 'mb-6',
				'flex content-center',
				disabled && 'text-grey',
			)}
		>
			<input
				type="radio"
				id={id}
				required={required}
				className={cn(
					`h-6 w-6 appearance-none ${
						input.checked ? bgColor || 'bg-julaRed' : 'bg-greyLight'
					} rounded-full`,
					!disabled &&
						'hover:focus cursor-pointer hover:border-solid hover:ring-2 hover:ring-grey',
				)}
				{...input}
			/>
			<label
				className={cn(
					'ml-4',
					disabled ? 'pointer-events-none' : 'cursor-pointer',
				)}
				htmlFor={id}
			>
				{label}
			</label>
		</div>
	);
}
RadioButtonInput.displayName = 'FinalForm_RadioButtonInput';

export default function RadioButton(props: any) {
	return <Field component={RadioButtonInput} {...props} type="radio" />;
}
RadioButton.displayName = 'FinalForm_RadioButton';
