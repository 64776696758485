/**
 * AuthJulaConnect
 */

import React, { useRef } from 'react';
import { useSelector } from '@xstate/react';

import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import { LoginMethod } from 'models/connect/oauth';
import {
	AuthCustomerType,
	selectIframeSrc,
	selectIframeType,
	selectIsLoadingGuiFull,
	selectIsLoadingGuiIframe,
} from 'state-machines/authentication/';
import { cn } from 'utils/classNames';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
}

function getTitleKey({
	customerType,
	loginMethod,
}: {
	customerType: AuthCustomerType | undefined;
	loginMethod: LoginMethod | undefined;
}) {
	switch (true) {
		case customerType === 'Club Staff' && loginMethod === 'otp_email':
			return 'jula_club_login_email_heading';
		case customerType === 'Club Staff' && loginMethod === 'otp_sms':
			return 'jula_club_login_sms_heading';
		case customerType === 'Club Staff' && loginMethod?.includes('bank_id'):
			return 'jula_club_login_bank_id_heading';
		case customerType === 'Pro' && loginMethod === 'otp_email':
			return 'jula_pro_login_email_heading';
		case customerType === 'Pro' && loginMethod === 'otp_sms':
			return 'jula_pro_login_sms_heading';
		case customerType === 'Pro' && loginMethod?.includes('bank_id'):
			return 'jula_pro_login_bank_id_heading';
		case customerType === 'Unregistered_Club' &&
			loginMethod?.includes('bank_id'):
			return 'jula_club_become_member_login_bank_id_heading';
		case customerType === 'Unregistered_Pro' &&
			loginMethod?.includes('bank_id'):
			return 'jula_pro_become_member_login_bank_id_heading';
		default:
			return '';
	}
}

/** Creates an iframe for embedding JulaConnect to handle login. */
export default function AuthJulaConnect({ className }: Props) {
	const { authService } = useGlobalStateContext();
	const isLoadingGuiIframe = useSelector(authService, selectIsLoadingGuiIframe);
	const isLoadingGuiFull = useSelector(authService, selectIsLoadingGuiFull);

	const iframeSrc = useSelector(authService, selectIframeSrc);

	const iframeType = useSelector(authService, selectIframeType);
	const customerType = useSelector(
		authService,
		(state) => state.context.customerType,
	);
	const loginMethod = useSelector(
		authService,
		(state) => state.context.loginMethod,
	);
	const iFrameTitleKey = getTitleKey({ customerType, loginMethod });
	const { t } = useI18n();
	const iframeRef = useRef<HTMLIFrameElement>(null);

	return (
		<div className={cn('flex min-h-[28rem] flex-col items-center', className)}>
			{isLoadingGuiIframe && (
				<LoadingSpinner
					variant="dashing"
					spinnerColor="julaRed"
					trackColor="transparent"
					size="medium"
				/>
			)}

			{isLoadingGuiFull && (
				<>
					<Text
						as="h2"
						styleAs="h2"
						className="mb-2 mt-4 text-greyDarker sm:mb-4 sm:mt-4"
					>
						{t('account_waiting_for_login_text')}
					</Text>

					<LoadingSpinner
						variant="dashing"
						spinnerColor="julaRed"
						trackColor="transparent"
						size="medium"
					/>

					<Button
						variant="text"
						className="!text-center"
						displayWidth="full"
						onClick={() => {
							authService.send('ABORT_AUTHENTICATION');
						}}
					>
						{t('account_cancel_login_button')}
					</Button>
				</>
			)}

			{iframeSrc && iframeType === 'gui_iframe' && (
				<iframe
					ref={iframeRef}
					src={iframeSrc}
					className="w-full grow"
					data-cid="jula-connect-iframe"
					data-cy={getTestDataAttrFrom('jula-connect-iframe')}
					title={t(iFrameTitleKey)}
					onLoad={() => {
						iframeRef.current?.focus();
						authService.send('LOGIN_FORM_LOADED');
					}}
				/>
			)}
		</div>
	);
}

AuthJulaConnect.displayName = 'AuthJulaConnect';
