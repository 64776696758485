/**
 * Login
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';

import AuthJulaConnect from 'components/AuthJulaConnect';
import Popover from 'components/Popover';
import Tabs from 'components/Tabs';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import { useGlobalLinks } from 'hooks';
import { LoginMethod } from 'models/connect/oauth';
import {
	selectErrorMessage,
	selectIsAuthenticatedUser,
	selectIsAwaitingAuthAction,
} from 'state-machines/authentication';
import { LoginPanelType } from 'types';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ClubLogin from './ClubLogin';
import ProLogin from './ProLogin';

interface Props {
	/** The heading in the log in. */
	header: string;

	/** The Popover id */
	id: string;

	initialTab?: LoginPanelType;

	/** True if the log in should be open. */
	isOpen: boolean;

	/** Callback when login closes */
	onClose: () => void;
}

/** Login popover panel. */
export default function Login({
	header,
	id,
	initialTab = 'LoginJulaClub',
	isOpen,
	onClose,
}: Props) {
	const { authService, globalPopoverService, userService } =
		useGlobalStateContext();
	const { signUpJulaClub, signUpJulaPro } = useGlobalLinks();
	const isAwaitingAuthAction = useSelector(
		authService,
		selectIsAwaitingAuthAction,
	);
	const { julaClubEnabled, julaProEnabled } = useFeatureToggle();

	const errorMessage = useSelector(authService, selectErrorMessage);

	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	const { t } = useI18n();
	const [activeTab, setActiveTab] = useState<LoginPanelType>(initialTab);

	useEffect(() => {
		setActiveTab(initialTab);
	}, [initialTab]);

	const sendGlobalPopoverOpen = (
		target: string | undefined,
		heading: string,
	) => {
		if (target) {
			globalPopoverService.send({
				type: 'OPEN',
				target,
				heading,
			});
			// Close the login popover
			onClose();
		}
	};

	const popoverHeaderId = `popover-${id}-title`;
	const clubTabId: LoginPanelType = 'LoginJulaClub';
	const proTabId: LoginPanelType = 'LoginJulaPro';
	return (
		<Popover
			isOpen={isOpen}
			title={header}
			id={`popover-${id}`}
			titleId={popoverHeaderId}
			onClose={() => {
				onClose();
				if (!isAuthenticatedUser) {
					authService.send('RELOAD_IFRAME');
				}
			}}
			contentClassName="flex flex-col"
		>
			{!isAuthenticatedUser && !isAwaitingAuthAction && (
				<AuthJulaConnect className="grow" />
			)}
			{isAwaitingAuthAction && (
				<Tabs
					data-cy={getTestDataAttrFrom('login-tabs')}
					tabListLabelledBy={popoverHeaderId}
					activeTabId={activeTab}
					onTabChange={(tab) => {
						setActiveTab(tab as LoginPanelType);
					}}
					items={[
						julaClubEnabled && {
							id: clubTabId,
							title: 'JulaClub',
							content: (
								<ClubLogin
									showHeading={false}
									errorMessage={errorMessage}
									onSelectLoginMethod={(loginMethod: LoginMethod) => {
										authService.send({
											type: 'SELECT_LOGIN_CLUB_STAFF',
											loginMethod,
										});
									}}
									onBecomeMemberClick={() => {
										sendGlobalPopoverOpen(
											signUpJulaClub,
											t('account_julaclub_become_member_button'),
										);
									}}
								/>
							),
						},
						julaProEnabled && {
							id: proTabId,
							title: 'JulaPro',
							content: (
								<ProLogin
									showHeading={false}
									errorMessage={errorMessage}
									onSelectLoginMethod={(loginMethod: LoginMethod) => {
										authService.send({
											type: 'SELECT_LOGIN_PRO',
											loginMethod,
										});
									}}
									onBecomeMemberClick={() => {
										sendGlobalPopoverOpen(
											signUpJulaPro,
											t('account_julapro_become_member_button'),
										);
									}}
								/>
							),
						},
					]}
				/>
			)}
		</Popover>
	);
}
Login.displayName = 'Login';
