/**
 * ProductListFiltersPopover
 */

import React, {
	type ChangeEventHandler,
	type MouseEventHandler,
	useState,
} from 'react';

import Button from 'components/Button';
import Count from 'components/Count';
import { Checkbox, RadioGroup } from 'components/FormUi';
import Icon from 'components/Icon';
import Popover from 'components/Popover';
import type { FacetResponse } from 'models/api';
import type { ProductListSortOption } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { slugify } from 'utils/string';

interface MainItemProps {
	className?: string;
	onClick: MouseEventHandler<HTMLButtonElement>;
	selected?: (string | undefined)[];
	showSelectedCount?: boolean;
	text: string;
}

function MainItem({
	className,
	onClick,
	selected: selectedProp,
	showSelectedCount,
	text,
}: MainItemProps) {
	const selected = (selectedProp ?? []).filter(is.truthy);
	const selectedCount = selected.length;

	return (
		<button
			type="button"
			onClick={onClick}
			className={cn(
				'group/filterMainItem block w-full border-b border-b-greyLight py-4 text-left',
				className,
			)}
		>
			<div className="flex items-center">
				<span className="pr-4 font-bold group-hover/filterMainItem:underline">
					{text}
				</span>
				{showSelectedCount && selectedCount > 0 && (
					<Count
						variant="badge"
						color="red"
						className="relative -left-2"
						margin="right"
						amount={selectedCount}
					/>
				)}
				<Icon icon="arrow" className="ml-auto" />
			</div>
			{selectedCount > 0 && (
				<span className="text-sm">
					{/* Whitespace for screen readers, without it this text is smooshed
					    together with the main text or count. */}
					<span className="sr-only"> </span>
					{selected?.join(', ')}
				</span>
			)}
		</button>
	);
}
MainItem.displayName = 'ProductListFiltersPopover_MainItem';

interface Props {
	disabled?: boolean;
	facets: FacetResponse[];
	onFacetChange: ChangeEventHandler<HTMLInputElement>;
	onReset: () => void;
	onSortChange: ChangeEventHandler<HTMLInputElement>;
	selectedFacetValues: [string, string][];
	selectedSortOptionValue: string;
	sortOptions: ProductListSortOption[];
	totalProductsCount: number;
}

/** Popover with filtering and sorting controls, plus its trigger button. */
export default function ProductListFiltersPopover({
	disabled,
	facets,
	onFacetChange,
	onReset,
	onSortChange,
	selectedFacetValues,
	selectedSortOptionValue,
	sortOptions,
	totalProductsCount,
}: Props) {
	const { t } = useI18n();
	const [isOpen, setIsOpen] = useState(false);
	const [currentPageIndex, setCurrentPageIndex] = useState(0);
	const [currentFacet, setCurrentFacet] = useState<FacetResponse | undefined>(
		facets[0],
	);
	const [selectedType, setSelectedType] = useState<'sorting' | 'facet'>(
		'sorting',
	);

	if (sortOptions.length === 0 && facets.length === 0) {
		return null;
	}

	const selectedFacetsCount = selectedFacetValues.length;
	const hasSelectedFacets = selectedFacetsCount > 0;
	const selectedSortOption = sortOptions.find(
		(opt) => opt.attribute === selectedSortOptionValue,
	);
	const baseId = 'product-list-filters-popover';

	return (
		<>
			<Button
				variant="primary"
				size="medium"
				className="min-w-[10rem] flex-shrink-0"
				onClick={() => {
					setIsOpen(true);
				}}
				aria-haspopup="dialog"
			>
				<Icon
					icon="filter"
					className={cn('mr-2', hasSelectedFacets && 'hidden')}
				/>
				{hasSelectedFacets
					? t('product_list_change_filters_mobile_button')
					: t('product_list_show_filters_mobile_button')}
				<Count
					variant="badge"
					color="white"
					amount={selectedFacetsCount}
					className={cn(!hasSelectedFacets && 'hidden')}
				/>
			</Button>
			<Popover
				id={baseId}
				isOpen={isOpen}
				currentPageIndex={currentPageIndex}
				// Changing sort/filters results in a route update, ignore it.
				shouldCloseOnNavigation={false}
				onClose={() => {
					setIsOpen(false);
				}}
				onBackClick={() => {
					setCurrentPageIndex(0);
				}}
				pages={[
					{
						title: t('product_list_filters_heading'),
						content: (
							<div className="-mt-4">
								{sortOptions.length > 0 && (
									<MainItem
										text={t('product_list_sorting_heading')}
										selected={[selectedSortOption?.name]}
										onClick={() => {
											setCurrentPageIndex(1);
											setSelectedType('sorting');
										}}
									/>
								)}
								{facets.map((facet) => (
									<MainItem
										key={facet.id}
										text={facet.title}
										selected={selectedFacetValues
											.filter(([key]) => key === facet.id)
											.map(([, val]) => val)}
										showSelectedCount
										onClick={() => {
											setCurrentPageIndex(1);
											setSelectedType('facet');
											setCurrentFacet(facet);
										}}
									/>
								))}
							</div>
						),
					},
					{
						title:
							// In practice, currentFacet will be set when needed
							selectedType === 'sorting'
								? t('product_list_sorting_heading')
								: currentFacet?.title || '',
						content: (
							<>
								{selectedType === 'sorting' && (
									<RadioGroup
										id={`${baseId}-sort`}
										name={`${baseId}-sort`}
										legend={t('product_list_sorting_heading')}
										hiddenLegend
										className="mt-6"
										value={selectedSortOptionValue}
										onChange={onSortChange}
										options={sortOptions.map((opt, i) => ({
											label: opt.name,
											value: opt.attribute,
											className: i === 0 ? undefined : 'mt-6',
											labelClassName: 'grow',
											disabled,
										}))}
									/>
								)}
								{selectedType === 'facet' && currentFacet && (
									<fieldset className="mt-6">
										<legend className="sr-only">{currentFacet.title}</legend>
										{currentFacet.facetItems?.map((item, i) => (
											<Checkbox
												key={item.item}
												id={slugify(
													`${baseId}-${currentFacet.id}-${item.item}`,
												)}
												name={currentFacet.id}
												value={item.item}
												checked={selectedFacetValues.some(
													([key, val]) =>
														key === currentFacet.id && val === item.item,
												)}
												disabled={disabled}
												onChange={onFacetChange}
												className={i === 0 ? undefined : 'mt-6'}
												labelClassName="grow"
												label={
													<>
														{item.item}
														<Count
															amount={`(${item.count})`}
															margin="none"
															className="ml-1"
														/>
													</>
												}
											/>
										))}
									</fieldset>
								)}
							</>
						),
					},
				]}
				padFooter
				footer={
					<div className="flex items-center">
						<Button variant="text" className="mr-6" onClick={onReset}>
							{t('product_list_clear_filters_button')}
						</Button>
						<Button
							variant="primary"
							displayWidth="full"
							onClick={() => {
								setIsOpen(false);
							}}
						>
							{t('product_list_show_products_button')}
							<Count
								variant="text"
								color="inherit"
								amount={`(${totalProductsCount})`}
							/>
						</Button>
					</div>
				}
			/>
		</>
	);
}
ProductListFiltersPopover.displayName = 'ProductListFiltersPopover';
