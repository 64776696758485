/**
 * StoreInfo
 */

import React, { ReactNode } from 'react';

import Status from 'components/Status';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { OpeningHours as OpeningHoursType } from 'models/openingHours';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { getHeadingLevelBelow } from 'utils/string';

interface OpeningHoursProps {
	className?: string;
	openingHours: OpeningHoursType[];
}

function OpeningHours({ className, openingHours }: OpeningHoursProps) {
	if (openingHours.length === 0) {
		return null;
	}

	return (
		<dl className={cn('font-standard text-base', className)}>
			{openingHours.map(
				({ dateStr, shortDescription, title, type, weekday }) => (
					<div
						key={`${weekday}-${title}-${dateStr}`}
						className="mb-2 flex justify-between gap-6"
					>
						<dt>
							{type?.toUpperCase() === 'SPECIAL'
								? `${title} (${dateStr})`
								: weekday}
						</dt>
						<dd>{shortDescription}</dd>
					</div>
				),
			)}
		</dl>
	);
}

OpeningHours.displayName = 'OpeningHours';

interface Props {
	addressSectionClassName?: string;
	afterAddressContent?: ReactNode;
	city: string;
	headingLevel: HeadingLevel;
	name?: string;
	postalCode: string;
	regularOpeningHours: OpeningHoursType[] | undefined;
	specialOpeningHours: OpeningHoursType[] | undefined;
	storeArea: string;
	streetAddress: string;
	todaysOpeningHours: OpeningHoursType;
}

export default function StoreInfo({
	addressSectionClassName = 'max-md:flex max-md:flex-wrap max-md:justify-between',
	afterAddressContent,
	city,
	headingLevel,
	name,
	postalCode,
	regularOpeningHours,
	specialOpeningHours,
	storeArea,
	streetAddress,
	todaysOpeningHours,
}: Props) {
	const { t } = useI18n();

	const hasSpecialOpeningHours = is.arrayWithLength(specialOpeningHours);
	const hasRegularOpeningHours = is.arrayWithLength(regularOpeningHours);
	return (
		<>
			{name && <Text as={headingLevel} text={name} className="mb-6" />}

			<Status
				color={
					todaysOpeningHours.state?.toUpperCase() === 'CLOSED' ? 'red' : 'green'
				}
				text={todaysOpeningHours.description || ''}
				className="mb-4"
			/>

			<div className={addressSectionClassName}>
				<Text as="p">
					{storeArea}
					<br />
					{streetAddress}
					<br />
					{postalCode} {city}
				</Text>
			</div>

			{afterAddressContent}

			{(hasRegularOpeningHours || hasSpecialOpeningHours) && (
				<>
					<Text
						as={getHeadingLevelBelow(headingLevel)}
						text={t('stores_opening_hours_heading')}
						className="mb-2 md:mb-4"
					/>
					{hasRegularOpeningHours && (
						<OpeningHours openingHours={regularOpeningHours} />
					)}
					{hasSpecialOpeningHours && (
						<>
							<Text
								as={getHeadingLevelBelow(headingLevel, 2)}
								styleAs="h6"
								text={t('stores_special_opening_hours_heading')}
								className="mb-2 mt-8 md:mb-4"
							/>
							<OpeningHours openingHours={specialOpeningHours} />
						</>
					)}
				</>
			)}
		</>
	);
}
StoreInfo.displayName = 'StoreInfo';
