import React from 'react';
import { Field } from 'react-final-form';

import InfoBox from 'components/InfoBox';
import RichText from 'components/RichText';
import type { FormInfo } from 'models/sitecore';
import { cn } from 'utils/classNames';

function getInfoBox(content: FormInfo, inputSpace?: string) {
	switch (content?.type) {
		case 'InfoField':
			return (
				<RichText
					key={content.id}
					className={cn(inputSpace, content.text.length > 200 && 'text-sm')}
					html={content.text}
					tightSpacing
				/>
			);
		case 'HighlightField':
			return (
				<InfoBox
					key={content.id}
					icon={content?.icon?.value?.[0]?.src || 'info'}
					variant="information"
					className={inputSpace}
				>
					<RichText html={content.text} className="text-sm" tightSpacing />
				</InfoBox>
			);
		default:
			return null;
	}
}

export default function renderNonInputContent(
	content: FormInfo,
	inputSpace?: string,
) {
	if (content.requires) {
		return (
			<Field
				key={content.id}
				name={content.requires}
				render={({ input }) =>
					input.value ? getInfoBox(content, inputSpace) : null
				}
			/>
		);
	}
	return getInfoBox(content, inputSpace);
}
