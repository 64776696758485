import React, { useState } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import type { JulaComponentProps } from 'lib/component-props';
import type { JulaProCreditApplicationResponse } from 'models/api';
import type { FormFields } from 'models/sitecore';
import { fetchResult } from 'utils/fetch';
import { API_URL } from 'utils/fetchData';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		content: Field<string>;
		form: FormFields;
		heading: Field<string>;
		linkText: Field<string>;
		name: Field<string>;
		resursDescription: Field<string>;
		submitText: Field<string>;
	};
};

export default function ResursApplicationForm({ fields }: Props) {
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const [link, setLink] = useState<string | undefined>();

	if (!fields) {
		return null;
	}

	const onSubmit = async (data: Record<string, unknown>) => {
		const response = await fetchResult<JulaProCreditApplicationResponse>(
			`${API_URL}Customer/applyForCredit`,
			{ method: 'POST', body: JSON.stringify(data) },
		);

		// the api is a bit strange and returns 204 even if no
		// link was generated
		if (response.isSuccess() && response.value) {
			setLink(response.value?.link);
			setSubmitSuccess(true);
			return undefined;
		}

		if (
			response.isFailure('ValidationError') &&
			is.objectWithKeys(response.error.fieldErrors)
		) {
			return response.error.fieldErrors;
		}

		return { FORM_ERROR: 'businessLogicErrors' };
	};

	return (
		<>
			{submitSuccess && (
				<>
					<RichText field={fields.resursDescription} />
					{link && (
						<Button
							displayWidth="full"
							className="mt-6"
							href={link}
							target="_blank"
						>
							{fields.linkText.value}
							<Icon icon="openNewWindow" className="ml-2" />
						</Button>
					)}
				</>
			)}
			{!submitSuccess && (
				<>
					<RichText field={fields.content} />
					<GenericForm
						className="mt-6"
						inputSpace="mb-4"
						fields={fields.form}
						onSubmitCallback={onSubmit}
						submitButtonText={fields.submitText.value}
					/>
				</>
			)}
		</>
	);
}
ResursApplicationForm.displayName = 'ResursApplicationForm';
