import type { Cart } from 'models/api';
import { getPaymentRequest } from 'utils/business-logic';
import fetchData, { API_URL, type ExtendedResponse } from 'utils/fetchData';

import { isPaymentTypeWithComponentState } from '../helpers';

import type { PaymentMachineContext, PaymentResult } from './payment.types';

/**
 * Make request to start the payment flow.
 *
 * @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__payment
 */
export async function requestPayment(context: PaymentMachineContext) {
	const { cart } = context;
	if (!cart) {
		throw new Error('Cart is missing');
	}
	const selectedPaymentType = cart.selectedPayment?.selectedPaymentType;

	const paymentRequest = getPaymentRequest(cart);
	const componentState = context.paymentComponentState;
	if (
		isPaymentTypeWithComponentState(selectedPaymentType) &&
		componentState?.isValid
	) {
		paymentRequest.paymentMethod = componentState.data?.paymentMethod;
	}
	const response = await fetchData<ExtendedResponse<PaymentResult>>(
		`${API_URL}Cart/${cart.id}/payment`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(paymentRequest),
		},
		true,
	);

	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to request payment');
	}
	return response.responseData;
}

/**
 * Make request to finalize the payment (after payment is done).
 *
 * @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__finalize
 */
export async function finalizePayment(cartId: string) {
	const response = await fetchData<ExtendedResponse<Cart>>(
		`${API_URL}Cart/${cartId}/finalize`,
		{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		},
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to finalize payment');
	}
	return response.responseData;
}
