/**
 * Product
 */

import React, { Fragment } from 'react';

import Img from 'components/Img';
import { OptionalLink } from 'components/Link';
import Text from 'components/Text';
import { FormattedPriceResponse } from 'models/api';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	/** The amount per product in the summary */
	amount: number;

	/** The article number in the component */
	articleNumber?: string;

	/** Is there a discount? If there is, then render red color on the price */
	discount: boolean;

	/** The heading in the component */
	heading: string;

	/** Image src attribute */
	imageSrc?: string;

	/** The link to the product page */
	link?: string;

	placements?: string[];

	/** The total sum of the product row */
	rowSum?: FormattedPriceResponse;
}

/** The product card. */
export default function SummaryProduct({
	amount,
	articleNumber,
	discount,
	heading,
	imageSrc,
	link,
	placements,
	rowSum,
}: Props) {
	const { t } = useI18n();
	const headingId = `summary-product-title-${articleNumber}`;

	return (
		<article
			className="break-inside-avoid border-b border-grey py-3 first-of-type:mt-4 first-of-type:border-t"
			aria-labelledby={headingId}
		>
			<div className="relative flex">
				<Img
					src={imageSrc}
					width={40}
					height={40}
					service="nextjs"
					className="mr-2 h-10 w-10 shrink-0"
				/>
				<div>
					<Text as="p" styleAs="h6" className="pr-4" id={headingId}>
						<OptionalLink
							href={link}
							cover
							fallback={null}
							linkClassName="hover:underline"
						>
							{heading}
						</OptionalLink>
					</Text>
					<Text as="pSmall" className="mt-1 flex gap-x-4">
						{articleNumber && (
							<span>
								{t('checkout_product_item_number')}: {articleNumber}
							</span>
						)}
						<span>
							{t('checkout_number_of_products')}: {amount}
						</span>
					</Text>
					{is.arrayWithLength(placements) && (
						<Text as="pSmall" className="mt-1 flex gap-x-4">
							<span>{`${t('checkout_product_prints')}:`}</span>
							<span>
								{placements?.map((print, i) => (
									<Fragment key={print}>
										{print}
										{i < placements.length - 1 ? ' + ' : ''}
									</Fragment>
								))}
							</span>
						</Text>
					)}
				</div>

				<Text
					as="p"
					className={cn(
						'ml-auto whitespace-nowrap font-bold',
						discount ? 'text-julaRed' : 'text-black',
					)}
				>
					{formatPriceText(rowSum?.displayValue, rowSum?.displaySymbol)}
				</Text>
			</div>
		</article>
	);
}
SummaryProduct.displayName = 'SummaryProduct';
