import React from 'react';
import { RichTextField, TextField } from '@sitecore-jss/sitecore-jss-react';

import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		content: RichTextField;
		heading: TextField;
	};
};

/**
 * Content with a yellow background, heading and rich text block.
 */
export default function ContentBlockHighlight({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withPopoverLayout
		>
			<div className="max-w-col-6 bg-informationLighter p-4">
				{fields?.heading && (
					<Text
						as={params?.heading || 'h2'}
						styleAs="h1"
						field={fields?.heading}
						className="mb-2"
					/>
				)}
				<RichText field={fields?.content} />
			</div>
		</LayoutContainer>
	);
}
ContentBlockHighlight.displayName = 'ContentBlockHighlight';
