/**
 * IconLink
 */

import React from 'react';
import { TextField } from '@sitecore-jss/sitecore-jss-react';

import Icon, { IconSize, IconType } from 'components/Icon';
import Link from 'components/Link';
import Text from 'components/Text';
import { cn } from 'utils/classNames';

interface BaseProps {
	className?: string;
	href: string;
	icon: IconType;
	iconPosition?: 'left' | 'right';
	iconSize?: IconSize;
	rel?: string;
	target?: string;
}
interface WithTextProps extends BaseProps {
	field?: never;
	text: string;
}
interface WithFieldProps extends BaseProps {
	field: TextField;
	text?: never;
}

type Props = WithTextProps | WithFieldProps;

export default function IconLink({
	className,
	field,
	href,
	icon,
	iconPosition = 'left',
	iconSize,
	rel,
	target,
	text,
}: Props) {
	return (
		<Link
			href={href}
			target={target}
			rel={rel}
			className={cn('group inline-flex items-center gap-2', className)}
		>
			{iconPosition === 'left' && <Icon icon={icon} size={iconSize} />}
			<Text
				as="span"
				styleAs="pSmall"
				className="underline group-hover:no-underline"
				field={field}
				text={text}
			/>
			{iconPosition === 'right' && <Icon icon={icon} size={iconSize} />}
		</Link>
	);
}
IconLink.displayName = 'IconLink';
