import React from 'react';

import Icon from 'components/Icon';
import ListItem from 'components/ListItem';
import Status from 'components/Status';
import Text from 'components/Text';
import { getFormattedStockAmount } from 'utils/business-logic/stock';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props =
	| (ItemWithClickProps & StatusProps)
	| (ItemWithUrlProps & StatusProps);

type StatusProps =
	| {
			availableStockLevel: number;
			inStock: boolean;
			isOpen?: never;
			openHours?: never;
	  }
	| {
			availableStockLevel?: never;
			inStock?: never;
			isOpen: boolean;
			openHours: string;
	  };

interface BaseProps {
	address: string;
	distance?: string;
	isSelected?: boolean;
	name: string;
	storeArea: string;
}

interface ItemWithClickProps extends BaseProps {
	onClick: () => void;
	url?: never;
}

interface ItemWithUrlProps extends BaseProps {
	onClick?: never;
	url: string;
}

export default function StoreListItem({
	address,
	availableStockLevel,
	distance,
	inStock,
	isOpen,
	isSelected,
	name,
	onClick,
	openHours,
	storeArea,
	url,
}: Props) {
	const { t } = useI18n();
	const statusText = is.defined(availableStockLevel)
		? t('product_details_in_stock_label', {
				amount: getFormattedStockAmount(availableStockLevel),
			})
		: openHours;
	const isGreenStatus = inStock ?? isOpen;
	return (
		<ListItem
			as="li"
			isSelected={isSelected}
			href={url}
			onClick={onClick}
			title={name}
			endContent={url && <Icon icon="arrow" className="self-center" />}
		>
			<Text as="p" text={storeArea} />
			<Text as="p" text={address} className="mb-2" />
			{distance && <Text as="p" text={distance} className="mb-2" />}
			<Status color={isGreenStatus ? 'green' : 'red'} text={statusText} />
		</ListItem>
	);
}
StoreListItem.displayName = 'StoreListItem';
