/**
 * LayoutContainer
 */

import React from 'react';
import type { HTMLAttributes } from 'react';

import type { HTMLTagName } from 'types';
import { cn } from 'utils/classNames';

import GridOverlay from './GridOverlay';
import { getContainerMaxWidthClass, type MaxWidth } from './helpers';

export const GUTTER_MARGIN_CLASSES = 'mx-4 md:mx-6';
export const GUTTER_PADDING_CLASSES = 'px-4 md:px-6';
export const SM_GUTTER_MARGIN_CLASSES = 'sm:mx-4 md:mx-6';
export const PULL_GUTTER_MARGIN_CLASSES = '-mx-4 md:-mx-6';

interface Props {
	/** CSS color value, like hex. */
	backgroundColor?: string;

	/** React children */
	children?: React.ReactNode;

	/** Optional className for the grid wrapper */
	className?: string;

	/** Include standard horizontal margin classes */
	gutters?: boolean;

	/** What type of horizontal spacing to use */
	gutterType?: 'margin' | 'padding';

	/** CSS color value, like hex. */
	innerBackgroundColor?: string;

	/** Component to use for the inner element */
	innerComponent?: HTMLTagName;

	/** max-width for the container. */
	maxWidth?: MaxWidth;

	/** Optional className for the outer container */
	outerClassName?: string;

	/** Component to use for the outer element */
	outerComponent?: HTMLTagName;

	/** Show a grid overlay */
	overlay?: boolean;

	/** Set standard grid classes on the inner element. */
	withGrid?: boolean;

	/** Make the container responsive to a popover grid */
	withPopoverLayout?: boolean;

	/** Set vertical grid gap matching the horizontal one. */
	withVerticalGridGap?: boolean;
}

/** Layout container setting the page width. */
const LayoutContainer = React.forwardRef<
	HTMLDivElement,
	Props & HTMLAttributes<HTMLElement>
>(
	(
		{
			backgroundColor,
			children,
			className = '',
			gutters = true,
			gutterType,
			innerBackgroundColor,
			innerComponent: InnerComponent = 'div',
			maxWidth = 'standard',
			outerClassName = '',
			outerComponent: OuterComponent = 'div',
			overlay = false,
			withGrid = false,
			withPopoverLayout = false,
			withVerticalGridGap = false,
			...props
		},
		ref,
	) => {
		const maxWidthClass = getContainerMaxWidthClass(maxWidth);

		return (
			<OuterComponent
				ref={ref}
				style={backgroundColor ? { backgroundColor } : undefined}
				className={cn(
					outerClassName,
					backgroundColor && 'py-8 md:py-12',
					gutters && [
						// TODO: switch to only using padding?
						// Margin shouldn't be necessary for anything.
						(gutterType === 'margin' || (!gutterType && !backgroundColor)) &&
							GUTTER_MARGIN_CLASSES,
						(gutterType === 'padding' || (!gutterType && backgroundColor)) &&
							GUTTER_PADDING_CLASSES,
						'print:!mx-0 print:!px-0',
					],
					overlay && 'relative',
					// TODO: Look at maybe replacing this with the same solution as
					// isLeftWide and isInNestedPlaceholder, to avoid having two different solutions.
					withPopoverLayout && '@all/popover:!mx-0',
				)}
				{...props}
			>
				<InnerComponent
					style={
						innerBackgroundColor
							? { backgroundColor: innerBackgroundColor }
							: undefined
					}
					className={cn(
						className,
						maxWidthClass,
						'mx-auto',
						withGrid && 'grid grid-cols-4 gap-x-4 md:grid-cols-12 md:gap-x-6',
						withVerticalGridGap && 'gap-y-4 md:gap-y-6',
						withPopoverLayout && '@all/popover:!block',
					)}
				>
					{children}
				</InnerComponent>
				{overlay && <GridOverlay maxWidth={maxWidth} />}
			</OuterComponent>
		);
	},
);
LayoutContainer.displayName = 'LayoutContainer';

export default LayoutContainer;
