import React, { forwardRef, useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import IconButton from 'components/IconButton';
import Img from 'components/Img';
import { Review } from 'components/Reviews';
import { useRerender } from 'hooks';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import { cn, cnm } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import 'keen-slider/keen-slider.min.css';

interface Props {
	className?: string;
	images: ReviewImage[];
	/** The slider needs to be visible to be rendered correctly */
	isVisible: boolean;
	onSlideChange: (slideIndex: number) => void;
	selectedReviewImageId: ReviewImageId | undefined;
}

const ReviewImagesView = forwardRef<HTMLDivElement, Props>(
	(
		{ className, images, isVisible, onSlideChange, selectedReviewImageId },
		ref,
	) => {
		const { t } = useI18n();
		const rerender = useRerender();
		const [isLoaded, setIsLoaded] = useState(false);

		const [sliderRef, instanceRef] = useKeenSlider({
			loop: true,
			slides: { perView: 1, origin: 'center' },
			slideChanged: () => {
				// Rerender to update currentIndex, which then triggers the slide
				// change handler prop.
				rerender();
			},
			created: () => {
				setIsLoaded(true);
			},
			destroyed() {
				setIsLoaded(false);
			},
		});
		const keenSlider = instanceRef.current;
		const currentIndex = keenSlider?.track?.details?.rel ?? 0;

		// As of keen-slider 6.8.6, the options supplied to it are stored in a ref
		// and any updated options from a new render are compared to the existing
		// ones with an `equal` function to decide if the slider should refresh
		// with the new ones. Function options like slideChanged are compared by
		// stringifying the function body which means a new function from a new
		// render will NOT trigger refreshed options unless the code itself
		// changes between renders (basically impossible?). The event callbacks
		// are thus saved from the first render and any variables like the
		// `images` prop are captured in a closure and will not have its exprected
		// value (in this case, `images` is an empty array on first render since
		// the images are loaded async).
		// https://github.com/rcbyr/keen-slider/blob/v6.8.6/src/core/utils.ts
		// An effect callback will have the latest render scope as expected so
		// trigger the callback that way instead to get around the issue.
		useEffect(() => {
			onSlideChange(currentIndex);
			// Don't want a new handler to trigger a change.
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [currentIndex]);

		useEffect(() => {
			if (isLoaded && selectedReviewImageId && keenSlider) {
				const index = images.findIndex(
					(image) => image.id === selectedReviewImageId,
				);
				if (index !== -1) {
					keenSlider.moveToIdx(index, false, { duration: 0 });
				}
			}
		}, [isLoaded, selectedReviewImageId, keenSlider, images]);

		// It's ugly, but if the slider renders before the tab is active, i.e. when
		// the <ProductDetails> component renders, the slider does not know what
		// size to make the slides, update forces a recheck.
		useEffect(() => {
			if (keenSlider && isVisible) {
				setTimeout(() => {
					keenSlider?.update();
				}, 50);
			}
		}, [isVisible, keenSlider]);

		const { review } =
			images.find((img) => img.id === selectedReviewImageId) ?? {};

		const shouldShowArrows =
			isLoaded && keenSlider && keenSlider.slides.length > 1;

		return (
			<div
				ref={ref}
				className={cn(
					className,
					'relative flex h-full grow max-md:flex-col max-md:overflow-y-auto',
				)}
			>
				{shouldShowArrows && (
					<IconButton
						className="mx-4 self-center bg-julaRed max-md:hidden lg:mx-6"
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
						icon="arrow"
						iconColor="white"
						iconDirection="left"
						text={t('slider_prev_button')}
						onClick={() => {
							keenSlider?.prev();
						}}
					/>
				)}
				<div
					className={cn(
						'flex grow bg-black py-12 max-md:relative max-sm:max-h-80 md:min-h-0 md:min-w-0',
						!shouldShowArrows && 'md:ml-20',
					)}
				>
					<div className="flex min-w-0 flex-col">
						<div
							ref={isVisible ? sliderRef : undefined}
							className="keen-slider h-full"
						>
							{images.map((reviewImage) => (
								<div
									key={reviewImage.image.large}
									className="keen-slider__slide flex justify-center"
								>
									<Img
										className="object-contain"
										src={reviewImage.image.large}
										width={reviewImage.image.largeWidth}
										height={reviewImage.image.largeHeight}
									/>
								</div>
							))}
						</div>
						{isLoaded && (
							<p className="mt-2 text-center text-white">{`${currentIndex + 1}/${keenSlider?.slides.length}`}</p>
						)}
					</div>
					{shouldShowArrows && (
						<IconButton
							className="absolute left-2 top-1/2 -translate-y-1/2 bg-julaRed md:hidden"
							hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
							icon="arrow"
							iconColor="white"
							iconDirection="left"
							text={t('slider_prev_button')}
							onClick={() => {
								keenSlider?.prev();
							}}
						/>
					)}
					{shouldShowArrows && (
						<IconButton
							className="absolute right-2 top-1/2 -translate-y-1/2 bg-julaRed md:hidden"
							hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
							icon="arrow"
							iconColor="white"
							iconDirection="right"
							text={t('slider_next_button')}
							onClick={() => {
								keenSlider?.next();
							}}
						/>
					)}
				</div>
				{review && (
					<Review
						className={cnm(
							'mx-4 shrink-0 max-md:mt-4 max-sm:mb-24',
							'md:ml-8 md:mr-0 md:mt-8 md:w-1/4 md:overflow-y-auto',
							'lg:ml-12 lg:w-1/5',
							!shouldShowArrows && 'md:mr-20',
						)}
						review={review}
						showImages={false}
					/>
				)}
				{shouldShowArrows && (
					<IconButton
						className="mx-4 self-center bg-julaRed max-md:hidden lg:mx-6"
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDarker"
						icon="arrow"
						iconColor="white"
						iconDirection="right"
						text={t('slider_next_button')}
						onClick={() => {
							keenSlider?.next();
						}}
					/>
				)}
			</div>
		);
	},
);
ReviewImagesView.displayName = 'ReviewImagesView';

export default ReviewImagesView;
