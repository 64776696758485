/**
 * CampaignTag
 */

import React from 'react';

import Text from 'components/Text';
import { cn } from 'utils/classNames';

export type Size = 'small' | 'medium' | 'regular';
export type Variant = 'productTag' | 'priceTag';

interface Props {
	children: string;
	size: Size;
	variant: Variant;
}

/** A component to render campagin tags */
export default function CampaignTag({ children, size, variant }: Props) {
	return (
		<Text
			as="p"
			styleAs={size === 'small' ? 'pSmall' : size === 'medium' ? 'p' : 'pLarge'}
			className={cn(
				'inline-block px-2 py-px font-bold',
				variant === 'productTag' && 'bg-julaRed text-white',
				variant === 'priceTag' && 'border border-black bg-white',
			)}
		>
			{children}
		</Text>
	);
}
CampaignTag.displayName = 'CampaignTag';
