/**
 * Staffling
 */

import React from 'react';

import Price from 'components/Price';
import Text from 'components/Text';
import { Price as PriceType } from 'models/price';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	volumePrice: PriceType;
}

export default function Staffling({ className, volumePrice }: Props) {
	const { t } = useI18n();
	const { comparisonPrice, priceIncVat, title } = volumePrice;

	return (
		<div
			className={cn(
				'flex items-center justify-between rounded-border bg-informationLighter p-4',
				className,
			)}
		>
			<div>
				<Text as="p" className="font-bold">
					{title}
				</Text>
				{comparisonPrice && (
					<Text as="pSmall" className="mt-1">
						{comparisonPrice.title}{' '}
						{formatPriceText(
							comparisonPrice.price,
							comparisonPrice.priceSymbol,
						)}
					</Text>
				)}
			</div>
			{priceIncVat && (
				<div className="pr-2">
					<Price
						price={priceIncVat.displayValue}
						currency={priceIncVat.displaySymbol}
						label={t('volume_price_label')}
						labelAlign="left"
						size="mini"
					/>
				</div>
			)}
		</div>
	);
}
Staffling.displayName = 'Staffling';
