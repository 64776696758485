import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { SWRConfig } from 'swr';
import { interpret } from 'xstate';

import DevModeBanner from 'components/DevModeBanner';
import {
	ChatContextProvider,
	FeatureToggleContextProvider,
	GlobalStateContextProvider,
	SelectedStoreContextProvider,
	SelectedStoreInfoContextProvider,
} from 'contexts';
import { useEffectOnce } from 'hooks';
import type { CustomLayoutServiceData } from 'lib/page-props';
import { globalFetchLockMachine } from 'state-machines/globalFetchLock.machine';
import { isClient } from 'utils/helpers';
import { type Dictionary, I18nProvider } from 'utils/i18n';

import '../styles/globals.css';
import '@adyen/adyen-web/dist/adyen.css';
import 'components/AdyenPayment/AdyenPayment.css';
import 'nprogress/nprogress.css';

if (
	process.env.NODE_ENV === 'development' &&
	process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
) {
	// eslint-disable-next-line global-require
	require('../mocks');
}

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const globalFetchLockInterpreter = interpret(globalFetchLockMachine, {
	devTools: true,
});
if (isClient()) {
	globalThis.globalFetchLockInterpreter = globalFetchLockInterpreter.start();
}

type Props = AppProps<{
	dictionary: Dictionary;
	layoutData: CustomLayoutServiceData;
	locale: string;
}>;

function App({ Component, pageProps }: Props): JSX.Element {
	const { dictionary, ...componentProps } = pageProps;
	const sitecoreContext = pageProps.layoutData.sitecore.context;

	useEffectOnce(() => {
		if (process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING) {
			const appInsights = new ApplicationInsights({
				config: {
					connectionString:
						process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING,
				},
			});
			appInsights.loadAppInsights();
			appInsights.trackPageView();
		}
	});

	return (
		<SelectedStoreContextProvider>
			<SelectedStoreInfoContextProvider>
				<FeatureToggleContextProvider
					featureToggles={sitecoreContext.featureToggles}
				>
					<GlobalStateContextProvider sitecoreContext={sitecoreContext}>
						<ChatContextProvider>
							<SWRConfig value={{ loadingTimeout: 10_000 }}>
								<I18nProvider dictionary={dictionary}>
									<DevModeBanner />
									<Component {...componentProps} />
								</I18nProvider>
							</SWRConfig>
						</ChatContextProvider>
					</GlobalStateContextProvider>
				</FeatureToggleContextProvider>
			</SelectedStoreInfoContextProvider>
		</SelectedStoreContextProvider>
	);
}
App.displayName = 'App';

export default function AppWrapper(props: Props): JSX.Element | null {
	if (!props.pageProps) {
		console.error(
			'Missing pageProps from Sitecore in App root, aborting render',
		);
		return null;
	}
	if (!props.pageProps.layoutData) {
		console.error(
			'Missing pageProps.layoutData from Sitecore in App root, aborting render',
		);
		return null;
	}
	return <App {...props} />;
}
AppWrapper.displayName = 'AppWrapper';
