import React from 'react';
import { useSelector } from '@xstate/react';

import AccountOverviewCreditInformation from 'components/AccountOverviewCreditInformation';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import LoadingSpinner from 'components/LoadingSpinner';
import { OrderList } from 'components/Order/';
import Placeholder from 'components/Placeholder';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import {
	useAvailableCreditBalance,
	useContactInformation,
	useCustomerInformation,
	useGlobalLinks,
	useIsEditing,
	useOrderHistory,
} from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import {
	selectContactRole,
	selectCustomerContactId,
} from 'state-machines/authentication';
import { useI18n } from 'utils/i18n';

import OrganizationDetails from './OrganizationDetails';

type Props = JulaComponentProps & {};

export default function AccountJulaProOverview({ rendering }: Props) {
	const { t } = useI18n();

	const { globalPopoverService, userService } = useGlobalStateContext();
	const { creditFunctionsEnabled } = useFeatureToggle();
	const {
		accountDetailsPro,
		accountOrders,
		julaProApplyForCredit,
		julaProRaiseCredit,
	} = useGlobalLinks();
	const isEditing = useIsEditing();

	const contactRole = useSelector(userService, selectContactRole);
	const isEcomBuyer = contactRole === 'EcomBuyer';
	const isAdmin = contactRole === 'Admin';

	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation();
	const {
		error: errorOrders,
		isLoading: isLoadingOrders,
		items: orders,
	} = useOrderHistory(isAdmin, 3);
	const hasOrders = orders && orders.length > 0;
	const {
		creditLimit,
		id,
		identificationNumber,
		invoiceAddress,
		isCreditCustomer,
		isValidCreditCustomer,
	} = customerInformation ?? {};

	const customerContactId = useSelector(userService, selectCustomerContactId);
	const {
		contactInformation,
		error: errorContactInformation,
		isLoading: isLoadingContactInformation,
	} = useContactInformation(customerContactId || '');

	const {
		availableCreditBalance,
		error: errorAvailableCreditBalance,
		isLoading: isLoadingAvailableCreditBalance,
	} = useAvailableCreditBalance(
		Boolean(creditFunctionsEnabled && customerInformation?.isCreditCustomer),
	);

	const isLoadingCreditInformation =
		(isCreditCustomer && isLoadingAvailableCreditBalance) ||
		isLoadingCustomerInformation ||
		isLoadingContactInformation;
	const isLoadingOrganizationDetails =
		isLoadingCustomerInformation || isLoadingContactInformation;
	const errorOrganizationDetails =
		errorCustomerInformation || errorContactInformation;

	const errorCreditInformation =
		(isCreditCustomer && errorAvailableCreditBalance) ||
		errorCustomerInformation;

	const loadingOrdersOk = !isLoadingOrders && !errorOrders;

	if (isEditing) {
		return (
			<AccountLayoutContainer
				heading={`${t('account_pages_heading')}: Exp editor placeholder`}
				rendering={rendering}
			>
				<Placeholder
					rendering={rendering}
					name="jula-account-details"
					isInNestedPlaceholder
				/>
			</AccountLayoutContainer>
		);
	}

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_pages_heading')}
			sidebarContent={
				<div className="mt-8 md:mt-24">
					<OrganizationDetails
						id={id}
						identificationNumber={identificationNumber}
						invoiceAddress={invoiceAddress}
						contactEmail={contactInformation?.email}
						contactPhoneNumber={contactInformation?.phoneNumber.default}
						editDetailsLink={accountDetailsPro}
						isLoading={isLoadingOrganizationDetails}
						hasError={errorOrganizationDetails}
					/>
					{creditFunctionsEnabled && !isEcomBuyer && (
						<AccountOverviewCreditInformation
							availableCreditBalance={availableCreditBalance}
							creditLimit={creditLimit}
							hasError={errorCreditInformation}
							heading={t('julapro_account_credit_information_heading')}
							notCreditCustomerInfoText={t(
								'julapro_account_credit_information_text',
							)}
							isCreditCustomer={isCreditCustomer}
							isValidCreditCustomer={isValidCreditCustomer && isAdmin}
							loading={isLoadingCreditInformation}
							applyForCreditButtonOnClick={() => {
								if (julaProApplyForCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: julaProApplyForCredit,
										heading: t('account_apply_for_credit_button'),
									});
								}
							}}
							raiseCreditButtonOnClick={() => {
								if (julaProRaiseCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: julaProRaiseCredit,
										heading: t('account_raise_credit_button'),
									});
								}
							}}
						/>
					)}
				</div>
			}
		>
			{isAdmin && (
				<>
					<div className="mb-4 mt-6 flex items-center justify-between">
						<Text as="h2">{t('account_purchases_heading')}</Text>
						{accountOrders && (
							<Link href={accountOrders}>
								{t('account_pages_show_all_orders_button')}
							</Link>
						)}
					</div>
					<div>
						{isLoadingOrders && (
							<div className="flex items-center justify-center">
								<LoadingSpinner />
							</div>
						)}
						{loadingOrdersOk && hasOrders && (
							<OrderList orders={orders} rendering={rendering} />
						)}
						{loadingOrdersOk && !hasOrders && (
							<InfoBox
								icon="info"
								message={t('jula_pro_orders_no_orders_text')}
							/>
						)}
						{errorOrders && (
							<InfoBox
								icon="error"
								message={t('account_generic_fetch_error_text')}
							/>
						)}
					</div>
				</>
			)}
			<Placeholder
				name="jula-account-details"
				rendering={rendering}
				isInNestedPlaceholder
			/>
		</AccountLayoutContainer>
	);
}
AccountJulaProOverview.displayName = 'AccountJulaProOverview';
