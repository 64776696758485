import React, { type InputHTMLAttributes, type ReactNode } from 'react';

import { cn, cnm } from 'utils/classNames';

import { getCheckboxRadioClassName, type RadioCheckboxColor } from './helpers';
import InputInfo from './InputInfo';

export interface Props
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
	color?: RadioCheckboxColor;
	errorMessage?: string | string[];
	helpText?: string;
	id: string;
	inputClassName?: string;
	invalid?: boolean;
	label: ReactNode;
	labelClassName?: string;
	valid?: boolean;
}

export default function Checkbox({
	className,
	color = 'red',
	disabled,
	errorMessage,
	helpText,
	id,
	inputClassName,
	invalid = false,
	label,
	labelClassName,
	required,
	// Currently not used but must be destructed from ...rest to not be passed
	// to the DOM as an unknown attribute.
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	valid = false,
	...rest
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<div className={className}>
			<div className="flex items-start">
				<input
					{...rest}
					type="checkbox"
					className={getCheckboxRadioClassName({
						color,
						extra: cn('rounded-border', inputClassName),
					})}
					aria-describedby={[errorMessageId, helpTextId].join(' ')}
					// Support for aria-errormessage isn't good enough as of autumn 2024
					// so stick with aria-describedby for now.
					// aria-errormessage={errorMessageId}
					aria-invalid={invalid}
					disabled={disabled}
					id={id}
					required={required}
				/>
				<label
					className={cnm(
						'cursor-pointer pl-2',
						disabled && 'opacity-50',
						labelClassName,
					)}
					htmlFor={id}
				>
					{label}
				</label>
			</div>
			<InputInfo
				invalid={invalid}
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				className="ml-8 md:ml-7"
			/>
		</div>
	);
}
Checkbox.displayName = 'FormUI_Checkbox';
