import React from 'react';
import { Form } from 'react-final-form';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import { TextInput } from 'components/FinalForm';
import GdprAccordion from 'components/GdprAccordion';
import { composeValidators, required } from 'components/GenericForm';
import InfoBox from 'components/InfoBox';
import { API_URL } from 'utils/fetchData';
import fetchResult from 'utils/fetchResult';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	onClose: () => void;
	userEmail?: string;
	variantId: string;
}

function WatchableSignup({ onClose, userEmail = '', variantId }: Props) {
	const { t } = useI18n();

	const onWatchableSignup = async ({ email }: { email: string }) => {
		const response = await fetchResult(
			`${API_URL}ProductStock/watch?variantId=${variantId}&email=${email}`,
			{ method: 'POST' },
		);
		if (response.isErr()) {
			return (
				response.error.fieldValidationErrors ?? {
					FORM_ERROR: 'businessLogicErrors',
				}
			);
		}
		pushToGTM({
			type: 'notify_in_stock',
			payload: {
				productId: variantId,
			},
		});
		sendGlobalEvent('engagement', {
			type: 'registeredForBackInStock',
		});
		return undefined;
	};

	return (
		<Form
			onSubmit={onWatchableSignup}
			initialValues={{
				email: userEmail,
			}}
			render={(renderProps) => {
				const { handleSubmit, hasSubmitErrors, submitSucceeded, submitting } =
					renderProps;
				return (
					<>
						{hasSubmitErrors && (
							<InfoBox
								icon="error"
								message={t('general_critical_error_text')}
								variant="error"
								type="default"
								className="mb-4"
							/>
						)}

						{submitSucceeded && (
							<>
								<InfoBox
									icon="info"
									message={t('product_watchable_popover_success_text')}
									variant="success"
									type="default"
									className="mb-4"
								/>
								<Button
									variant="secondary"
									displayWidth="full"
									size="large"
									onClick={onClose}
								>
									{t('popover_close_label')}
								</Button>
							</>
						)}
						<form
							className={submitSucceeded ? 'hidden' : undefined}
							onSubmit={handleSubmit}
						>
							<p>{t('product_watchable_popover_text')}</p>
							<GdprAccordion />
							<TextInput
								type="email"
								id="email"
								className="my-4"
								label={t('account_details_email_label')}
								inputMode="email"
								name="email"
								validate={composeValidators(required(t('FieldIsRequired')))}
							/>
							<ActionButton
								variant="cta"
								displayWidth="full"
								size="xl"
								customState={
									submitting ? 'loading' : hasSubmitErrors ? 'failure' : 'idle'
								}
								minimunLoadingTime={0}
								type="submit"
							>
								{t('product_watchable_popover_button')}
							</ActionButton>
						</form>
					</>
				);
			}}
		/>
	);
}

WatchableSignup.displayName = 'WatchableSignup';

export default WatchableSignup;
