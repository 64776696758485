import React from 'react';

import InfoBox from 'components/InfoBox';
import { BusinessLogicError } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export function ErrorMessageInfoBox({
	className,
	errors,
}: {
	className?: string;
	errors: BusinessLogicError[];
}) {
	const { t } = useI18n();
	const visibleErrors = filterTruthy(errors, 'text');
	if (!is.arrayWithLength(visibleErrors)) return null;
	return (
		<InfoBox icon="error" variant="error" className={className}>
			<div className="space-y-2">
				{visibleErrors.map((error) => (
					<p key={error.key}>{t(error.text)}</p>
				))}
			</div>
		</InfoBox>
	);
}
ErrorMessageInfoBox.displayName = 'ErrorMessageInfoBox';
