/**
 * Custom error classes.
 */
/* eslint-disable max-classes-per-file */

import type { BusinessLogicError, JulaValidationError } from 'models/api';
import type { ValueOf } from 'types';
import { filterTruthy } from 'utils/collection';

/** Error for a bad HTTP response. */
export class ResponseError extends Error {
	readonly headers: Headers;

	readonly status: number;

	readonly statusText: string;

	readonly response: unknown;

	constructor(
		headers: Headers,
		status: number,
		statusText: string,
		response: unknown,
		message?: string,
		options?: ErrorOptions,
	) {
		super(message, options);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ResponseError);
		}
		this.name = 'ResponseError';

		this.headers = headers;
		this.status = status;
		this.statusText = statusText;
		this.response = response;
	}
}

/** Error for validation issues. */
export class ValidationError extends Error {
	readonly fieldErrors: Record<string, string[]>;

	readonly generalErrors: BusinessLogicError[];

	constructor(
		{ errors }: JulaValidationError,
		message?: string,
		options?: ErrorOptions,
	) {
		super(message, options);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, ValidationError);
		}
		this.name = 'ValidationError';

		const fieldErrors: typeof this.fieldErrors = {};
		const generalErrors: typeof this.generalErrors = [];
		filterTruthy(errors, 'text').forEach((error) => {
			if ('fieldName' in error) {
				if (!fieldErrors[error.fieldName]) {
					fieldErrors[error.fieldName] = [];
				}
				fieldErrors[error.fieldName]?.push(error.text);
			} else {
				generalErrors.push(error);
			}
		});
		this.fieldErrors = fieldErrors;
		this.generalErrors = generalErrors;
	}
}

export const ERRORS = {
	Error,
	ResponseError,
	ValidationError,
};

export type Errors = typeof ERRORS;
export type ErrorName = keyof Errors;
export type ErrorObject = InstanceType<ValueOf<Errors>>;
