import useSWRImmutable from 'swr/immutable';
import useSWRMutation from 'swr/mutation';

import type {
	BonusCheck,
	BonusInformation,
	CustomerInformation,
	InvoiceResponse,
	JulaProCustomerContact,
	OrderDetail,
	OrderRequestQueryParams,
	OrdersResponse,
} from 'models/api';
import { MutationExtraArg } from 'models/SWR';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';
import { createUrl } from 'utils/url';

import { useSWRMutate } from './general';
import { usePagedData } from './internal';

export function useBonusBalance(isActive: boolean = true) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Customer/bonus` : null,
		fetchData<BonusInformation>,
	);

	return { bonus: data, isLoading, error };
}

export function useBonusChecks() {
	const { data, error, isLoading } = useSWRImmutable(
		`${API_URL}Customer/bonusChecks`,
		fetchData<BonusCheck[]>,
	);

	return { bonusChecks: data, isLoading, error };
}
export function useRevalidateAvailableCreditBalance() {
	return useSWRMutate(`${API_URL}Customer/availableCreditBalance`);
}
export function useAvailableCreditBalance(isActive: boolean) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Customer/availableCreditBalance` : null,
		(key) => fetchData<ExtendedResponse<number>>(key, { method: 'GET' }, true),
		{ revalidateIfStale: true, revalidateOnMount: true },
	);

	return { availableCreditBalance: data?.responseData, isLoading, error };
}

export function useRevalidateCustomerInformation() {
	return useSWRMutate(`${API_URL}Customer`);
}

export function useCustomerInformation(isActive: boolean = true) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Customer` : null,
		fetchData<CustomerInformation>,
	);

	return { customerInformation: data, isLoading, error };
}
export function useContactInformation(
	customerContactId: string,
	isActive: boolean = true,
) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Customer/julapro/contact/${customerContactId}` : null,
		fetchData<JulaProCustomerContact>,
	);

	return { contactInformation: data, isLoading, error };
}

export function useRevalidateContactInformation(customerContactId: string) {
	return useSWRMutate(
		`${API_URL}Customer/julapro/contact/${customerContactId}`,
	);
}

export function useOrderHistory(isActive: boolean = true, pageSize?: number) {
	return usePagedData<OrdersResponse, 'transactions'>({
		baseUrl: `${API_URL}Customer/order`,
		itemsKey: 'transactions',
		isActive,
		pageSize,
	});
}

export function useOrderHistoryDetails(
	orderId: string,
	isActive: boolean = true,
) {
	const { data, error, isLoading } = useSWRImmutable(
		isActive ? `${API_URL}Customer/order/${orderId}` : null,
		fetchData<OrderDetail>,
	);

	return { orderDetails: data, isLoading, error };
}

export function useOrderStatus(customerId: string, orderId: string) {
	const { data, error, isLoading } = useSWRImmutable(
		`${API_URL}Customer/${customerId}/order/${orderId}`,
		fetchData<OrderDetail>,
	);

	return { orderStatus: data, isLoading, error };
}

async function getRequest(
	url: string,
	{ arg: { cancel } }: MutationExtraArg<OrderRequestQueryParams>,
) {
	return fetchData(createUrl(url, { cancel: cancel.toString() }), {
		method: 'PUT',
	});
}

export function useSendOrderStatusCustomerResponse(
	customerId: string,
	orderId: string,
) {
	return useSWRMutation(
		`${API_URL}Customer/${customerId}/order/${orderId}`,
		getRequest,
	);
}

export function useInvoices(isActive: boolean = true, pageSize?: number) {
	return usePagedData<InvoiceResponse, 'invoices'>({
		baseUrl: `${API_URL}Customer/invoice`,
		itemsKey: 'invoices',
		isActive,
		pageSize,
	});
}
