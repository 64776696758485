import { useCallback } from 'react';

import type { VariantId } from 'models/product';
import fetchData, { API_URL } from 'utils/fetchData';
import { ignorePromiseRejection } from 'utils/helpers';
import { createUrl } from 'utils/url';

interface ProductClickEvent {
	eventType: 'ProductClickEvent';
	value: VariantId;
}
type Event = ProductClickEvent;

function sendEvent(event: Event) {
	ignorePromiseRejection(
		fetchData(createUrl(`${API_URL}TrackEvent`, { ...event }), {
			method: 'POST',
		}),
	);
}
export function useTrackEvent() {
	const sendProductClickEvent = useCallback(
		(variantId: VariantId | undefined) => {
			if (variantId) {
				sendEvent({
					eventType: 'ProductClickEvent',
					value: variantId,
				});
			}
		},
		[],
	);

	return { sendProductClickEvent };
}
