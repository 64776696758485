import { Cart, MinimizedAddToCartResponse, VariantMetaData } from 'models/api';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';
import { createUrl } from 'utils/url';

export async function addMultipleToCart(
	variants: { quantity: number; variantId: string }[],
) {
	const response = await fetchData<
		ExtendedResponse<MinimizedAddToCartResponse>
	>(
		`${API_URL}Cart/mini/variants`,
		{ method: 'POST', body: JSON.stringify(variants) },
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to add multiple variants to cart');
	}
	return response.responseData;
}

export async function addRemoveCart({
	customizationId,
	distinctValue,
	metaData,
	qty,
	variantId,
}: {
	customizationId?: string;
	distinctValue?: boolean;
	metaData?: VariantMetaData;
	qty: number;
	variantId: string;
}) {
	const response = await fetchData<ExtendedResponse<Cart>>(
		createUrl(`${API_URL}Cart/variants/${variantId}`, {
			qty,
			setDistinctValue: distinctValue ? 'true' : 'false',
			customizationId,
		}),
		{
			method: 'POST',
			body: metaData ? JSON.stringify(metaData) : undefined,
		},
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to update variant qty');
	}
	return response.responseData;
}

export async function addRemoveCartMini({
	customizationId,
	metaData,
	requestAdditionalSales = false,
	returnAddedProducts = false,
	variantId,
}: {
	customizationId?: string;
	metaData?: VariantMetaData;
	requestAdditionalSales?: boolean;
	returnAddedProducts?: boolean;
	variantId: string;
}) {
	const response = await fetchData<
		ExtendedResponse<MinimizedAddToCartResponse>
	>(
		createUrl(`${API_URL}Cart/mini/variants/${variantId}`, {
			qty: 1,
			setDistinctValue: 'false',
			returnAccessories: requestAdditionalSales ? 'true' : 'false',
			returnAddedProducts: returnAddedProducts ? 'true' : 'false',
			customizationId,
		}),
		{
			method: 'POST',
			body: metaData ? JSON.stringify(metaData) : undefined,
		},
		true,
	);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to fetch cart');
	}
	return response.responseData;
}

export async function fetchCartMini() {
	const response = await fetchData<
		ExtendedResponse<MinimizedAddToCartResponse>
	>(`${API_URL}Cart/mini`, { method: 'GET' }, true);
	if (!response || response?.status < 200 || response?.status >= 300) {
		throw new Error('Failed to fetch cart');
	}
	return response.responseData;
}
