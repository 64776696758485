import React from 'react';
import { Field } from 'react-final-form';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import { Step, Stepper } from 'components/Stepper';
import type { Section } from 'models/sitecore';
import type { Worksite } from 'state-machines/createJulaProCustomer';
import { formatTemplate } from 'utils/format';
import { empty } from 'utils/helpers';

interface Props {
	resetToSelectOrganization: () => void;
	section: Section | undefined;
	selectCredit: (credit: number) => void;
	selectedCredit: number | null;
	selectedWorksite: Worksite | undefined;
}

export default function StepTwoContent({
	resetToSelectOrganization,
	section,
	selectCredit,
	selectedCredit,
	selectedWorksite,
}: Props) {
	if (!section) {
		return null;
	}

	const {
		backText,
		description,
		form,
		heading,
		submitText,
		submitTextVariation,
	} = section;

	const withCreditButtonText = submitText.value;
	const withoutCreditButtonText = submitTextVariation.value;

	return (
		<>
			<Stepper className="mx-8 mb-8 mt-4 flex items-center">
				<Step number={1} status="done" />
				<Step number={2} status="active" />
				<Step number={3} status="pending" />
			</Stepper>

			<p className="mb-1 font-bold">
				{heading?.value.includes('{{company}}')
					? formatTemplate(heading.value, {
							company: selectedWorksite?.companyName,
						})
					: `${heading?.value} ${selectedWorksite?.companyName}`}
			</p>
			<p className="mb-8">{description.value}</p>

			<GenericForm
				inputSpace="my-4"
				fields={form}
				initialValues={
					selectedCredit ? { creditLimit: selectedCredit } : undefined
				}
				onSubmitCallback={empty.func}
				button={
					<Field name="creditLimit">
						{({ input: { value } }) => (
							<ActionButton
								data-cid="julaproContinue"
								displayWidth="full"
								type="submit"
								size="large"
								variant="cta"
								className="mt-6"
								onClick={() => selectCredit(Number.parseInt(value, 10))}
							>
								{value > 0 ? withoutCreditButtonText : withCreditButtonText}
							</ActionButton>
						)}
					</Field>
				}
			/>

			<div className="mt-4 text-center">
				<Button variant="text" onClick={() => resetToSelectOrganization()}>
					{backText.value}
				</Button>
			</div>
		</>
	);
}
StepTwoContent.displayName = 'AccountJulaProSignupForm_StepTwoContent';
