/**
 * AlternativeDeliveryAddress
 */
/**
 * UserInfoForm
 */

import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import { FormOnChange, TextInput } from 'components/FinalForm';
import GdprAccordion from 'components/GdprAccordion';
import Text from 'components/Text';
import { useCheckoutContext, useGlobalStateContext } from 'contexts';
import type { CartAddress, UnregisteredCompanyInfo } from 'models/api';
import { selectCustomerType } from 'state-machines/authentication';
import {
	selectDeliveryAddressCityValidationError,
	selectDeliveryAddressCoValidationError,
	selectDeliveryAddressFirstNameValidationError,
	selectDeliveryAddressLastNameValidationError,
	selectDeliveryAddressPostalCodeValidationError,
	selectDeliveryAddressValidationError,
	selectEmailValidationError,
	selectTelephoneNumberValidationError,
	selectUserInfoActor,
} from 'state-machines/checkout/';
import {
	selectDeliveryAddress,
	selectEmail,
	selectTelephoneNumber,
} from 'state-machines/checkout/userInfo';
import { setFieldData } from 'utils/formHelpers';
import { useI18n } from 'utils/i18n';

import { updateAddress } from './helpers';

export const DELIVERY_ADDRESS_FORM_ID = 'deliveryAddressForm';
const FORM_NAME = 'deliveryAddress';

interface FormValues {
	email: string;
	[FORM_NAME]: CartAddress;
	telephoneNumber: string;
	unregisteredCompanyInfo: UnregisteredCompanyInfo;
	useAlternativeDeliveryAddress: boolean;
}

/** Form to edit user info. */
export default function DeliveryAddressForm() {
	const { t } = useI18n();
	const { userService } = useGlobalStateContext();
	const { checkoutService } = useCheckoutContext();
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);

	const customerType = useSelector(userService, selectCustomerType);
	const isClubIshUser = customerType === 'Club' || customerType === 'Staff';

	const { send } = userInfoActor;
	const deliveryAddress = useSelector(userInfoActor, selectDeliveryAddress);
	const email = useSelector(userInfoActor, selectEmail);
	const telephoneNumber = useSelector(userInfoActor, selectTelephoneNumber);
	const deliveryAddressFirstNameValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressFirstNameValidationError,
	);
	const telephoneNumberValidationError = useSelector(
		checkoutService,
		selectTelephoneNumberValidationError,
	);
	const emailValidationError = useSelector(
		checkoutService,
		selectEmailValidationError,
	);
	const deliveryAddressLastNameValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressLastNameValidationError,
	);
	const deliveryAddressValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressValidationError,
	);

	const deliveryAddressCoValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressCoValidationError,
	);

	const deliveryAddressPostalCodeValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressPostalCodeValidationError,
	);

	const deliveryAddressCityValidationError = useSelector(
		checkoutService,
		selectDeliveryAddressCityValidationError,
	);

	const validateField = (value, field, options?) => {
		let errorMsg;
		let shouldShowValidationError = false;

		// Value must be null not undefined else the shouldShowValidationError check won't work
		if (value === undefined || value === null) {
			if (options?.required) {
				return t('FieldIsRequired');
			}
			value = null;
		}
		switch (field.name) {
			case `${FORM_NAME}.firstName`:
				errorMsg = deliveryAddressFirstNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.firstName === value;
				break;

			case `${FORM_NAME}.lastName`:
				errorMsg = deliveryAddressLastNameValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.lastName === value;
				break;

			case `${FORM_NAME}.coAddress`:
				errorMsg = deliveryAddressCoValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.coAddress === value;
				break;

			case `${FORM_NAME}.address`:
				errorMsg = deliveryAddressValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.address === value;
				break;

			case `${FORM_NAME}.postalCode`:
				errorMsg = deliveryAddressPostalCodeValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.postalCode === value;
				break;

			case `${FORM_NAME}.city`:
				errorMsg = deliveryAddressCityValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = deliveryAddress?.city === value;
				break;

			case 'email':
				errorMsg = emailValidationError?.map((error) => error.description);
				shouldShowValidationError = email === value;
				break;

			case 'telephoneNumber':
				errorMsg = telephoneNumberValidationError?.map(
					(error) => error.description,
				);
				shouldShowValidationError = telephoneNumber === value;
				break;

			default:
			// No default
		}

		if (shouldShowValidationError && errorMsg && errorMsg.length > 0) {
			return errorMsg;
		}
		return undefined;
	};
	// This form works by auto saving the users input data
	// to make this work several parts are involved
	// When the form initializes the initial values are with initialValues in the finalForm Form component
	// This uses data coming from the api going to the checkout machine passed on to the userInfo machine
	// Since the data is set in initialValues the data from the api is only set in the form in init
	// to make the auto save work the <FormOnChange> component is first in line, this reacts to changes on a single field
	// and makes sure only actual value changes are sent
	// when a value changes the data via a related event to the userInfoMachine
	// based on the event the userInfoMachine might debounce the update based on the event
	// if debounced the machine saves data to it's internal context and transitions out of the debounce state
	// resetting the debounce timer, until the timer runs out, at which point it passes on the data to the checkout machine
	// the checkout machine uses the update cart method to request an update, here we have actions and variables to make sure no data is lost
	// when the update is done and the api has replied we pass the data back to userInfo machine so that it can be updated
	// with the latest changes
	// while this has happened the <FormOnChange> has been waiting for the userInfo machine to return to idle
	// the reason we do this is to trigger a form Update and revalidation
	// and to only do this when the field error selectors have the latest possible errors supplied by the api

	return (
		<>
			<Text as="h2" className="mt-8">
				{t('checkout_alt_address_form_heading')}
			</Text>
			<GdprAccordion className="mb-6 mt-2" />

			<Form
				onSubmit={() => {}}
				mutators={{
					setFieldData,
				}}
				initialValues={{ deliveryAddress }}
				initialValuesEqual={() => true}
				render={(renderProps) => {
					const {
						form: { batch, change, mutators },
						handleSubmit,
					} = renderProps;
					return (
						<form id={DELIVERY_ADDRESS_FORM_ID} onSubmit={handleSubmit}>
							<FormOnChange<FormValues>>
								{async (values) => {
									if (isClubIshUser) {
										send({
											type: 'UPDATE_CLUB_USER_ALTERNATIVE_ADDRESS',
											...values,
										});
									} else if (customerType === 'Pro') {
										send({
											type: 'UPDATE_PRO_USER_ALTERNATIVE_ADDRESS',
											...values,
										});
									} else {
										send({
											type: 'UPDATE_ANONYMOUS_USER_ALTERNATIVE_ADDRESS',
											...values,
										});
									}

									const doneData = await waitFor(
										userInfoActor,
										(state) => state.matches('idle'),
										{
											timeout: 120_000,
										},
									);
									updateAddress({
										formName: FORM_NAME,
										cartLatestAddress: doneData.context.deliveryAddress,
										batch,
										change,
									});

									mutators.setFieldData?.();
								}}
							</FormOnChange>

							{customerType !== 'Pro' && (
								<>
									<TextInput
										id="firstName"
										name={`${FORM_NAME}.firstName`}
										type="text"
										className="mb-4"
										label={t('checkout_first_name_input_label')}
										validate={(value, _values, field) =>
											validateField(value, field, {
												required: true,
											})
										}
									/>
									<TextInput
										id="lastName"
										name={`${FORM_NAME}.lastName`}
										type="text"
										className="mb-4"
										label={t('checkout_last_name_input_label')}
										validate={(value, _values, field) =>
											validateField(value, field, {
												required: true,
											})
										}
									/>
								</>
							)}
							<TextInput
								id="address"
								name={`${FORM_NAME}.address`}
								className="mb-4"
								label={t('checkout_address_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>
							<TextInput
								id="coAddress"
								name={`${FORM_NAME}.coAddress`}
								className="mb-4"
								label={t('checkout_co_address_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field)
								}
							/>
							<TextInput
								id="postalCode"
								name={`${FORM_NAME}.postalCode`}
								className="mb-4"
								label={t('checkout_postal_code_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>
							<TextInput
								id="city"
								name={`${FORM_NAME}.city`}
								className="mb-4"
								label={t('checkout_city_input_label')}
								validate={(value, _values, field) =>
									validateField(value, field, {
										required: true,
									})
								}
							/>
						</form>
					);
				}}
			/>
		</>
	);
}
DeliveryAddressForm.displayName = 'DeliveryAddressForm';
