import React, { type LabelHTMLAttributes, type ReactNode } from 'react';

import { cnm } from 'utils/classNames';

import LabelText from './LabelText';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
	children: ReactNode;
	className?: string;
	htmlFor: string;
	invalid?: boolean;
	/**
	 * If the label is currently small and floating above the field border.
	 * Is in addition to cases where it always floats, like on focus.
	 */
	isFloating: boolean;
	isHidden?: boolean;
	isValid?: boolean;
	required?: boolean;
}

/**
 * Floating form field label. Is expected to be a sibling after the form
 * field and that field must have the `peer` class name.
 */
export default function FloatingLabel({
	children,
	className,
	htmlFor,
	invalid = false,
	isFloating,
	isHidden = false,
	isValid = false,
	required = false,
}: Props) {
	return (
		<label
			htmlFor={htmlFor}
			className={cnm(
				// Add an additional pixel via border for alignment with the form field.
				// The border is not rem based like the padding.
				'inline-block border-l border-l-transparent px-1',
				'absolute left-3 z-1',
				'max-w-[calc(100%-1.5rem)] overflow-hidden text-ellipsis whitespace-nowrap',
				'rounded-border bg-white',
				'transition-floatingLabel',
				'pointer-events-none',
				isHidden && 'sr-only',

				// Color
				'text-greyDark',
				invalid && 'text-julaRed',
				isValid && 'text-success',

				// Size and position
				isFloating ? '-top-2.5 text-sm' : 'top-4 text-base',
				'peer-focus:-top-2.5 peer-focus:text-sm',
				'peer-autofill:-top-2.5 peer-autofill:text-sm',
				'peer-placeholder-shown:-top-2.5 peer-placeholder-shown:text-sm',

				className,
			)}
		>
			<LabelText required={required}>{children}</LabelText>
		</label>
	);
}
FloatingLabel.displayName = 'FloatingLabel';
