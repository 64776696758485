/**
 * BreadcrumbsItem
 */

import React from 'react';

import Icon from 'components/Icon';
import Link from 'components/Link';
import { cn } from 'utils/classNames';

interface Props {
	/** URL as a string */
	href: string;

	/** If it's the last item */
	isLast?: boolean;

	/** If it's the last item on small screens */
	isLastMobile?: boolean;

	/** Text of the item */
	text: string;
}

/** A single link item in a breadcrumb */
export default function BreadcrumbsItem({
	href,
	isLast,
	isLastMobile,
	text,
}: Props) {
	return (
		<li
			className={cn(
				'flex items-center font-standard text-sm',
				!isLast &&
					'after:mx-2 after:h-3 after:rotate-12 after:border-r after:border-r-greyDarker max-md:after:hidden',
				!isLastMobile && 'max-md:hidden',
			)}
			itemProp="itemListElement"
			itemType="http://schema.org/ListItem"
		>
			<Link
				href={href}
				itemProp="item"
				className={cn(
					'group',
					isLastMobile && 'flex items-center',
					isLast && 'text-grey',
				)}
				aria-current={isLast ? 'page' : undefined}
			>
				{isLastMobile && (
					<Icon icon="arrow" direction="left" className="mr-2 md:hidden" />
				)}
				<span itemProp="name" className="group-hover:underline">
					{text}
				</span>
			</Link>
		</li>
	);
}
BreadcrumbsItem.displayName = 'BreadcrumbsItem';
