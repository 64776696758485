import {
	type ActorRefFrom,
	assign,
	createMachine,
	type StateFrom,
} from 'xstate';

import { sendToast } from 'components/Toast';
import type { Cart } from 'models/api';
import { fetchNewCart, updateCart } from 'state-machines/checkout';
import { getDiscountCodesDuplicate } from 'utils/business-logic';
import { sendGlobalEvent } from 'utils/helpers';
import { i18n } from 'utils/i18n';
import { parseMarkdown } from 'utils/string';
import { pathStartsWith } from 'utils/url';

import {
	addMultipleToCart,
	addRemoveCart,
	addRemoveCartMini,
	fetchCartMini,
} from './cart.services';
import type {
	CartMachineContext,
	CartMachineEvents,
	CartMachineServices,
} from './cart.types';

export const cartMachine = createMachine(
	{
		context: {
			id: '',
			totalQty: 0,
			productAddedSuccessfully: false,
			addedProductPopoverIsOpen: false,
			purchaseButtonState: {
				state: 'idle',
			},
			requestAdditionalSales: false,
		},
		id: 'cartMachine',
		initial: 'initialLoadCart',
		schema: {
			context: {} as CartMachineContext,
			events: {} as CartMachineEvents,
			services: {} as CartMachineServices,
		},
		tsTypes: {} as import('./cart.machine.typegen').Typegen0,
		predictableActionArguments: true,
		states: {
			initialLoadCart: {
				tags: 'loadingCart',
				after: {
					1: [
						{
							cond: 'isInCart',
							target: 'getCart',
						},
						{
							target: 'getCartMini',
						},
					],
				},
			},
			idle: {
				id: 'idle',
				on: {
					GET_CART: {
						target: 'getCart',
					},
					ADD_ONE_FROM_BUY_BUTTON: [
						{
							target: 'addProductWithPopover',
							cond: 'shouldOpenAddedProductPopover',
						},
						{
							target: 'addProduct',
						},
					],
					RESET_ADDED_PRODUCT_POPOVER: {
						actions: 'resetAddedProductPopover',
					},
					ADD_REMOVE_VARIANT: {
						target: 'addRemoveCart',
					},
					ADD_MULTIPLE_VARIANTS_MINI: {
						target: 'addMultipleVariantsMini',
					},
					EDIT_DISCOUNT_CODES: {
						target: 'updateDiscountCodes',
					},
				},
			},
			getCartMini: {
				invoke: {
					id: 'getCartMini',
					src: 'getMiniCart',
					onDone: {
						actions: 'updateProductQuantity',
						target: '#idle',
					},
					onError: {
						target: '#idle',
					},
				},
			},
			getCart: {
				id: 'getCart',
				tags: 'loadingCart',
				invoke: {
					src: 'getCart',
					onDone: {
						actions: 'updateData',
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			addRemoveCart: {
				tags: 'loadingCart',
				invoke: {
					id: 'addRemoveCart',
					src: 'addRemoveCart',
					onDone: {
						actions: ['updateData'],
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			updateDiscountCodes: {
				invoke: {
					id: 'updateDiscountCodes',
					src: 'updateDiscountCodes',
					onDone: {
						actions: ['updateData'],
						target: 'idle',
					},
					onError: {
						target: 'idle',
					},
				},
			},
			addProduct: {
				entry: ['setPurchaseButtonStateToLoading'],
				invoke: {
					src: 'addProduct',
					onDone: [
						{
							cond: 'productAddedSuccessfully',
							target: 'checkShouldLoadFullCart',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'showToastMessage',
							],
						},
						{
							target: '#idle',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
					],
					onError: {
						target: '#idle',
						actions: [
							'setPurchaseButtonStateToFailed',
							'showErrorToastMessage',
						],
					},
				},
			},
			addProductWithPopover: {
				entry: ['setPurchaseButtonStateToLoading'],
				invoke: {
					src: 'addProductWithAdditionalSales',
					onDone: [
						{
							cond: 'productAddedSuccessfullyInCart',
							target: 'checkShouldLoadFullCart',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'sendEngagement',
							],
						},
						{
							cond: 'productAddedSuccessfully',
							target: 'checkShouldLoadFullCart',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'setAddedProductPopoverOpen',
								'setAddedProduct',
								'setAdditionalSalesProducts',
								'setMessage',
							],
						},
						{
							target: '#idle',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
					],
					onError: [
						{
							target: '#idle',
							actions: [
								'setPurchaseButtonStateToFailed',
								'showErrorToastMessage',
							],
						},
					],
				},
			},
			addMultipleVariantsMini: {
				entry: 'setPurchaseButtonStateToLoading',
				invoke: {
					id: 'addMultipleMini',
					src: 'addMultipleMini',
					onDone: [
						{
							cond: 'productAddedSuccessfully',
							actions: [
								'setPurchaseButtonStateToSuccess',
								'updateProductQuantity',
								'showToastMessage',
							],
							target: 'checkShouldLoadFullCart',
						},
						{
							target: 'idle',
							actions: ['setPurchaseButtonStateToFailed', 'showToastMessage'],
						},
					],
					onError: {
						target: 'idle',
						actions: [
							'setPurchaseButtonStateToFailed',
							'showErrorToastMessage',
						],
					},
				},
			},
			checkShouldLoadFullCart: {
				id: 'checkShouldLoadFullCart',
				description:
					'Since cart mini updates might be from components that is on the cart page, we need to check if we should trigger a load of the full cart',
				always: [
					{
						cond: 'isInCart',
						target: '#getCart',
					},
					{
						target: '#idle',
					},
				],
			},
		},
	},
	{
		actions: {
			updateData: assign({
				cart: (_context, event) => event.data,
				id: (_context, event) => event.data?.id || '',
				totalQty: (_context, event) => event.data.totalQty,
			}),
			updateProductQuantity: assign({
				totalQty: (_context, event) => event.data.totalQty,
				id: (context, event) => {
					if ('id' in event.data) {
						return event.data.id ?? 'cart id missing';
					}
					return context.id;
				},
			}),
			sendEngagement: () => {
				sendGlobalEvent('engagement', {
					type: 'addAdditionalSalesFromCart',
				});
			},
			showErrorToastMessage: (_context) => {
				sendToast(i18n.t('general_critical_error_text'), 'error');
			},
			showToastMessage: (_context, event) => {
				const addedProduct = event.data.addedProducts?.[0];
				const message = addedProduct
					? parseMarkdown(
							i18n.t('variant_added_to_cart_text', {
								productTitle: addedProduct.title,
							}),
						)
					: event.data.message;
				sendToast(
					message ?? '',
					event.data.success ? 'success' : 'error',
					'/cart/',
					'continue_to_cart',
				);
			},
			setAdditionalSalesProducts: assign({
				additionalSalesProducts: (_context, event) => event.data.accessories,
			}),
			setMessage: assign({
				cartMiniMessage: (_context, event) => event.data.message,
			}),
			setAddedProductPopoverOpen: assign({
				addedProductPopoverIsOpen: (_context) => true,
			}),
			setAddedProduct: assign({
				addedProduct: (_context, event) => event.data.addedProducts?.[0],
			}),
			resetAddedProductPopover: assign({
				addedProduct: (_context) => undefined,
				additionalSalesProducts: (_context) => undefined,
				cartMiniMessage: (_context) => '',
				addedProductPopoverIsOpen: (_context) => false,
			}),
			setPurchaseButtonStateToSuccess: assign({
				purchaseButtonState: (context) => ({
					state: 'success' as const,
					buttonId: context.purchaseButtonState.buttonId,
				}),
			}),
			setPurchaseButtonStateToFailed: assign({
				purchaseButtonState: (context) => ({
					state: 'failure' as const,
					buttonId: context.purchaseButtonState.buttonId,
				}),
			}),
			setPurchaseButtonStateToLoading: assign({
				purchaseButtonState: (_context, event) => {
					if (event.type === 'ADD_ONE_FROM_BUY_BUTTON') {
						return {
							state: 'loading' as const,
							buttonId: event.buttonId || event.variantId || undefined,
						};
					}
					if (event.type === 'ADD_MULTIPLE_VARIANTS_MINI') {
						return {
							state: 'loading' as const,
							buttonId: event.buttonId || undefined,
						};
					}
					return {
						state: 'loading' as const,
						buttonId: undefined,
					};
				},
			}),
		},
		guards: {
			shouldOpenAddedProductPopover: (_context, event) =>
				Boolean(event.openAddedProductPopover),
			isInCart: () => pathStartsWith('cart'),
			productAddedSuccessfully: (_context, event) => event.data.success,
			productAddedSuccessfullyInCart: (_context, event) =>
				event.data.success && pathStartsWith('cart'),
		},
		services: {
			addProduct: (_context, event) =>
				addRemoveCartMini({
					variantId: event.variantId,
					customizationId: event.customizationId,
					requestAdditionalSales: false,
					returnAddedProducts: true,
					metaData: event.metaData,
				}),
			addProductWithAdditionalSales: (_context, event) =>
				addRemoveCartMini({
					variantId: event.variantId,
					customizationId: event.customizationId,
					requestAdditionalSales: true,
					returnAddedProducts: true,
					metaData: event.metaData,
				}),
			getMiniCart: () => fetchCartMini(),
			getCart: () => fetchNewCart(),
			addMultipleMini: (_context, event) => addMultipleToCart(event.variants),
			addRemoveCart: (_context, event) => addRemoveCart(event),
			updateDiscountCodes: (context, event) => {
				let discountCodes: string[] = [];

				if (event.discountCode && event.variant === 'add') {
					discountCodes = [
						...getDiscountCodesDuplicate(context.cart),
						event.discountCode,
					];
				} else if (event.discountCode && event.variant === 'remove') {
					discountCodes = getDiscountCodesDuplicate(context.cart).filter(
						(code) => code !== event.discountCode,
					);
				}

				return updateCart({
					...context.cart,
					discountCodes,
				} as Cart);
			},
		},
	},
);
export type CartMachineState = StateFrom<typeof cartMachine>;
export type CartMachineActor = ActorRefFrom<typeof cartMachine>;
