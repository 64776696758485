import {
	type ActorRefFrom,
	assign,
	createMachine,
	sendParent,
	type StateFrom,
} from 'xstate';
import { pure } from 'xstate/lib/actions';

import { UpdateCartEvent } from 'state-machines/checkout';

import {
	DeliveryMachineContext,
	DeliveryMachineEvents,
} from './delivery.types';

/** delivery machine */
export const deliveryMachine = createMachine(
	{
		id: 'delivery',
		initial: 'idle',
		schema: {
			context: {} as DeliveryMachineContext,
			events: {} as DeliveryMachineEvents,
		},
		tsTypes: {} as import('./delivery.machine.typegen').Typegen0,
		context: {
			deliveryErrorTypes: [
				'DeliveryMethodNotSelected',
				'DeliveryMethodInvalid',
				'DeliveryMethodsRestricted_Dimensions',
				'DeliveryMethodsRestricted_PostalCodeNotExist',
			],
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					SELECT_DELIVERY_PICKUP: {
						target: 'loading',
						actions: 'sendSelectedPickupToParent',
					},
				},
			},
			debounceDeliveryMethodUpdate: {
				after: {
					300: { target: 'loading', actions: 'sendSelectedDeliveryToParent' },
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			RESET_TO_OLD: {
				target: 'idle',
				actions: 'resetToOldDelivery',
			},
			SELECT_DELIVERY_METHOD: {
				target: 'debounceDeliveryMethodUpdate',
				internal: false,
				actions: ['saveSelectedDeliveryToContext'],
			},
		},
	},
	{
		actions: {
			updateData: assign({
				cart: (_context, event) => event.value,
				availableDeliveryMethods: (context, event) =>
					event?.value?.availableDeliveryMethods ??
					context?.availableDeliveryMethods,
				selectedDelivery: (context, event) =>
					event?.value?.selectedDelivery ?? context?.selectedDelivery,
				errors: (_context, event) => event?.value?.errorList,
			}),

			saveSelectedDeliveryToContext: assign({
				selectedDelivery: (context, event) => event?.value,
			}),

			resetToOldDelivery: assign({
				selectedDelivery: (context) => context.oldSelectedDelivery,
			}),
			sendSelectedPickupToParent: pure((_context, event) => {
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					selectedDelivery: event.value,
				};
				return sendParent(updateCartEvent);
			}),
			sendSelectedDeliveryToParent: pure((context) => {
				const updateCartEvent: UpdateCartEvent = {
					type: 'UPDATE_CART',
					selectedDelivery: context.selectedDelivery,
				};
				return sendParent(updateCartEvent);
			}),
		},
	},
);
export type DeliveryMachineState = StateFrom<typeof deliveryMachine>;
export type DeliveryMachineActor = ActorRefFrom<typeof deliveryMachine>;
