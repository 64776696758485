import React, { type ReactNode } from 'react';
import { VisitorIdentification } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import Script from 'next/script';

import BrowserWarning from 'components/BrowserWarning';
import ConsentAndAnalytics from 'components/ConsentAndAnalytics';
import ErrorBoundary from 'components/ErrorBoundary';
import Favicons from 'components/Favicons';
import LoadingSpinner from 'components/LoadingSpinner';
import Meta from 'components/Meta';
import Placeholder from 'components/Placeholder';
import { publicRuntimeConfig } from 'config';
import { MAIN_CONTENT_CONTAINER_ID } from 'constants/ids';
import { useGlobalStateContext } from 'contexts';
import { useEffectOnce, useIsEditing } from 'hooks';
import type {
	CustomLayoutServiceData,
	RouteData,
	UserInfo,
} from 'lib/page-props';
import { getPublicUrl } from 'lib/util';
import { cn } from 'utils/classNames';
import { getPageThemeClasses } from 'utils/sitecore';
import { removeTrailingSlash } from 'utils/url';

// Prefix public assets with a public URL to enable compatibility with
// Sitecore Experience Editor.
const publicUrl = getPublicUrl();

interface Props {
	children?: ReactNode;
	forceNoindexNofollow?: boolean;
	isLeftWide?: boolean;
	layoutData: CustomLayoutServiceData;
	renderEmpty?: boolean;
	renderFooter?: boolean;
	renderHeader?: boolean;
	renderLoading?: boolean;
	renderMain?: boolean;
	route: RouteData | null;
	userInfo: UserInfo | undefined;
}

export default function BaseLayout({
	children,
	forceNoindexNofollow = false,
	isLeftWide = false,
	layoutData,
	renderEmpty = false,
	renderFooter = true,
	renderHeader = true,
	renderLoading = false,
	renderMain = true,
	route,
	userInfo,
}: Props) {
	const router = useRouter();
	const { metaData, pageEditing } = layoutData.sitecore.context;
	const { wishlistService } = useGlobalStateContext();
	const isEditing = useIsEditing();
	const socialMedia = metaData?.socialMediaTags;
	const siteUrl = removeTrailingSlash(
		publicRuntimeConfig?.NEXT_PUBLIC_PUBLIC_URL || '',
	);
	const pageTheme = route?.fields?.pageTheme?.value;
	const themeClasses = getPageThemeClasses(pageTheme);

	useEffectOnce(() => {
		wishlistService.send('FETCH_MINI_WISHLIST');
	});

	const renderWithErrorBoundary = (components: ReactNode[]) => (
		<ErrorBoundary isPageWidth>{components}</ErrorBoundary>
	);

	return (
		<>
			{!pageEditing && (
				<ConsentAndAnalytics userInfo={userInfo} layoutData={layoutData} />
			)}

			{!pageEditing && router.asPath === '/' && siteUrl && (
				// The WebSite structured data element should only appear on the
				// home page, no other pages.
				// https://developers.google.com/search/docs/appearance/structured-data/sitelinks-searchbox
				<Script
					type="application/ld+json"
					id="website-structured-data"
					dangerouslySetInnerHTML={{
						__html: JSON.stringify({
							'@context': 'https://schema.org',
							'@type': 'WebSite',
							'url': `${siteUrl}/`,
							'potentialAction': {
								'@type': 'SearchAction',
								'target': {
									'@type': 'EntryPoint',
									'urlTemplate': `${siteUrl}/search?query={search_term_string}`,
								},
								'query-input': 'required name=search_term_string',
							},
						}),
					}}
				/>
			)}

			<Meta
				title={metaData?.title || 'Page'}
				description={metaData?.description}
				ogTitle={socialMedia?.ogTitle}
				ogDescription={socialMedia?.ogDescription || metaData?.description}
				ogImage={socialMedia?.ogImage}
				ogSiteName={socialMedia?.ogSiteName}
				ogType={socialMedia?.ogType}
				ogUrl={socialMedia?.ogUrl || publicUrl}
				twitterCard={socialMedia?.twitterCard}
				twitterTitle={socialMedia?.twitterTitle}
				twitterDescription={socialMedia?.twitterDescription}
				twitterImage={socialMedia?.twitterImage}
				noIndex={forceNoindexNofollow ? true : metaData?.noIndex}
				noFollow={forceNoindexNofollow ? true : metaData?.noFollow}
				canonical={metaData?.canonicalLink}
			/>

			<Favicons iconPath={`${publicUrl}/favicons`} />

			{/*
			VisitorIdentification is necessary for Sitecore Analytics to determine
			if the visitor is a robot. If Sitecore XP (with xConnect/xDB) is used,
			this is required or else analytics will not be collected for the JSS app.
			For XM (CMS-only) apps, this should be removed.

			VI detection only runs once for a given analytics ID, so this is not a
			recurring operation once cookies are established.
			*/}
			<VisitorIdentification />

			<div className="global__page-wrapper">
				{!pageEditing && <BrowserWarning />}

				{route && (
					<>
						{renderHeader && (
							<Placeholder
								name="jula-header"
								rendering={route}
								render={renderWithErrorBoundary}
							/>
						)}

						<main
							id={MAIN_CONTENT_CONTAINER_ID}
							// Main may receive focus via skiplink, no outline needed then.
							className={cn(
								'outline-none',
								themeClasses,
								// A pixel of top padding to keep the first component's editor
								// margin contained.
								themeClasses && 'pt-px',
								// Pull the footer and add an equal amount of padding to have
								// the background go all the way down.
								themeClasses && '-mb-14 pb-14 pt-px md:-mb-20 md:pb-20',
							)}
						>
							{renderMain && (
								<Placeholder
									name="jula-main"
									rendering={route}
									render={renderWithErrorBoundary}
									isLeftWide={isLeftWide}
								/>
							)}
							{!isEditing && renderEmpty && (
								<Placeholder name="jula-empty" rendering={route} />
							)}
							{isEditing && renderEmpty && (
								<div className="bg-greyLight">
									<Placeholder name="jula-empty" rendering={route} />
								</div>
							)}
							{renderLoading && (
								<LoadingSpinner
									trackColor="transparent"
									spinnerColor="julaRed"
									variant="dashing"
									className="mx-auto mt-8"
								/>
							)}
							{children}
						</main>

						{renderFooter && (
							<Placeholder
								name="jula-footer"
								rendering={route}
								render={renderWithErrorBoundary}
							/>
						)}
					</>
				)}
			</div>
		</>
	);
}
BaseLayout.displayName = 'BaseLayout';
