/**
 * JulaClubSignupForm
 */

import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import type { ActionButtonState } from 'components/ActionButton';
import {
	type FormSubmitCallback,
	getValuesForSubmit,
} from 'components/GenericForm';
import { ErrorMessageInfoBox } from 'components/GlobalPopover';
import Popover from 'components/Popover';
import { useGlobalStateContext } from 'contexts';
import { FormFields } from 'models/sitecore';
import {
	type CreateJulaClubCustomerMachineActor,
	handledBusinessLogicErrors,
	selectAddCustomerButtonState,
	selectAlreadyMember,
	selectBankIdSignUrl,
	selectCreatingCustomer,
	selectCreditApplicationData,
	selectCustomerCreationDone,
	selectCustomerNotCreatedState,
	selectDisplayBankIdSigningModal,
	selectErrorOpeningSignWindow,
	selectFirstName,
} from 'state-machines/createJulaClubCustomer';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import AlreadyMemberView from './AlreadyMemberView';
import CustomerCreationDoneView from './CustomerCreationDoneView';
import CustomerNotCreatedView from './CustomerNotCreatedView';
import FormView from './FormView';
import LoadingView from './LoadingView';

interface SignUpFormProps {
	alreadyMember: boolean;
	alreadyMemberButtonText: string;
	alreadyMemberHeading: string;
	alreadyMemberText: string;
	bankIdSignUrl: string | undefined;
	creditApplicationData:
		| {
				CreditLimit?: string | undefined;
				CreditResult?:
					| 'Approved'
					| 'Denied'
					| 'Canceled'
					| 'Failed'
					| undefined;
				CustomerId?: string | undefined;
		  }
		| undefined;
	customerCreationDone: boolean;
	customerNotCreated: boolean;
	customState: ActionButtonState;
	displayBankIdSigningModal: boolean;
	errorOpeningSignWindowText: string;
	fields: FormFields;
	formDescription: string;
	formHeading?: string;
	formSubmitButtonText: string;
	hasErrorOpeningSignWindow: boolean;
	membershipCreatedButtonText: string;
	membershipCreatedHeading: string;
	membershipCreatedText: string;
	membershipCreationCanceledText: string;
	membershipCreationErrorButtonText: string;
	membershipCreationErrorHeading: string;
	membershipCreationErrorText: string;
	membershipCreationUnderWayText: string;
	membershipWithCreditApprovedText: string;
	membershipWithCreditDeniedText: string;
	onClose: () => void;
	onContinue: () => void;
	onLoad: () => void;
	onModalClose?: () => void;
	onReset: () => void;
	onRetryOpenSignWindow: () => void;
	onRetryOpenSignWindowButtonText: string;
	onSubmitCallback: FormSubmitCallback;
	signFrameHeading: string;
	waitingForCustomerToBeCreated: boolean;
}

export function SignupForm({
	alreadyMember,
	alreadyMemberButtonText,
	alreadyMemberHeading,
	alreadyMemberText,
	bankIdSignUrl,
	creditApplicationData,
	customerCreationDone,
	customerNotCreated,
	customState,
	displayBankIdSigningModal,
	errorOpeningSignWindowText,
	fields,
	formDescription,
	formHeading,
	formSubmitButtonText,
	hasErrorOpeningSignWindow,
	membershipCreatedButtonText,
	membershipCreatedHeading,
	membershipCreatedText,
	membershipCreationCanceledText,
	membershipCreationErrorButtonText,
	membershipCreationErrorHeading,
	membershipCreationErrorText,
	membershipCreationUnderWayText,
	membershipWithCreditApprovedText,
	membershipWithCreditDeniedText,
	onClose,
	onContinue,
	onLoad,
	onModalClose,
	onReset,
	onRetryOpenSignWindow,
	onRetryOpenSignWindowButtonText,
	onSubmitCallback,
	signFrameHeading,
	waitingForCustomerToBeCreated,
}: SignUpFormProps) {
	return (
		<>
			{displayBankIdSigningModal && (
				<Popover
					isOpen
					onClose={onModalClose}
					variant="window"
					title={signFrameHeading}
					headerColor="red"
					padContent={false}
					contentClassName="min-h-48"
				>
					{bankIdSignUrl && (
						<iframe
							src={bankIdSignUrl}
							title={signFrameHeading}
							className="h-full w-full border-0 sm:min-h-[39rem]"
							onLoad={onLoad}
						/>
					)}
				</Popover>
			)}

			<FormView
				heading={formHeading}
				description={formDescription}
				fields={fields}
				submitButtonState={customState}
				submitButtonText={formSubmitButtonText}
				onSubmitCallback={onSubmitCallback}
				className={
					alreadyMember ||
					waitingForCustomerToBeCreated ||
					customerNotCreated ||
					customerCreationDone
						? 'hidden'
						: undefined
				}
			/>

			{customerCreationDone && (
				<CustomerCreationDoneView
					membershipCreatedHeading={membershipCreatedHeading}
					creditResult={creditApplicationData?.CreditResult}
					creditLimit={creditApplicationData?.CreditLimit}
					creditApplicationApprovedText={membershipWithCreditApprovedText}
					creditApplicationFailedOrDeniedText={membershipWithCreditDeniedText}
					creditApplicationCanceledText={membershipCreationCanceledText}
					membershipCreatedText={membershipCreatedText}
					onAcceptMembershipAndContinue={onContinue}
					membershipCreatedButtonText={membershipCreatedButtonText}
				/>
			)}
			{customerNotCreated && (
				<CustomerNotCreatedView
					membershipCreationErrorHeading={membershipCreationErrorHeading}
					membershipCreationErrorText={membershipCreationErrorText}
					onTryAgain={onReset}
					membershipCreationErrorButtonText={membershipCreationErrorButtonText}
				/>
			)}
			{alreadyMember && (
				<AlreadyMemberView
					alreadyMemberHeading={alreadyMemberHeading}
					alreadyMemberText={alreadyMemberText}
					onContinueShopping={onClose}
					alreadyMemberButtonText={alreadyMemberButtonText}
				/>
			)}
			{waitingForCustomerToBeCreated && (
				<LoadingView
					loadingText={membershipCreationUnderWayText}
					hasErrorOpeningSignWindow={hasErrorOpeningSignWindow}
					onRetryOpenSignWindow={onRetryOpenSignWindow}
					errorOpeningSignWindowText={errorOpeningSignWindowText}
					onRetryOpenSignWindowButtonText={onRetryOpenSignWindowButtonText}
				/>
			)}
		</>
	);
}
SignupForm.displayName = 'JulaClubSignupForm_SignupForm';

interface Props {
	businessLogicErrorsRenderCallback: (
		component: React.ReactNode | undefined,
	) => void;
	createJulaClubCustomerActor: CreateJulaClubCustomerMachineActor;
	description: string;
	form: FormFields;
	formSection: string | undefined;
	heading?: string;
	submitText: string;
	unregisteredToken: string | undefined;
}

export default function JulaClubSignupForm({
	businessLogicErrorsRenderCallback,
	createJulaClubCustomerActor,
	description,
	form,
	formSection,
	heading,
	submitText,
	unregisteredToken,
}: Props) {
	const { t } = useI18n();

	const { globalPopoverService } = useGlobalStateContext();

	const waitingForCustomerToBeCreated = useSelector(
		createJulaClubCustomerActor,
		selectCreatingCustomer,
	);
	const customerNotCreated = useSelector(
		createJulaClubCustomerActor,
		selectCustomerNotCreatedState,
	);
	const alreadyMember = useSelector(
		createJulaClubCustomerActor,
		selectAlreadyMember,
	);
	const customerCreationDone = useSelector(
		createJulaClubCustomerActor,
		selectCustomerCreationDone,
	);

	const firstName = useSelector(createJulaClubCustomerActor, selectFirstName);
	const addCustomerButtonState = useSelector(
		createJulaClubCustomerActor,
		selectAddCustomerButtonState,
	);
	const displayBankIdSigningModal = useSelector(
		createJulaClubCustomerActor,
		selectDisplayBankIdSigningModal,
	);
	const bankIdSignUrl = useSelector(
		createJulaClubCustomerActor,
		selectBankIdSignUrl,
	);
	const creditApplicationData = useSelector(
		createJulaClubCustomerActor,
		selectCreditApplicationData,
	);
	const errorOpeningSignWindow = useSelector(
		createJulaClubCustomerActor,
		selectErrorOpeningSignWindow,
	);
	const { send } = createJulaClubCustomerActor;

	// if user has become menber we make sure they are properly signed in
	// by seding CONTINUE to createCustomer on cleanup
	useEffect(() => {
		send('RESET');

		return () => {
			businessLogicErrorsRenderCallback(undefined);
			send('CONTINUE');
		};
	}, []);

	const onSubmit: FormSubmitCallback = async (genericFormData) => {
		businessLogicErrorsRenderCallback(undefined);
		send({
			type: 'CREATE_CUSTOMER',
			formData: {
				...getValuesForSubmit(form, genericFormData),
				formSection,
				token: unregisteredToken,
			},
		});
		const doneData = await waitFor(
			createJulaClubCustomerActor,
			(state) => state.hasTag('customerCreationStartedOrFailed'),
			{ timeout: 120_000 },
		);

		if (
			doneData?.context?.errors?.businessLogicErrors ||
			doneData?.context?.errors?.fieldValidationErrors
		) {
			const businessErrors =
				doneData?.context?.errors?.businessLogicErrors?.filter(
					handledBusinessLogicErrors,
				);
			if (is.arrayWithLength(businessErrors)) {
				businessLogicErrorsRenderCallback(
					<ErrorMessageInfoBox errors={businessErrors} />,
				);
			}
			if (is.objectWithKeys(doneData?.context?.errors?.fieldValidationErrors)) {
				return doneData?.context?.errors?.fieldValidationErrors;
			}

			return { FORM_ERROR: 'businessLogicErrors' };
		}
		return undefined;
	};

	return (
		<SignupForm
			displayBankIdSigningModal={displayBankIdSigningModal}
			signFrameHeading={t('sign_frame_heading')}
			bankIdSignUrl={bankIdSignUrl}
			onLoad={() => send('SIGN_FRAME_LOAD_SUCCESS')}
			alreadyMember={alreadyMember}
			waitingForCustomerToBeCreated={waitingForCustomerToBeCreated}
			customerNotCreated={customerNotCreated}
			customerCreationDone={customerCreationDone}
			formHeading={heading}
			formDescription={description}
			fields={form}
			customState={addCustomerButtonState}
			formSubmitButtonText={submitText}
			onSubmitCallback={onSubmit}
			membershipCreatedHeading={t('account_membership_created_heading', {
				firstName,
			})}
			creditApplicationData={creditApplicationData}
			membershipWithCreditApprovedText={t(
				'account_membership_credit_approved_text',
				{ creditLimit: creditApplicationData?.CreditLimit },
			)}
			membershipWithCreditDeniedText={t(
				'account_membership_credit_denied_text',
			)}
			membershipCreationCanceledText={t(
				'account_membership_credit_canceled_text',
			)}
			membershipCreatedText={t('account_membership_created_text')}
			onContinue={() => send('CONTINUE')}
			membershipCreatedButtonText={t('account_continue_shopping_button')}
			membershipCreationErrorHeading={t(
				'account_error_creating_membership_heading',
			)}
			membershipCreationErrorText={t('account_try_again_text')}
			onReset={() => send('RESET')}
			membershipCreationErrorButtonText={t('account_try_again_button')}
			alreadyMemberHeading={t('account_already_member_heading')}
			alreadyMemberText={t('account_already_member_text')}
			onClose={() => globalPopoverService.send('CLOSE')}
			alreadyMemberButtonText={t('account_already_member_button')}
			membershipCreationUnderWayText={t('account_creating_membership_text')}
			hasErrorOpeningSignWindow={errorOpeningSignWindow}
			onRetryOpenSignWindow={() => send('RETRY_OPEN_SIGN_WINDOW')}
			onRetryOpenSignWindowButtonText={t('account_try_again_button')}
			errorOpeningSignWindowText={t('account_error_opening_sign_window_text')}
		/>
	);
}
JulaClubSignupForm.displayName = 'JulaClubSignupForm';
